import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bxl-view-gait-analysis-walking',
  templateUrl: 'view-gait-analysis-walking.component.html',
})
export class ViewGaitAnalysisWalkingComponent implements OnInit {
  initialized = false;
  analysisId: any;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.analysisId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.initialized = true;
  }
}
