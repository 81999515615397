import { Component, OnInit, Input} from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import { AssessmentDetailViewModel, AssessmentResult, AssessmentType } from 'src/app/_models/generatedModels';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { AssessmentPDFDialogComponent } from '../assessment-pdf-dialog/assessment-pdf-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../../_services/toaster.service';

@Component({
  selector: 'assessment-result-details',
  templateUrl: 'assessment-result-details.component.html',
  styleUrls: ['assessment-result-details.component.scss']
})
export class AssessmentResultDetailsComponent implements OnInit {
  initialized = false;
  assessment: AssessmentDetailViewModel;
  ImpairmentCategoryEnum = Enums.ImpairmentCategoryEnum;
  MovementCategoryEnum = Enums.MovementCategoryEnum;
  AssessmentResultEnum = Enums.AssessmentResultEnum;
  AssessmentResult = AssessmentResult;
  assessmentType = AssessmentType;

  @Input()
  isCoachView: boolean = false;

  @Input()
  isPrintVersion: boolean = false;

  @Input()
  assessmentId: any;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService, private modalService: NgbModal, private router: Router, private toastr: ToasterService) {}

  ngOnInit(): void {
    // this view is used in two ways, so we need to check if the assessmentId was passed in
    if (!this.assessmentId) {
      this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    }

    this.assessmentData.getAssessmentDetails(this.assessmentId).subscribe((result) => {
      this.assessment = result;
      this.initialized = true;
    },
      (err: HttpErrorResponse) =>
      {
        if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
          this.toastr.error('You do not have access to the assessment', 'Error',);
        } else {
          this.toastr.error('An error occurred while loading the assessment', 'Error');
        }

        this.router.navigate(['../../../'], { relativeTo: this.route });

      });
  }

  onPrintPDF() {
    const modalRef = this.modalService.open(AssessmentPDFDialogComponent, { size: 'lg', windowClass: 'modal-max-xl-custom' });
    modalRef.componentInstance.assessmentId = this.assessmentId;
    modalRef.componentInstance.assessmentType = this.assessmentType.RRA;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}
