<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card-body">
  <div class="row no-gutters">
    <div class="col-lg-12">
      <h3 class="mb-0 d-inline">Programs</h3>
      <div class="float-right">
        <a [routerLink]="['add']" class="btn btn-primary">Add Program</a>
      </div>
    </div>
    <div class="col">Build programs to use for your athletes</div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-7">
      <div class="form-group">
        <label class="form-control-label"> &nbsp; </label>
        <custom-input-persisted class="form-control" [placeholder]="'Search by program name or keyword'" [key]="'programs'" [(ngModel)]="filter" (valueChange)="onFilterChanged($event)"></custom-input-persisted>
      </div>
    </div>
    <div class="col-lg-3 col-md-5">
      <div class="form-group">
        <i class="far fa-filter mr-1"></i><label class="form-control-label"> Created By </label>
        <coaches-library-dropdown-persisted class="form-control" [key]="'programs'" [(ngModel)]="coachFilter" (valueChange)="onCoachFilterChange($event)"></coaches-library-dropdown-persisted>
      </div>
    </div>
    <div class="col-lg-3 col-md-5">
      <div class="form-group">
        <i class="far fa-filter mr-1"></i><label class="form-control-label"> Keyword </label>
        <kendo-combobox [data]="programKeywords" [valuePrimitive]="true" valueField="id" textField="name" [(ngModel)]="programKeywordFilter" class="form-control" (valueChange)="onKeywordFilterChange($event)"></kendo-combobox>
      </div>
    </div>
    <div class="col-lg-2 col-md-5">
      <div class="form-group">
        <label class="form-control-label mr-1" for="showArchived">
          Status
        </label>
        <kendo-combobox [data]="programStatuses" [valuePrimitive]="true" valueField="value" textField="name" [(ngModel)]="showArchived" [clearButton]="false" class="form-control" (valueChange)="onShowArchivedChanged($event)"></kendo-combobox>
      </div>
    </div>
  </div>
    <page-load-header *ngIf="searching"></page-load-header>
    <div class="row mt-3">
      <div class="col">
        <div *ngIf="initialized && !searching" class="table-responsive">
          <table class="table align-items-center table-flush mobile-table">
            <thead class="thead-light">
              <tr class="header-row sortable">
                <th (click)="programs.sort('Name')" [ngClass]="programs.getCSSClass('Name')">Name</th>

                <th (click)="programs.sort('WeekCount')" [ngClass]="programs.getCSSClass('WeekCount')">Weeks</th>

                <th>Keywords</th>

                <th (click)="programs.sort('User.Credential.FullName')" [ngClass]="programs.getCSSClass('User.Credential.FullName')">Created By</th>

                <th *ngIf="hasMarketplace" (click)="programs.sort('IsPaidProgram')" [ngClass]="programs.getCSSClass('IsPaidProgram')">Paid Program</th>

                <th class="text-lg-center">Actions</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr *ngFor="let program of programs">
                <td>
                  <span class="mobile-grid-label">Name</span>
                  <a href="javascript:void(0)" [routerLink]="[previewUrl, program.id]">{{ program.name }}</a>
                </td>

                <td>
                  <span class="mobile-grid-label">Weeks</span>
                  {{ program.weekCount }}
                </td>

                <td>
                  <span class="mobile-grid-label">Keywords</span>
                  <span *ngFor="let keyword of program.programKeywords; let last = last">
                    <span>{{ keyword.name }}{{ last ? '' : ', ' }}</span>
                  </span>
                </td>

                <td>
                  <span class="mobile-grid-label">Created By</span>
                  {{ program.userFullName || 'RunDNA' }}
                </td>

                <td *ngIf="hasMarketplace">
                  <span class="mobile-grid-label">Paid Program</span>
                  {{ program.isPaidProgram | booleanToYesNo }}
                </td>

                <td class="text-lg-center">
                  <span class="mobile-grid-label">Actions</span>
                  <div class="btn-group no-caret">
                    <button type="button" class="btn btn-sm btn-icon-only text-light dropdown-toggle" data-toggle="dropdown" data-display="static" aria-haspopup="true" (click)="checkAllowedActions(program)" aria-expanded="false">
                      <i class="fas fa-ellipsis-v"> </i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg-right">
                      <div *ngIf="!program.isArchived">
                        <a href="javascript:void(0)" (click)="onAssign(program.id, program.name)" class="dropdown-item"> Assign to Athlete </a>
                        <a href="javascript:void(0)" (click)="onAssignToTeam(program.id, program.name)" class="dropdown-item"> Assign to Team </a>
                        <a *ngIf="program.canBeArchived && (program.organizationId || canEditGlobalLibrary)" href="javascript:void(0)" (click)="onArchive(program.id)" class="dropdown-item"> Archive </a>
                        <div title="Program can not be archived because it is in use. It might be assigned to categories or surveys." (click)="onArchiveDisabled($event)" *ngIf="!program.canBeArchived && (program.organizationId || canEditGlobalLibrary)"><a href="javascript:void(0)" class="dropdown-item disabled"> Archive </a></div>
                      </div>
                      <div *ngIf="program.isArchived">
                        <a *ngIf="program.organizationId || canEditGlobalLibrary" href="javascript:void(0)" (click)="onRestore(program.id)" class="dropdown-item"> Restore </a>
                      </div>
                      <a href="javascript:void(0)" (click)="onClone(program.id)" class="dropdown-item"> Clone </a>
                      <a *ngIf="program.organizationId || canEditGlobalLibrary" [routerLink]="[editUrl, program.id]" class="dropdown-item"> Edit </a>
                      <a *ngIf="program.canBeDeleted && (program.organizationId || canEditGlobalLibrary)" href="javascript:void(0)" (click)="onDelete(program.id)" class="dropdown-item"> Delete </a>
                      <div title="Program can not be deleted because it is in use. It might be assigned to one or more users." (click)="onDeleteDisabled($event)" *ngIf="!program.canBeDeleted && (program.organizationId || canEditGlobalLibrary)"><a href="javascript:void(0)" class="dropdown-item disabled"> Delete </a></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer py-4">
          <nav aria-label="...">
            <custom-pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"> </custom-pagination>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Assign Program modal-->
  <assign-program *ngIf="openAssignProgramDialog" [programId]="currentProgramId" [programName]="currentProgramName" [(openAssignProgram)]="openAssignProgramDialog"></assign-program>
  <assign-team-program *ngIf="openAssignTeamProgramDialog" [programId]="currentProgramId" [programName]="currentProgramName" [(openAssignProgram)]="openAssignTeamProgramDialog"></assign-team-program>
