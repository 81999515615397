import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { GaitReportSummaryViewModel } from 'src/app/_models/generatedModels';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gait-capacity-report-update-dialog',
  templateUrl: 'gait-capacity-report-update-dialog.component.html'
})
export class GaitCapacityReportUpdateDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  model: GaitReportSummaryViewModel;

  @Input()
    assessmentId: number;


  @Output()
    saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private assessmentService: AssessmentService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void
  {
    this.assessmentService.getGaitCapacityAnalysisSummaryById(this.assessmentId).subscribe(result =>
    {
      this.model = result;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      id: [this.assessmentId],
      notes: [this.model.notes],
      name: [this.model.name]
    });

    this.initialized = true;
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: GaitReportSummaryViewModel = Object.assign({}, this.formGroup.getRawValue());
      this.assessmentService.updateGaitCapacityAnalysisSummary(this.assessmentId, formData).subscribe(result =>
      {
        this.toastr.success('Gait Report Updated', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }
}
