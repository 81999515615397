import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gait-comparison-report-shared',
  templateUrl: 'gait-comparison-report-shared.component.html',
})
export class GaitComparisonReportSharedComponent implements OnInit {
  initialized = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.initialized = true;
  }
}
