import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CategoryScore, ComparisonDirectionEnum, ComparisonResultEnum, GaitComparisonReportViewModels, GaitComparisonViewModel, GaitReportDiagrams, GaitReportViewModel, StringViewModel } from 'src/app/_models/generatedModels';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Enums } from '../../_models/generatedEnums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GaitReportVideoPopupComponent } from '../gait-analysis-result-details/gait-report-video-popup.component';
import { AuthenticationService } from '../../_services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'gait-comparison-report',
  templateUrl: './gait-comparison-report.component.html',
  styleUrls: ['./gait-comparison-report.component.scss']
})
export class GaitComparisonReportComponent implements OnInit, OnDestroy
{
  initialized = false;
  firstReportId: any;
  secondReportId: any;
  comparisonModel: GaitComparisonReportViewModels;
  shareableLink: string;
  categoryScoreEnum = Enums.CategoryScoreEnum;
  gaitProgramPrimaryFactor = Enums.GaitProgramPrimaryFactorEnum;
  AssessmentResultShortEnum = Enums.AssessmentResultShortEnum;
  gaitScoreDescriptionEnum = Enums.GaitScoreDescriptionEnum;
  assessmentsUrl: string = './../../../../analysis-walking/view'; 

  @Input()
  isShared: boolean = false;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService, private auth: AuthenticationService, private settingsProvider: SettingsProvider, private toastr: ToasterService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void
  {
    this.firstReportId = this.route.snapshot.params ? this.route.snapshot.params['rid'] : null;
    this.secondReportId = this.route.snapshot.params ? this.route.snapshot.params['secrid'] : null;

    let pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let isFirstReportIdGuid = this.firstReportId.match(pattern) ? true : false;
    let isSeconfReportIdGuid = this.secondReportId.match(pattern) ? true : false;

    if (isFirstReportIdGuid && isSeconfReportIdGuid && this.isShared) {
      const model = new GaitComparisonViewModel();
      model.firstReportGuidId = this.firstReportId;
      model.secondReportGuidId = this.secondReportId;

      this.assessmentData.getGaitComparisonReportByGuid(model).subscribe((result) =>
      {
        this.comparisonModel = result;
        this.initialized = true;
      });
    } else {
      this.assessmentData.getGaitComparisonReport(this.firstReportId, this.secondReportId).subscribe((result) =>
      {
        this.comparisonModel = result;
        this.shareableLink = this.settingsProvider.siteUrl + `/gait-comparison/${this.comparisonModel.olderGaitReport.guidId}/${this.comparisonModel.newerGaitReport.guidId}`;

        this.initialized = true;
      },
        (err: HttpErrorResponse) =>
        {
          if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
            this.toastr.error('You do not have access to all or some compared assessments', 'Error',);
          } else {
            this.toastr.error('An error occurred while loading the assessment', 'Error');
          }

          this.router.navigate(['../../../../'], { relativeTo: this.route });

        });
    }
  }

  getArrowStyle(comparisonDirection: ComparisonDirectionEnum, comparisonResult: ComparisonResultEnum)
  {
    if (comparisonDirection == ComparisonDirectionEnum.Equal)
      return "cs_emp_space";

    let result = "";

    if (comparisonDirection == ComparisonDirectionEnum.Down) {
      result+= "cs_arrow down";
    } else if (comparisonDirection == ComparisonDirectionEnum.Up) {
      result += "cs_arrow";
    }

    if (comparisonResult == ComparisonResultEnum.Better) {
      result += " cs_arrow_green";
    } else if (comparisonResult == ComparisonResultEnum.Worse) {
      result += " cs_arrow_red";
    } else {
      result += " cs_arrow_white";
    }
    
    return result;
  }

  calculateScoringFactorPosition(categoryScore: CategoryScore, deviationPoints: number): number
  {
    if (deviationPoints == 0)
      return 50;

    let maxValue = 3;

    if (deviationPoints < 0) {
      let val = 50 - ((deviationPoints / -maxValue) * 45);

      return val >= 5 ? val : 5
    }

    let val = 50 + ((deviationPoints / maxValue) * 45);

    return val <= 95 ? val : 95
  }

  calculateScoringFactorPositionReverse(categoryScore: CategoryScore, deviationPoints: number): number
  {
    if (deviationPoints == 0)
      return 50;

    let maxValue = 3;

    if (deviationPoints > 0) {
      let val = 50 - ((deviationPoints / maxValue) * 45);

      return val >= 5 ? val : 5
    }

    let val = 50 + ((deviationPoints / -maxValue) * 45);

    return val <= 95 ? val : 95
  }


  onCopyLink()
  {
    //copy link to clipboard
    let link = document.createElement('textarea');
    link.value = this.shareableLink;
    document.body.appendChild(link);
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    this.toastr.success('Link copied to clipboard', 'Success');
  }

  getNumberFromNumber(value: string)
  {
    if (!value)
      return 0;

    return parseInt(value);
  }

  ngOnDestroy(): void
  {
  }
}
