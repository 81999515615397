import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService, ProgramsService } from 'src/app/_services/generatedServices';
import { GaitCapabilityAssessmentProgramAssignmentViewModel, GaitCapabilityAssessmentProgramCategoryViewModel } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'gaitcapability-assessment-program-dialog',
  templateUrl: 'gaitcapability-assessment-program-dialog.component.html'
})
export class GaitCapabilityAssessmentProgramDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  model: GaitCapabilityAssessmentProgramCategoryViewModel;
  gaitCapabilityCategoryEnum = Enums.GaitCapabilityCategoryEnum;

  @Input()
    assessmentId: number;

    @Output()
    saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private assessmentService: AssessmentService,
    private programsService: ProgramsService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.assessmentService.getGaitCapabilityAssessmentCategoryProgramInfoById(this.assessmentId).subscribe(result => {
      this.model = result;
      this.setupForm();
    });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      assessmentId: [this.model.assessmentId],
      clientUserId: [this.model.clientUserId],
      gaitCapabilityCategory: [this.model.gaitCapabilityCategory],
      gaitCapabilityCategoryProgramId: [this.model.gaitCapabilityProgramId],
      gaitCapabilityCategoryProgramName: [this.model.gaitCapabilityProgramName],
      gaitCapabilityCategoryProgramStartDate: [ null],

      optionalProgramId: [null],
      optionalProgramStartDate: [null]
    }, { validators: [this.requireOneProgramValidator] });

    this.initialized = true;
  }

  requireOneProgramValidator: ValidatorFn = (fg: FormGroup) => {
    if (!fg.get('gaitCapabilityCategoryProgramStartDate').value && !fg.get('optionalProgramStartDate').value) {
      return { oneStartDateRequired: true };
    }

    return null;
  };

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    if (!this.formGroup.get('gaitCapabilityCategoryProgramStartDate').value && !this.formGroup.get('optionalProgramStartDate').value) {
    }

    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: GaitCapabilityAssessmentProgramAssignmentViewModel = Object.assign({}, this.formGroup.getRawValue());
      formData.gaitCapabilityCategoryProgramStartDate = formData.gaitCapabilityCategoryProgramStartDate ? dateAsUTCNoTime(formData.gaitCapabilityCategoryProgramStartDate) : null;
      formData.optionalProgramStartDate = formData.optionalProgramStartDate ?  dateAsUTCNoTime(formData.optionalProgramStartDate) : null;
      this.programsService.assignGaitCapabilityAssessmentProgramsToClient(formData).subscribe(result => {
        this.toastr.success('Programs Assigned', 'Success');
        this.saved.emit(true);
        this.activeModal.dismiss('saved');
      });
    });
  }

  onOptionalProgramChange() {
    if (this.formGroup.get('optionalProgramId').value) {
      this.formGroup.get('optionalProgramStartDate').setValidators([Validators.required]);
    } else {
      this.formGroup.get('optionalProgramStartDate').setValidators([]);
    }

    this.formGroup.get('optionalProgramStartDate').updateValueAndValidity();
  }

  onOptionalProgramDateChange() {
    if (this.formGroup.get('optionalProgramStartDate').value) {
      this.formGroup.get('optionalProgramId').setValidators([Validators.required]);
    } else {
      this.formGroup.get('optionalProgramId').setValidators([]);
    }

    this.formGroup.get('optionalProgramId').updateValueAndValidity();
  }
}
