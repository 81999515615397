<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="cs_wrapper">
      <div *ngIf="!isShared" class="row">
        <div class="col-lg-12 mt-1 mb-2">
          <div class="float-right d-none d-lg-block">
            <a href="javascript:void(0)" (click)="onCopyLink()" class="btn btn-sm btn-primary ml-2"> Shareable Link </a>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <div class="cs_header">
                <div class="cs_list">
                  <div class="cs_list-item">
                    <span class="cs_hl">Name:</span> {{analysis.user.firstName}} {{analysis.user.lastName}}
                  </div>
                  <div class="cs_list-item">
                    <span class="cs_hl">Date:</span> {{analysis.reportDate | date:'short' }}
                  </div>
                </div>
               <a href="https://rundna.com/" target="_blank"> <img class="cs_logo" src="../../../../assets/img/gaitreport/logo.png" alt="logo" /></a>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <!-- page 1 -->
              <div class="card card-body">
                <div class="cs_panel">
                  <div class="cs_sec-header">
                    <div class="cs_sec-left overal">
                      <div>
                        <h2>RunDNA</h2>
                        <h2>Gait Score</h2>
                      </div>
                      <div class="cs_score handicap_score lg colorg-{{ analysis.gaitScoreDescription }}">
                        {{analysis.gaitScore}}
                      </div>
                    </div>
                    <div class="cs_sec-right ">
                      <div class="gait_score_descr">
                        {{analysis.gaitScoreDescription | enumString: gaitScoreDescriptionEnum}}
                      </div>
                      <div class="watch-video"> <a href="javascript:void(0)" (click)="showVideo()"> Watch video </a> </div>
                    </div>

                  </div>

                  <div class="cs_stats-wrapper cs_gap cs_mt4">
                    <img src="../../../../assets/img/gaitreport/human.png" alt="image" class="cs_image_001" />

                    <!--  -->
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <h3>Trunk</h3>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_stats-col-label">Left</div>
                        <div class="cs_score s-{{ analysis.trunk.left | enumString: categoryScoreEnum | lowercase }}">{{analysis.trunk.left | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_stats-col-label">Right</div>
                        <div class="cs_score s-{{ analysis.trunk.right | enumString: categoryScoreEnum | lowercase }}">{{analysis.trunk.right | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                    </div>
                    <!--  -->
                    <div class="cs_separator"></div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <h3>Pelvis</h3>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.pelvis.left | enumString: categoryScoreEnum | lowercase }}">{{analysis.pelvis.left | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.pelvis.right | enumString: categoryScoreEnum | lowercase }}">{{analysis.pelvis.right | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                    </div>
                    <!--  -->
                    <div class="cs_separator"></div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <h3>Hips</h3>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.hip.left | enumString: categoryScoreEnum | lowercase }}">{{analysis.hip.left | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.hip.right | enumString: categoryScoreEnum | lowercase }}">{{analysis.hip.right | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                    </div>
                    <!--  -->
                    <div class="cs_separator"></div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <h3>Knees</h3>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.knee.left | enumString: categoryScoreEnum | lowercase }}">{{analysis.knee.left | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.knee.right | enumString: categoryScoreEnum | lowercase }}">{{analysis.knee.right | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                    </div>
                    <!--  -->
                    <div class="cs_separator"></div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <h3>Foot/Ankle</h3>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.foot.left | enumString: categoryScoreEnum | lowercase }}">{{analysis.foot.left | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score s-{{ analysis.foot.right | enumString: categoryScoreEnum | lowercase }}">{{analysis.foot.right | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col"></div>
                    </div>
                  </div>
                  <div class="cs_separator"></div>
                  <div class="cs_stats-wrapper cs_gap-sm ">
                    <div class="cs_stats-row">
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">Left</div>
                      <div class="cs_stats-col"></div>
                      <div class="cs_stats-col to-center">Right</div>
                      <div class="cs_stats-col"></div>
                    </div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <div class="cs_stats-value">{{analysis.strideLength.left/1000 | number:'1.2-2'}} m</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.strideLength.leftScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.strideLength.leftScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <h4>Stride Length</h4>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.strideLength.rightScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.strideLength.rightScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col">{{analysis.strideLength.right/1000 | number:'1.2-2'}} m</div>
                    </div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <div class="cs_stats-value">{{analysis.groundContact.left}} ms</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.groundContact.leftScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.groundContact.leftScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <h4>Ground Contact</h4>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.groundContact.rightScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.groundContact.rightScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col">{{analysis.groundContact.right}} ms</div>
                    </div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <div class="cs_stats-value">{{analysis.dutyFactor.left}} %</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.dutyFactor.leftScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.dutyFactor.leftScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <h4>Duty Factor</h4>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.dutyFactor.rightScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.dutyFactor.rightScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col">{{analysis.dutyFactor.right}} %</div>
                    </div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <div class="cs_stats-value">{{analysis.doubleSupport.left}} %</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.doubleSupport.leftScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.doubleSupport.leftScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <h4>Double Support</h4>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.doubleSupport.rightScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.doubleSupport.rightScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col">{{analysis.doubleSupport.right}} %</div>
                    </div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <div class="cs_stats-value">{{analysis.singleSupport.left}} %</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.singleSupport.leftScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.singleSupport.leftScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <h4>Single Support</h4>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.singleSupport.rightScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.singleSupport.rightScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col">{{analysis.singleSupport.right}} %</div>
                    </div>
                    <div class="cs_stats-row">
                      <div class="cs_stats-col">
                        <div class="cs_stats-value">{{analysis.stepWidth.left/10 | number:'2.0-0'}} cm</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.stepWidth.leftScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.stepWidth.leftScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col to-center">
                        <h4>Step Width</h4>
                      </div>
                      <div class="cs_stats-col to-center">
                        <div class="cs_score sm s-{{ analysis.stepWidth.rightScore | enumString: categoryScoreEnum | lowercase }}">{{analysis.stepWidth.rightScore | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col">{{analysis.stepWidth.right/10 | number:'2.0-0'}} cm</div>
                    </div>
                    <div class="cs_separator"></div>
                    <div class="cs_stats-row cs_around">
                      <div class="cs_stats-col cs_inline">
                        <h4>Gait Speed</h4>
                        <span>{{analysis.gaitSpeed.value}} mph</span>
                        <div class="cs_score sm s-{{ analysis.gaitSpeed.score | enumString: categoryScoreEnum | lowercase }}">{{analysis.gaitSpeed.score | enumString: categoryScoreEnum}}</div>
                      </div>
                      <div class="cs_stats-col cs_inline">
                        <h4> </h4>
                        <span></span>
                        <div class="cs_score sm"></div>
                      </div>
                      <div class="cs_stats-col cs_inline">
                        <h4>Cadence</h4>
                        <span>{{analysis.cadence.value}} spm</span>
                        <div class="cs_score sm s-{{ analysis.cadence.score | enumString: categoryScoreEnum | lowercase }}">{{analysis.cadence.score | enumString: categoryScoreEnum}}</div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="cs_sec-header dark cs_mt1">
                  <div class="cs_sec-left">
                    <h2>Primary factor</h2>
                    <div class="cs_highlight" title="{{analysis.primaryFactor}}">
                      {{ (analysis.primaryFactor.length>25) ? (analysis.primaryFactor | slice:0:24) + '...' : (analysis.primaryFactor) }}
                    </div>
                  </div>
                  <div class="cs_sec-right">
                    {{ analysis.gaitProgramPrimaryFactor | enumString: gaitProgramPrimaryFactor }}
                  </div>
                </div>

                <div class="cs_notes cs_mt1">
                  <div class="cs_notes-label">Notes:</div>
                  <div class="cs_notes-content">
                    {{analysis.notes}}
                  </div>
                </div>

                <div class="cs_score-tab cs_mt1 score_descr">
                  <div class="cs_score-tab-item">
                    <div class="cs_score-tab-item-label">
                      Good
                    </div>
                    <div class="cs_score-tab-item-review">
                      <div class="cs_score  s-g">g</div>
                      <div class="cs_score  s-gf">gf</div>
                    </div>
                  </div>
                  <div class="cs_score-tab-item">
                    <div class="cs_score-tab-item-label">
                      Fair
                    </div>
                    <div class="cs_score-tab-item-review">
                      <div class="cs_score  s-fg">fg</div>
                      <div class="cs_score  s-f">f</div>
                      <div class="cs_score  s-fp">fp</div>
                    </div>
                  </div>
                  <div class="cs_score-tab-item">
                    <div class="cs_score-tab-item-label">
                      Poor
                    </div>
                    <div class="cs_score-tab-item-review">
                      <div class="cs_score  s-pf">pf</div>
                      <div class="cs_score  s-p">p</div>
                    </div>
                  </div>
                </div>
              </div>

              <hr  *ngIf="analysis.isGaitCapabilityAssessed" class="cs_page-break" />
              <!-- page 2 -->
              <div class="card card-body" *ngIf="analysis.isGaitCapabilityAssessed">
                <!--<div class="cs_space"></div>-->
                <div class="cs_sec-header dark cs_mt1">
                  <div class="cs_sec-left">
                    <div>
                      <h2>Gait</h2>
                      <h2>Capacity</h2>
                      <h2>Screen</h2>
                    </div>
                  </div>
                  <div class="cs_sec-right">
                    The Gait Capacity Screen (GCS) is a test of your flexibility,
                    strength, and stability. These tests are designed to identify
                    areas closely related to walking/balance that should be
                    addressed, but do not indicate your risk of injury from walking.
                  </div>
                </div>

                <div class="cs_card-grid cs_mt2">
                  <div class="cs_card overal cs_black">

                    <div class="cs_overal-score">
                      <div class="cs_overal-score-item color-1"></div>
                      <div class="cs_overal-score-item color-2"></div>
                      <div class="cs_overal-score-item color-3"></div>
                      <div class="cs_overal-score-item color-4"></div>
                      <div class="cs_overal-score-item color-5"></div>
                    </div>
                    <h3 class="cs_card-title overal-title">
                      <div>Overall<br>Score</div>
                      <div class="cs_score sm color-{{analysis.gaitCapabilityAssessmentBase.handicapScoreLevel}}">{{analysis.gaitCapabilityAssessmentBase.handicapScore}}</div>
                    </h3>


                  </div>
                  <div class="cs_card">
                    <img src="../../../../assets/img/gaitreport/img_0004.svg" alt="" class="cs_card-image img-4">
                    <h3 class="cs_card-title">
                      Mobility
                      <span class="cs_card-title-value">({{analysis.gaitCapabilityAssessmentBase.mobility.score}}/{{analysis.gaitCapabilityAssessmentBase.mobility.scoreMax}})</span>
                    </h3>
                    <h4 class="cs_card-subtitle">The ability of the body to move</h4>
                    <div class="cs_card-list">
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col">
                          <!-- ## -->
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Left
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Right
                        </div>
                      </div>

                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('kneelingDorsiflexion')">
                          Ankle
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.mobility.ankleLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.mobility.ankleLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.mobility.ankleRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.mobility.ankleRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('thomas')">
                          Hip
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.mobility.hipLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.mobility.hipLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.mobility.hipRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.mobility.hipRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('aslr')">
                          Hamstrings
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.mobility.hamstringsLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.mobility.hamstringsLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.mobility.hamstringsRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.mobility.hamstringsRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="cs_card">
                    <img src="../../../../assets/img/gaitreport/img_0003.svg" alt="" class="cs_card-image img-3">
                    <h3 class="cs_card-title">
                      Stability
                      <span class="cs_card-title-value">({{analysis.gaitCapabilityAssessmentBase.stability.score}}/{{analysis.gaitCapabilityAssessmentBase.stability.scoreMax}})</span>
                    </h3>
                    <h4 class="cs_card-subtitle">Strength and Control</h4>
                    <div class="cs_card-list">
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col">
                          <!-- ## -->
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Left
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Right
                        </div>
                      </div>

                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('uhbe')">
                          Core
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.coreLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.coreLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.coreRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.coreRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('sitToStand30sec')">
                          Hip/Knee
                        </div>
                        <div class="cs_card-list-item-col cs_span-2">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.hip | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.hip | enumString: AssessmentResultShortEnum}}</div>
                        </div>

                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('calfRaise')">
                          Calf
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.calfLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.calfLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.calfRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.calfRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('paperGrip')">
                          Foot
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.footLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.footLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.stability.footRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.stability.footRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cs_card">
                    <img src="../../../../assets/img/gaitreport/img_0001.svg" alt="" class="cs_card-image img-1">
                    <h3 class="cs_card-title">
                      Foot
                      <span class="cs_card-title-value">({{analysis.gaitCapabilityAssessmentBase.foot.score}}/{{analysis.gaitCapabilityAssessmentBase.foot.scoreMax}})</span>
                    </h3>
                    <h4 class="cs_card-subtitle">Foot Coordinated Movements</h4>
                    <div class="cs_card-list">
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col">
                          <!-- ## -->
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Left
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Right
                        </div>
                      </div>

                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('halluxExtensionGc')">
                          Big Toe
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.foot.bigToeLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.foot.bigToeLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.foot.bigToeRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.foot.bigToeRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>

                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('halluxABD')">
                          Pronation
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.foot.pronationLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.foot.pronationLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.foot.pronationRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.foot.pronationRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('toeSplayGc')">
                          Toe&#8239;Splay
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.foot.splayLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.foot.splayLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.foot.splayRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.foot.splayRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cs_card">
                    <img src="../../../../assets/img/gaitreport/img_0002.svg" alt="" class="cs_card-image img-2">
                    <h3 class="cs_card-title">
                      Functional
                      <span class="cs_card-title-value">({{analysis.gaitCapabilityAssessmentBase.functional.score}}/{{analysis.gaitCapabilityAssessmentBase.functional.scoreMax}})</span>
                    </h3>
                    <h4 class="cs_card-subtitle">Combined Movements & Balance</h4>
                    <div class="cs_card-list">
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col">
                          <!-- ## -->
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Left
                        </div>
                        <div class="cs_card-list-item-col cs_bold">
                          Right
                        </div>
                      </div>

                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('toeTouchGc')">
                          Toe Touch
                        </div>
                        <div class="cs_card-list-item-col cs_span-2">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.toeTouch | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.toeTouch | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('rotationGc')">
                          Rotation
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.rotationLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.rotationLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.rotationRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.rotationRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>

                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('4stageBalance')">
                          Balance
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.balanceLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.balanceLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.balanceRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.balanceRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                      <div class="cs_card-list-item">
                        <div class="cs_card-list-item-col btn-gc" (click)="showHelp('Hurdle')">
                          Hurdle
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.hurdleLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.hurdleLeft | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                        <div class="cs_card-list-item-col">
                          <div class="cs_score sm sg-{{ analysis.gaitCapabilityAssessmentBase.functional.hurdleRight | enumString: AssessmentResultShortEnum | lowercase }}">{{analysis.gaitCapabilityAssessmentBase.functional.hurdleRight | enumString: AssessmentResultShortEnum}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cs_card cs_black cs_content-center">
                    <h3 class="cs_card-title">Your GCS Program</h3>
                    <div class="cs_highlight" title="{{analysis.gscProgram.name}}">
                      <a href="javascript:void(0)" [routerLink]="[programUrl, analysis.gscProgram.id]">{{ (analysis.gscProgram.name.length>20) ? (analysis.gscProgram.name | slice:0:18) + '...' : (analysis.gscProgram.name) }}</a>
                    </div>
                    <p>
                      {{ (gscProgramDescription.length > 140) ? (gscProgramDescription | slice:0:140) + '...' : (gscProgramDescription)}}
                    </p>
                  </div>
                  <div class="cs_sec-header dark">
                    <div class="cs_sec-left sec-score">
                      <div class="cs_sec-score-item">
                        <h2>Able</h2>
                        <div class="cs_score lg s-g">A</div>
                      </div>

                      <div class="cs_sec-score-item">
                        <h2>Unable</h2>
                        <div class="cs_score lg s-p">U</div>
                      </div>


                    </div>
                    <div class="cs_sec-right">
                      Tests are scored as Able or Unable. It is common that if there is one
                      area for improvement, it will impact multiple tests. Improving strength
                      or flexibility in one area may improve other tests, which is why we
                      focus on the top priority to improve how you move.
                    </div>
                  </div>

                </div>
              </div>
              <hr class="cs_page-break" />
              <!-- page 3 -->
              <div class="card card-body">
                <div class="cs_short-box cs_mt2">
                  <h1 class="text-center">Scoring Factors</h1>
                  <p class="text-center">Below you will find the individual factors that contribute to your RunDNA<br>Gait Score. A lower score means that you are closer to normal walking. </p>
                </div>

                <div class="cs_pbar-wrapper">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">Cadence</h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml pos-1">
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.cadence.score, analysis.cadence.deviationPoints)}}%">
                        <span> {{analysis.cadence.rawValue}} </span>
                        <span>&#9650;</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Cadence is the number of steps per minute that you take. Too high or too low may impact your walking speed and you balance
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Stride Length
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.strideLength.leftScore, analysis.strideLength.deviationPointsLeft)}}%">
                        <span>{{getNumberFromNumber(analysis.strideLength.rawValueLeft)/1000 | number:'1.2-2'}} m</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.strideLength.rightScore, analysis.strideLength.deviationPointsRight)}}%">
                        <span>{{getNumberFromNumber(analysis.strideLength.rawValueRight)/1000 | number:'1.2-2'}} m</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The distance you cover from one foot contact to the next on the same side is your stride length. Too large, too small, or asymmetries may impact your walking speed and balance
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Double Support
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.doubleSupport.leftScore, analysis.doubleSupport.deviationPointsLeft)}}%">
                        <span>{{analysis.doubleSupport.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.doubleSupport.rightScore, analysis.doubleSupport.deviationPointsRight)}}%">
                        <span>{{analysis.doubleSupport.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Double support is the time that you spend during walking with both feet on the ground. People with pain, balance, or strength issues may shorten their time in Double Support
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Duty Factor
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.dutyFactor.leftScore, analysis.dutyFactor.deviationPointsLeft)}}%">
                        <span>{{analysis.dutyFactor.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.dutyFactor.rightScore, analysis.dutyFactor.deviationPointsRight)}}%">
                        <span>{{analysis.dutyFactor.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The time spent with the foot on the ground versus in the air. People with pain, balance, or strength issues may see changes in Duty Factor
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Step Width
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.stepWidth.leftScore, analysis.stepWidth.deviationPointsLeft)}}%">
                        <span>{{getNumberFromNumber(analysis.stepWidth.rawValueLeft)/10 | number:'2.0-0'}} cm</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.stepWidth.rightScore, analysis.stepWidth.deviationPointsRight)}}%">
                        <span>{{getNumberFromNumber(analysis.stepWidth.rawValueRight)/10 | number:'2.0-0'}} cm</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Step width is related to balance and stability. Too high or too low may indicate potential balance issues
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Stance Time
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.stanceTime.leftScore, analysis.stanceTime.deviationPointsLeft)}}%">
                        <span>{{analysis.stanceTime.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.stanceTime.rightScore, analysis.stanceTime.deviationPointsRight)}}%">
                        <span>{{analysis.stanceTime.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The time spent with the foot on the ground. Differences from side to side may indicate limitations or compensations in your walking gait
                  </p>
                </div>
                <!--  -->
              </div>


              <hr class="cs_page-break" />
              <!-- page 4 -->
              <div class="card card-body">
                <p class="cs_bpar-desc">
                  IC = Initial contact, when the foot hits the ground<br>
                  MS = Mid Stance, half way through stance phase<br>
                  TS = Terminal Stance, right before the foot leaves the ground
                </p>

                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Hip Flexion IC
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.hipFlexion.leftScore, analysis.hipFlexion.deviationPointsLeft)}}%">
                        <span>{{analysis.hipFlexion.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.hipFlexion.rightScore, analysis.hipFlexion.deviationPointsRight)}}%">
                        <span>{{analysis.hipFlexion.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The position of the hip when the foot hits the ground relates to your walking posture and how your body is ready to accept the forces of walking
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Knee Flexion IC
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.kneeFlexion.leftScore, analysis.kneeFlexion.deviationPointsLeft)}}%">
                        <span>{{analysis.kneeFlexion.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.kneeFlexion.rightScore, analysis.kneeFlexion.deviationPointsRight)}}%">
                        <span>{{analysis.kneeFlexion.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The knee is often nearly straight when the foot hits the ground to allow it to aid in absorbing forces and propelling the body forward
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Ankle Flexion IC
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.ankleFlexion.leftScore, analysis.ankleFlexion.deviationPointsLeft)}}%">
                        <span>{{analysis.ankleFlexion.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.ankleFlexion.rightScore, analysis.ankleFlexion.deviationPointsRight)}}%">
                        <span>{{analysis.ankleFlexion.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The position of the foot at initial contact plays a vital role in absorbing the forces and propeling the body forward
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Pelvic Rotation IC
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.pelvicRotation.leftScore, analysis.pelvicRotation.deviationPointsLeft)}}%">
                        <span>{{analysis.pelvicRotation.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.pelvicRotation.rightScore, analysis.pelvicRotation.deviationPointsRight)}}%">
                        <span>{{analysis.pelvicRotation.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The position of the foot at initial contact plays a vital role in absorbing the forces and propeling the body forward
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Trunk Rotation IC
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.trunkRotation.leftScore, analysis.trunkRotation.deviationPointsLeft)}}%">
                        <span>{{analysis.trunkRotation.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.trunkRotation.rightScore, analysis.trunkRotation.deviationPointsRight)}}%">
                        <span>{{analysis.trunkRotation.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The rotation of the upper body provides opposition to the lower body in order to maintain balance and stability
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Knee Flexion MS
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.kneeFlexionMs.leftScore, analysis.kneeFlexionMs.deviationPointsLeft)}}%">
                        <span>{{analysis.kneeFlexionMs.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.kneeFlexionMs.rightScore, analysis.kneeFlexionMs.deviationPointsRight)}}%">
                        <span>{{analysis.kneeFlexionMs.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The position of the knee halfway through the stance phase allows for force absoprtion and propulsion of the body forward
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Peak Hip ADD
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPositionReverse(analysis.peakHipAdd.leftScore, analysis.peakHipAdd.deviationPointsLeft)}}%">
                        <span>{{analysis.peakHipAdd.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPositionReverse(analysis.peakHipAdd.rightScore, analysis.peakHipAdd.deviationPointsRight)}}%">
                        <span>{{analysis.peakHipAdd.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The body absorbs forces by going into small amounts of hip adduction (knees coming closer together). Too high or low will shift where the body absorbs the forces
                  </p>
                </div>
                <!--  -->
              </div>


              <hr class="cs_page-break" />
              <!-- page 5 -->
              <div class="card card-body">
                <p class="cs_bpar-desc">
                  IC = Initial contact, when the foot hits the ground<br>
                  MS = Mid Stance, half way through stance phase<br>
                  TS = Terminal Stance, right before the foot leaves the ground
                </p>

                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Peak Hip IR
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPositionReverse(analysis.peakHipIr.leftScore, analysis.peakHipIr.deviationPointsLeft)}}%">
                        <span>{{analysis.peakHipIr.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPositionReverse(analysis.peakHipIr.rightScore, analysis.peakHipIr.deviationPointsRight)}}%">
                        <span>{{analysis.peakHipIr.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The body absorbs forces by going into small amounts of rotation. Too high or low will shift where the body absorbs the forces
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Peak Ankle
                        Pronation
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.peakAnklePronation.leftScore, analysis.peakAnklePronation.deviationPointsLeft)}}%">
                        <span>{{analysis.peakAnklePronation.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.peakAnklePronation.rightScore, analysis.peakAnklePronation.deviationPointsRight)}}%">
                        <span>{{analysis.peakAnklePronation.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Pronation is important to help absorb forces and engage the joints above the foot for normal walking mechanics. Too high or low may shift how the body absorbs forces
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Peak Pelvic Drop
                        MS
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.peakPelvicDrop.leftScore, analysis.peakPelvicDrop.deviationPointsLeft)}}%">
                        <span>{{analysis.peakPelvicDrop.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.peakPelvicDrop.rightScore, analysis.peakPelvicDrop.deviationPointsRight)}}%">
                        <span>{{analysis.peakPelvicDrop.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    The hip muscles resist the pelvis from dropping to the opposite side, which can contribute to injuries if too high or low
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Pelvic Tilt TO
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.pelvicTilt.leftScore, analysis.pelvicTilt.deviationPointsLeft)}}%">
                        <span>{{analysis.pelvicTilt.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.pelvicTilt.rightScore, analysis.pelvicTilt.deviationPointsRight)}}%">
                        <span>{{analysis.pelvicTilt.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Limited or excessive pelvis motion while walking can put strain on the hips and/or lower back
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Peak Foot ER
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.peakFoot.leftScore, analysis.peakFoot.deviationPointsLeft)}}%">
                        <span>{{analysis.peakFoot.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.peakFoot.rightScore, analysis.peakFoot.deviationPointsRight)}}%">
                        <span>{{analysis.peakFoot.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Foot rotation may be present from limited ankle mobility and/or to shift weight bearing in the knee

                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Hip Extension TO
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(analysis.hipExtension.leftScore, analysis.hipExtension.deviationPointsLeft)}}%">
                        <span>{{analysis.hipExtension.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(analysis.hipExtension.rightScore, analysis.hipExtension.deviationPointsRight)}}%">
                        <span>{{analysis.hipExtension.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    A certain amount of hip extension is need as the body propels forward walking. Too little or too much can place strain on the hips or lower back
                  </p>
                </div>
                <!--  -->
                <div class="cs_pbar-wrapper ">
                  <div class="cs_pbar-item">
                    <div class="cs_pbar-val">
                      <h3 class="cs_pbar-lable">
                        Ankle Supination
                        TO
                      </h3>
                    </div>
                    <div class="cs_pbar-line">
                      <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPositionReverse(analysis.ankleSupination.leftScore, analysis.ankleSupination.deviationPointsLeft)}}%">
                        <span>{{analysis.ankleSupination.rawValueLeft}}</span>
                        <span>L</span>
                      </div>
                      <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPositionReverse(analysis.ankleSupination.rightScore, analysis.ankleSupination.deviationPointsRight)}}%">
                        <span>{{analysis.ankleSupination.rawValueRight}}</span>
                        <span>R</span>
                      </div>
                    </div>
                  </div>
                  <p class="cs_pbar_description">
                    Ankle supination is part of walking where the foot is on the pinky toe side and is needed to generate forces to propel the body forward. Too high or low may shifts forces
                  </p>
                </div>
                <!--  -->
              </div>


              <hr class="cs_page-break" />
              <!-- page Diagrams -->
              <div class="card card-body">
                <!--<div class="cs_space"></div>-->
                <div class="cs_short-box">
                  <h1 class="text-center">Biomechanics Details</h1>
                  <p class="text-center">
                    DetailsBelow are graphical representations of how each joints moves
                    during thewalking cycle.
                    The gray area is normative values. The left (red) and right(blue) lines represent the
                    average of all the steps you took while walking.
                  </p>
                </div>
                <div class="cs_chart-grid-wrapper">

                  <div *ngFor="let dgDiagrams of groupedDiagrams | keyvalue" class="cs_chart-grid">
                    <div class="cs_chart-item">
                      <h3 class="cs_chart-section">{{dgDiagrams.key}}</h3>
                    </div>
                    <div *ngFor="let diagram of dgDiagrams.value" class="cs_chart-item" (click)="showDiagram(diagram)">
                      <h3>{{diagram.title}}</h3>
                      <img src="{{diagram.resizedUrl}}" />
                    </div>
                  </div>

                </div>
                <div class="cs_notes cs_mt1">
                  <div class="cs_notes-label"></div>
                  <div class="cs_notes-content">
                    <!-- Lorem ipsum dolor sit amet. -->
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
