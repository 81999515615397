import { Component, OnInit, Input} from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import { AFFitnessAssessmentCardioExerciseType, AFFitnessAssessmentMuscular1ExerciseType, AFFitnessAssessmentMuscular2ExerciseType, AFFitnessAssessmentViewModelRead } from 'src/app/_models/generatedModels';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../../_services/toaster.service';

@Component({
  selector: 'af-fitness-result-details',
  templateUrl: 'af-fitness-result-details.component.html',
  styleUrls: ['af-fitness-result-details.component.scss']
})
export class AFFitnessResultDetailsComponent implements OnInit {
  initialized = false;
  assessmentId: any;
  assessment: AFFitnessAssessmentViewModelRead;
  compositeScoreCategoryEnum = Enums.AFCompositeScoreCategoryEnum;
  genderEnum = Enums.GenderEnum;

  cardioExerciseTypeEnum = Enums.AFFitnessAssessmentCardioExerciseTypeEnum;
  cardioExerciseType = AFFitnessAssessmentCardioExerciseType;
  muscular1ExerciseTypeEnum = Enums.AFFitnessAssessmentMuscular1ExerciseTypeEnum;
  muscular1ExerciseType = AFFitnessAssessmentMuscular1ExerciseType;
  muscular2ExerciseTypeEnum = Enums.AFFitnessAssessmentMuscular2ExerciseTypeEnum;
  muscular2ExerciseType = AFFitnessAssessmentMuscular2ExerciseType;


  @Input()
  isCoachView: boolean = false;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService, private toastr: ToasterService, private router: Router) {}

  ngOnInit(): void {
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    this.assessmentData.getAFFitnessAssessment(this.assessmentId).subscribe((result) => {
      this.assessment = result;
      this.initialized = true;
    },
      (err: HttpErrorResponse) =>
      {
        if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
          this.toastr.error('You do not have access to the assessment', 'Error',);
        } else {
          this.toastr.error('An error occurred while loading the assessment', 'Error');
        }

        this.router.navigate(['../../../'], { relativeTo: this.route });

      });
  }
}
