import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'view-gait-comparison-report',
  templateUrl: 'view-gait-comparison-report.component.html',
})
export class ViewGaitComparisonReportComponent implements OnInit {
  initialized = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.initialized = true;
  }
}
