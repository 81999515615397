import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
//import { ROUTES } from '../sidebar/sidebar.component';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BrandService, ClientsService, MessagingService, OrganizationService } from 'src/app/_services/generatedServices';
import { MessageViewModel, UserRole, UserAuthProfileViewModel, BrandViewModelRead, ClientStatus } from 'src/app/_models/generatedModels';
import { Observable, Subscription, forkJoin, timer } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';
import { coachUserRoleArray } from 'src/app/_models/models';
import { map, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyStatusesService } from 'src/app/_services/dailyStatuses.service';
import { DailyStatusModalComponent } from 'src/app/_components/runner-daily-status/daily-status-modal.component';
import { UserStatusService } from 'src/app/_services/userStatus.service';
import { SurveyHandlerService } from '../../_services/surveyHandler.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public focus;
  //public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  initialized = false;
  messages: MessageViewModel[];
  unreadCount: number;
  hasAthleteRole: boolean;
  hasCoachRole: boolean;
  user: UserAuthProfileViewModel;
  userInfoPolling: Subscription;
  private subscription: Subscription;
  isModalOpen: boolean;
  brand: BrandViewModelRead;
  orgImageName: string = null;

  constructor(location: Location, private messageService: MessagingService, private element: ElementRef, private router: Router, public auth: AuthenticationService, private toastr: ToasterService,
    private modalService: NgbModal, private clientService: ClientsService, private dailyStatusesService: DailyStatusesService, private brandService: BrandService,
    private userStatus: UserStatusService, private organizationService: OrganizationService) {
    this.location = location;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove('g-sidenav-pinned');
          document.body.classList.add('g-sidenav-hidden');
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  ngOnInit() {
    this.getUserInfo(false).subscribe(result => {
      this.getBrand();
    });
  }

  getUserInfo(isOrgChange: boolean): Observable<boolean> {
    return this.auth.fetchUserProfile(isOrgChange, true).pipe(
      switchMap(user => {
        this.user = user;
        this.userInfoPolling = timer(0, 1000*30).subscribe((val) => this.updateUserMessages());

        if (user.userRole & UserRole.Runner) {
          this.checkForDailyStatus();
        }
        
        return this.auth.refreshClaims();
      }),
      map((claimsResult) => {
        this.hasAthleteRole = this.auth.hasClaimForRole([UserRole.Runner]);
        this.hasCoachRole = this.auth.hasClaimForRole(coachUserRoleArray);
        return true;
      })
    );
  }

  // we aren't using this anymore, this was for 6AMRun
  getBrand() {
    this.brand = null;
    if (this.user.organizationBrandKeyName) {
      this.brandService.getBrandByKeyName(this.user.organizationBrandKeyName).subscribe(brand => {
        this.brand = brand;
        this.initialized = true;
      });
    } else {
      this.initialized = true;
    }
  }

  updateUserMessages() {
    this.messageService.getUnreadMessagesToUser().subscribe((msgs) => {
      this.messages = msgs.filter((x) => x.toUserId == this.user.id);
      this.unreadCount = msgs.filter((x) => x.toUserId == this.user.id).length;
    });
  }

  checkForDailyStatus() {
    this.subscription = timer(0, (12 * 60 * 60 * 1000)).subscribe((result) => {
      const timeZoneOffset = new Date().getTimezoneOffset();

      var nextTimeToShowPopup = this.dailyStatusesService.getLastTimeStatusPopupShown();
      nextTimeToShowPopup.setHours(nextTimeToShowPopup.getHours() + 1);

      if (this.user.status != ClientStatus.Active || nextTimeToShowPopup > new Date())
        return;

      return forkJoin([this.clientService.doesUserHaveDailyStatusToday(this.user.id, timeZoneOffset),
        this.organizationService.doesOrganizationRequestDailyStatus(),
        this.clientService.getDailyStatusSettings(this.user.userCredentialId),
        this.clientService.getLatesDailyStatusIdForUser(this.user.userCredentialId)]).subscribe((results) =>
        {
          if (!results[0] && results[1] && results[2].requestDailyStatus && !this.isModalOpen) {
          this.isModalOpen = true;
          const modalRef = this.modalService.open(DailyStatusModalComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
          modalRef.componentInstance.userId = this.user.id;
          modalRef.componentInstance.userCredencialId = this.user.userCredentialId;
          modalRef.componentInstance.lastDayStatusId = results[3].id;

         this.dailyStatusesService.setCurrentTimeAsStatusPopupLastShown();

          modalRef.result.then(
            (result) => {
              this.isModalOpen = false;
            },
            (reason) => {
              if (reason === 'saved') {
                this.dailyStatusesService.SendEntryAddedEvent();
              }
              this.isModalOpen = false;
            }
          );
        }
      });
    });
  }

  logout() {
    this.auth.logout();
  }

  switchOrganizations(organizationId: number) {
    if (this.user.organizationId != organizationId) {
      this.auth.changeOrgContext(organizationId).subscribe(result => {
        this.toastr.success('Organization changed', 'Success');
        this.getUserInfo(true).subscribe(info => {
          this.getBrand();
          this.userStatus.SendUserOrgChange(this.user);
          // first navigate to a path that all users have access to, then navigate to root so that root will always be reloaded
          this.router.navigate(['/user-profile']).then(() => {
            this.router.navigate(['/'])
          });
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.userInfoPolling.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



  toggleSidenav() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }
}
