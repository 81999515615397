import { Component, OnInit } from '@angular/core';
import { MarketplaceStatus, OrganizationType, ProgramKeywordViewModelRead, ProgramListViewModelRead, ProgramSearchParams, ProgramStatusViewModelRead } from 'src/app/_models/generatedModels';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerSortableList } from 'src/app/_helpers/serverSortableList';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bxl-programs',
  templateUrl: 'programs.component.html',
})
export class ProgramsComponent implements OnInit {

  initialized = false;
  programs: ServerSortableList<ProgramListViewModelRead>;
  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;
  editUrl: string = '../programs/edit';
  previewUrl: string = '../programs/view';
  openAssignProgramDialog: boolean = false;
  openAssignTeamProgramDialog: boolean = false;
  currentProgramId: number;
  currentProgramName: string;
  searching: boolean = false;
  filterChanged: Subject<string> = new Subject<string>();
  filter = '';
  marketplaceStatus = MarketplaceStatus;
  hasMarketplace: boolean = false;
  canEditGlobalLibrary: boolean = false;

  programKeywordsInitialized: boolean = false;
  programKeywords: ProgramKeywordViewModelRead[];
  programStatuses: ProgramStatusViewModelRead[] = [{ name: "Active", value: false }, { name: "Archived", value: true }];
  programKeywordFilter: number;
  coachFilter: number = null;
  showArchived: boolean = false;
  showArchivedStorageKey: string = "rundna-persistedInput-showArchivedStorageKey";

  constructor(private programService: ProgramsService, public auth: AuthenticationService, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void
  {
    if (localStorage.getItem(this.showArchivedStorageKey)) {
      this.showArchived = (localStorage.getItem(this.showArchivedStorageKey) === 'true');
    }

    this.breadcrumbs.SetSecondaryBreadcrumb('Programs', 'programs', []);
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
    this.programs = new ServerSortableList<ProgramListViewModelRead>([], 'Name', false);
    this.searchPrograms();
    this.loadProgramKeywords();
    
    this.auth.fetchUserProfile().subscribe(user => {
      if (user.organizationMarketplaceStatus & this.marketplaceStatus.Active) {
        this.hasMarketplace = true;
      }
    });

    this.filterChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((model) => {
      this.filter = model;
      this.currentPage = 1;
      this.searchPrograms();
    });

    this.programs.sortChanged.subscribe(result => {
      this.currentPage = 1;
      this.searchPrograms();
    });
  }

  searchPrograms() {
    this.searching = true;
    let model = new ProgramSearchParams();
    model.searchText = this.filter;
    model.page = this.currentPage;
    model.size = this.itemsPerPage;
    model.sortProperty = this.programs.column;
    model.isSortDesc = this.programs.isDesc;
    model.programKeywordId = this.programKeywordFilter || null;
    model.coachId = this.coachFilter;
    model.showArchived = this.showArchived;

    this.programService.searchForPrograms(model).subscribe((results) => {
      this.totalItems = results.rowCount;
      this.programs.updateData(results.results);
      this.searching = false;
      this.initialized = true;
    });
  }

  loadProgramKeywords() {
    this.programService.getProgramKeywordsForOrganization().subscribe(result => {
      this.programKeywords = result;
      this.programKeywordsInitialized = true;
    });
  }

  onShowArchivedChanged(value: any)
  {
    if (value === undefined)
      value = false;

    this.showArchived = value;
    localStorage.setItem(this.showArchivedStorageKey, value.toString());
    this.searchPrograms();
  }

  onFilterChanged(query: string) {
    this.filterChanged.next(query);
  }

  onKeywordFilterChange(event: any) {
    this.currentPage = 1;
    this.searchPrograms();
  }

  onCoachFilterChange(event: any) {
    this.currentPage = 1;
    this.searchPrograms();
  }

  pageChanged(event: any): void {
    this.searchPrograms();
  }

  onDelete(programId: number) {
    this.toastr.confirmDialog('Are you sure you want to delete this program? This action cannot be undone.', 'Delete Program', 'Delete Program', 'Cancel').subscribe(result => {
      if (result) {
        this.programService.deleteProgram(programId).subscribe(result => {
          this.programs.remove(x => x.id === programId);
          this.toastr.success('Program Deleted', 'Success');
        },
          (err: HttpErrorResponse) =>
          {
            if (err && err.error && err.error.indexOf('ApplicationException') >= 0) {
              this.toastr.error('Program can not be deleted because it is in use. It is assigned to one or more users. You can try to archive the program.', 'Error');
            } else {
              this.toastr.error('An error occurred while deleting the program', 'Error');
            }
          });
      }
    });
  }

  onArchive(programId: number)
  {
    this.toastr.confirmDialog('Archive means the program can not be assigned to users/teams anymore and it will not be visible in marketplace. You will be able to restore the program anytime. Users who had the program assigned can still see and reschedule it.  Are you sure you want to archive this program? ', 'Archive Program', 'Archive Program', 'Cancel', 340).subscribe(result =>
    {
      if (result) {
        this.programService.archiveProgram(programId).subscribe(result =>
        {
          this.toastr.success('Program Archived', 'Success');
          this.searchPrograms();
        },
          (err: HttpErrorResponse) =>
          {
            if (err && err.error && err.error.indexOf('ApplicationException') >= 0) {
              this.toastr.error('Program can not be archived because it is in use. It might be assigned to movement categories or surveys', 'Error');
            } else {
              this.toastr.error('An error occurred while archiving the program', 'Error');
            }
          });
      }
    });
  }

  checkAllowedActions(program: ProgramListViewModelRead)
  {
    //Default values, we check on server side if a program can be deleted or archived
    program.canBeDeleted = true;
    program.canBeArchived = true;

    this.programService.getProgramAllowedActions(program.id).subscribe(result =>
    {
      program.canBeDeleted = result.canBeDeleted;
      program.canBeArchived = result.canBeArchived;
    });
  }

  onRestore(programId: number)
  {
    this.toastr.confirmDialog('Are you sure you want to restore this program?', 'Restore Program', 'Restore Program', 'Cancel').subscribe(result =>
    {
      if (result) {
        this.programService.restoreProgram(programId).subscribe(result =>
        {
          this.toastr.success('Program Restored', 'Success');
          this.searchPrograms();
        },
          (err: HttpErrorResponse) =>
          {
            this.toastr.error('An error occurred while restoring the program', 'Error');
          });
      }
    });
  }

  onAssign(programId: number, programName: string) {
    this.currentProgramId = programId;
    this.currentProgramName = programName;
    this.openAssignProgramDialog = true;
    this.searchPrograms();
  }

  onAssignToTeam(programId: number, programName: string) {
    this.currentProgramId = programId;
    this.currentProgramName = programName;
    this.openAssignTeamProgramDialog = true;
    this.searchPrograms();
  }

  onClone(programId: number) {
    this.programService.cloneProgram(this.auth.user.id, programId).subscribe(result => {
      this.toastr.success('Program Cloned', 'Success');
      this.router.navigate([this.editUrl, result.id], { relativeTo: this.route });
      this.searchPrograms();
    });
  }

  onArchiveDisabled(event: Event)
  {
    this.toastr.error('Program can not be archived because it is in use. It might be assigned to categories or surveys.', '');
    event.stopPropagation();
  }

  onDeleteDisabled(event: Event)
  {
    this.toastr.error('Program can not be deleted because it is in use. It might be assigned to one or more users.', '');
    event.stopPropagation();
  }
}
