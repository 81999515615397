import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'view-gc-assessment',
  templateUrl: 'view-gaitcapability-assessment.component.html',
})
export class ViewGaitCapabilityAssessmentComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }

}
