<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-6">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block mb-0">Gait Capacity Assessment</h6>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Fundamental section-->
  <div class="mt--6">
    <form class="form" [formGroup]="formGroup" novalidate>
      <div formGroupName="gaitCapabilityAssessmentBase">
        <div class="card mb-4">
          <div class="card-header"><h5 class="mb-0">Fundamental</h5></div>
          <div class="card-body" formGroupName="functional">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center bg-lighter pt-1 pb-1">
                  <div class="col-lg-6 col-md-12">
                    <h5>Toe Touch <button type="button" (click)="showHelp('toeTouchGc')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <select class="form-control" formControlName="toeTouch" id="exampleFormControlSelect1">
                      <option [ngValue]="'Able'">Able</option>
                      <option [ngValue]="'Unable'">Unable</option>
                    </select>
                  </div>
                </div>

                <div class="row row align-items-center bg-lighter pt-1 pb-1">
                  <div class="col-lg-6 col-md-12">
                    <h5>Rotation <button type="button" (click)="showHelp('rotationGc')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="rotationRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter pt-1 pb-1">
                  <div class="col-lg-6 col-md-12">
                    <h5>4 Stage Balance <button type="button" (click)="showHelp('4stageBalance')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="balanceLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="balanceRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter pt-1 pb-1">
                  <div class="col-lg-6 col-md-12">
                    <h5>Hurdle <button type="button" (click)="showHelp('hurdle')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="hurdleLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="hurdleRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <!--Mobility section-->
        <div class="card mb-4">
          <div class="card-header"><h5 class="mb-0">Mobility</h5></div>
          <div class="card-body" formGroupName="mobility">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Kneeling Dorsiflexion <button type="button" (click)="showHelp('kneelingDorsiflexion')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="ankleLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="ankleRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>ASLR <button type="button" (click)="showHelp('aslr')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="hamstringsLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="hamstringsRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Thomas <button type="button" (click)="showHelp('thomas')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="hipLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="hipRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--Stability section-->
        <div class="card mb-4">
          <div class="card-header"><h5 class="mb-0">Stability</h5></div>
          <div class="card-body" formGroupName="stability">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>UHBE <button type="button" (click)="showHelp('uhbe')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="coreLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="coreRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter pt-1 pb-1">
                  <div class="col-lg-6 col-md-12">
                    <h5>Sit To Stand (30 Sec) <button type="button" (click)="showHelp('sitToStand30sec')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <select class="form-control" formControlName="hip" id="exampleFormControlSelect1">
                      <option [ngValue]="'Able'">Able</option>
                      <option [ngValue]="'Unable'">Unable</option>
                    </select>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Calf Raise <button type="button" (click)="showHelp('calfRaise')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="calfLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="calfRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Paper Grip <button type="button" (click)="showHelp('paperGrip')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="footLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="footRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Foot section-->
        <div class="card mb-4">
          <div class="card-header"><h5 class="mb-0">Foot</h5></div>
          <div class="card-body"  formGroupName="foot">
            <div class="container">
              <div class="mr-auto">
                <div class="row">
                  <div class="col"></div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Hallux Extension <button type="button" (click)="showHelp('halluxExtension')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="bigToeLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="bigToeRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Toe Splay <button type="button" (click)="showHelp('toeSplay')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="splayLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="splayRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row align-items-center bg-lighter">
                  <div class="col-lg-6 col-md-12">
                    <div class="">
                      <h5>Hallux ABD <button type="button" (click)="showHelp('halluxABD')" class="btn btn-sm  btn-icon-only"><span class="btn-inner--icon"><i class="fas fa-info-circle"></i></span></button></h5>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="row">
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Left</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="pronationLeft" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <span class="ml-2 text-muted text-xs">Right</span>
                        <div class="form-group">
                          <select class="form-control" formControlName="pronationRight" id="exampleFormControlSelect1">
                            <option [ngValue]="'Able'">Able</option>
                            <option [ngValue]="'Unable'">Unable</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <h5>Notes: </h5><textarea class="form-control" formControlName="notes" maxlength="250" type="text"></textarea>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
        </div>
        <div class="col text-right">
          <button (click)="onSave(true)" [promiseBtn]="submitComplete" class="btn btn-secondary">Save for Later</button>
          <button (click)="onSave(false)" [promiseBtn]="submitComplete" class="btn btn-primary">Finish</button>
        </div>
      </div>
    </form>
  </div>
</div>

