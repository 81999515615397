<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="cs_wrapper">
  <div *ngIf="!isShared" class="row">
    <div class="col-lg-12 mt-1 mb-2">
      <div class="float-right d-none d-lg-block">
        <a href="javascript:void(0)" (click)="onCopyLink()" class="btn btn-sm btn-primary ml-2"> Shareable Link </a>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <td>
          <div class="cs_header">
            <div class="cs_list">
              <div class="cs_list-item">
                <span class="cs_hl">Name:</span> {{comparisonModel.newerGaitReport.user.firstName}} {{comparisonModel.newerGaitReport.user.lastName}}
              </div>
              <div class="cs_list-item">
                <span class="cs_hl">Date:</span> {{comparisonModel.newerGaitReport.reportDate | date:'short' }}
              </div>
            </div>
            <a href="https://rundna.com/" target="_blank"><img class="cs_logo" src="../../../../assets/img/gaitreport/logo.png" alt="logo" /> </a>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="card card-body">
            <div class="cs_panel cs_rrp">
              <div class="cs_highlight-bg"></div>

              <div class="cs_stats-wrapper cs_gap-lg">
                <!--  -->
                <div class="cs_stats-row fillx3">
                  <div class="cs_stats-col">
                    <h2 class="text-center cs_bold">
                      RunDNA<br>Gait Score
                    </h2>
                  </div>
                  <div class="cs_stats-col to-center">
                    <div class="cs_score handicap_score lg colorg-{{ comparisonModel.olderGaitReport.gaitScoreDescription }}">{{comparisonModel.olderGaitReport.gaitScore}}</div>
                  </div>
                  <div class="cs_stats-col to-center">
                    <div class="cs_score handicap_score lg colorg-{{ comparisonModel.newerGaitReport.gaitScoreDescription }}">{{comparisonModel.newerGaitReport.gaitScore}}</div>
                  </div>
                </div>

                <!--  -->
                <div class="cs_stats-row fillx3">
                  <div class="cs_stats-col">&nbsp;</div>
                  <div class="cs_stats-col to-center">
                    <h3 class="text-center">
                      <p class="text-400 cs_rm">
                        <a *ngIf="!isShared" class="nav-link" target="_blank" href="javascript:void(0)" [routerLink]="[assessmentsUrl, comparisonModel.olderGaitReport.id]">{{ comparisonModel.olderGaitReport.name }}</a>
                      </p>
                      <p class="text-400 cs_rm" *ngIf="isShared"> {{ comparisonModel.olderGaitReport.name }} </p>
                      <div class="cs_bold"> {{ comparisonModel.olderGaitReport.reportDate  | date:'longDate'}} </div>
                    </h3>
                  </div>
                  <div class="cs_stats-col to-center">
                    <h3 class="text-center">
                      <p class="text-400 cs_rm">
                        <a *ngIf="!isShared" class="nav-link" target="_blank" href="javascript:void(0)" [routerLink]="[assessmentsUrl, comparisonModel.newerGaitReport.id]">{{ comparisonModel.newerGaitReport.name }}</a>
                      </p>
                      <p class="text-400 cs_rm" *ngIf="isShared"> {{ comparisonModel.newerGaitReport.name }} </p>
                      <div class="cs_bold"> {{ comparisonModel.newerGaitReport.reportDate  | date:'longDate' }} </div>
                    </h3>
                  </div>
                </div>
                <!--  -->

                <div class="cs_stats-row fillx3 cs_mt2">
                  <div class="cs_stats-col">
                    <h3 class="cs_bold">Trunk</h3>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.trunk.left | enumString: categoryScoreEnum | lowercase }}">
                      {{comparisonModel.olderGaitReport.trunk.left | enumString: categoryScoreEnum}}
                      <div class="cs_stats-col-label">Left</div>
                    </div>
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.trunk.right | enumString: categoryScoreEnum | lowercase }}">
                      {{comparisonModel.olderGaitReport.trunk.right | enumString: categoryScoreEnum}}
                      <div class="cs_stats-col-label">Right</div>
                    </div>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.trunk.left | enumString: categoryScoreEnum | lowercase }}">
                      {{comparisonModel.newerGaitReport.trunk.left | enumString: categoryScoreEnum}}
                      <div class="cs_stats-col-label">Left</div>
                    </div>
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.trunk.right | enumString: categoryScoreEnum | lowercase }}">
                      {{comparisonModel.newerGaitReport.trunk.right | enumString: categoryScoreEnum}}
                      <div class="cs_stats-col-label">Right</div>
                    </div>
                  </div>
                </div>
                <div class="cs_separator"></div>
                <!--  -->
                <div class="cs_stats-row fillx3">
                  <div class="cs_stats-col">
                    <h3 class="cs_bold">Pelvis</h3>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.pelvis.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.pelvis.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.pelvis.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.pelvis.right | enumString: categoryScoreEnum}}</div>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.pelvis.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.pelvis.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.pelvis.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.pelvis.right | enumString: categoryScoreEnum}}</div>
                  </div>
                </div>
                <div class="cs_separator"></div>
                <!--  -->
                <div class="cs_stats-row fillx3">
                  <div class="cs_stats-col">
                    <h3 class="cs_bold">Hips</h3>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.hip.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.hip.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.hip.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.hip.right | enumString: categoryScoreEnum}}</div>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.hip.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.hip.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.hip.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.hip.right | enumString: categoryScoreEnum}}</div>
                  </div>
                </div>
                <div class="cs_separator"></div>
                <!--  -->
                <div class="cs_stats-row fillx3">
                  <div class="cs_stats-col">
                    <h3 class="cs_bold">Knees</h3>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.knee.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.knee.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.knee.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.knee.right | enumString: categoryScoreEnum}}</div>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.knee.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.knee.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.knee.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.knee.right | enumString: categoryScoreEnum}}</div>
                  </div>
                </div>
                <div class="cs_separator"></div>
                <!--  -->
                <div class="cs_stats-row fillx3">
                  <div class="cs_stats-col">
                    <h3 class="cs_bold">Foot/Ankle</h3>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.foot.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.foot.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.olderGaitReport.foot.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.foot.right | enumString: categoryScoreEnum}}</div>
                  </div>
                  <div class="cs_stats-col cs_gap-md to-center">
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.foot.left | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.foot.left | enumString: categoryScoreEnum}}</div>
                    <div class="cs_score s-{{ comparisonModel.newerGaitReport.foot.right | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.foot.right | enumString: categoryScoreEnum}}</div>
                  </div>
                </div>
                <!--  -->
                <div class="cs_separator"></div>
                <div class="cs_stats-wrapper cs_gap-sm">
                  <div class="cs_stats-row">
                    <div class="cs_stats-col">
                      <h3 class="cs_bold">Saptial</h3>
                    </div>
                  </div>
                  <!--  -->
                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Stride Length</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.strideLength.left/1000 | number:'1.2-2'}} m</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.strideLength.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.strideLength.leftScore | enumString: categoryScoreEnum}}
                        <div class="cs_stats-col-label">Left</div>
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.strideLength.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.strideLength.rightScore | enumString: categoryScoreEnum}}
                        <div class="cs_stats-col-label">Right</div>
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.olderGaitReport.strideLength.right/1000 | number:'1.2-2'}} m</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.strideLength.left/1000 | number:'1.2-2'}} m</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.strideLength.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.strideLength.leftScore | enumString: categoryScoreEnum}}
                        <div class="cs_stats-col-label">Left</div>
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.strideLength.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.strideLength.rightScore | enumString: categoryScoreEnum}}
                        <div class="cs_stats-col-label">Right</div>
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.newerGaitReport.strideLength.right/1000 | number:'1.2-2'}} m</span>
                    </div>
                  </div>
                  <!--  -->
                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Ground Contact</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.groundContact.left}} ms</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.groundContact.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.groundContact.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.groundContact.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.groundContact.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.olderGaitReport.groundContact.right}} ms</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.groundContact.left}} ms</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.groundContact.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.groundContact.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.groundContact.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.groundContact.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.newerGaitReport.groundContact.right}} ms</span>
                    </div>
                  </div>
                  <!--  -->
                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Duty Factor</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.dutyFactor.left}} %</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.dutyFactor.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.dutyFactor.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.dutyFactor.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.dutyFactor.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.olderGaitReport.dutyFactor.right}} %</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.dutyFactor.left}} %</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.dutyFactor.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.dutyFactor.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.dutyFactor.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.dutyFactor.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.newerGaitReport.dutyFactor.right}} %</span>
                    </div>
                  </div>
                  <!--  -->
                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Double Support</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.doubleSupport.left}} %</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.doubleSupport.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.doubleSupport.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.doubleSupport.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.doubleSupport.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.olderGaitReport.doubleSupport.right}} %</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.doubleSupport.left}} %</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.doubleSupport.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.doubleSupport.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.doubleSupport.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.doubleSupport.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.newerGaitReport.doubleSupport.right}} %</span>
                    </div>
                  </div>
                  <!--  -->
                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Single Support</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.singleSupport.left}} %</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.singleSupport.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.singleSupport.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.singleSupport.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.singleSupport.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.olderGaitReport.singleSupport.right}} %</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.singleSupport.left}} %</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.singleSupport.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.singleSupport.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.singleSupport.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.singleSupport.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.newerGaitReport.singleSupport.right}} %</span>
                    </div>
                  </div>
                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Step Width</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.stepWidth.left/10 | number:'2.0-0'}} cm</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.stepWidth.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.stepWidth.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.stepWidth.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.olderGaitReport.stepWidth.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.olderGaitReport.stepWidth.right/10 | number:'2.0-0'}} cm</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.stepWidth.left/10 | number:'2.0-0'}} cm</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.stepWidth.leftScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.stepWidth.leftScore | enumString: categoryScoreEnum}}
                      </div>
                      &nbsp;
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.stepWidth.rightScore | enumString: categoryScoreEnum | lowercase }}">
                        {{comparisonModel.newerGaitReport.stepWidth.rightScore | enumString: categoryScoreEnum}}
                      </div>
                      <span class="cs_force-fill text-left">{{comparisonModel.newerGaitReport.stepWidth.right/10 | number:'2.0-0'}} cm</span>
                    </div>
                  </div>
                  <!--  -->
                </div>


                <div class="cs_separator"></div>
                <div class="cs_stats-wrapper cs_gap-sm">
                  <div class="cs_stats-row">
                    <div class="cs_stats-col">
                      <h3 class="cs_bold">General</h3>
                    </div>
                  </div>

                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">Gait Speed</h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.gaitSpeed.value}} mph</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.gaitSpeed.score | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.gaitSpeed.score | enumString: categoryScoreEnum}}</div>
                      <span class="cs_force-fill text-left">&nbsp;</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.gaitSpeed.value}} mph</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.gaitSpeed.score | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.gaitSpeed.score | enumString: categoryScoreEnum}}</div>
                      <span class="cs_force-fill text-left">&nbsp;</span>
                    </div>
                  </div>

                  <div class="cs_stats-row fillx3">
                    <div class="cs_stats-col">
                      <h3 class="text-400">
                        Cadence
                      </h3>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.olderGaitReport.cadence.value}} spm</span>
                      <div class="cs_score sm s-{{ comparisonModel.olderGaitReport.cadence.score | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.olderGaitReport.cadence.score | enumString: categoryScoreEnum}}</div>
                      <span class="cs_force-fill text-left">&nbsp;</span>
                    </div>
                    <div class="cs_stats-col cs_gap-sm to-center">
                      <span class="cs_force-fill text-right">{{comparisonModel.newerGaitReport.cadence.value}} spm</span>
                      <div class="cs_score sm s-{{ comparisonModel.newerGaitReport.cadence.score | enumString: categoryScoreEnum | lowercase }}">{{comparisonModel.newerGaitReport.cadence.score | enumString: categoryScoreEnum}}</div>
                      <span class="cs_force-fill text-left">&nbsp;</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!--  -->
            <div class="cs_panel rbg cs_rrp">
              <div class="cs_stats-row fillx3">
                <div class="cs_stats-col">
                  <h3 class="cs_bold">Primary Factor</h3>
                </div>
                <div class="cs_stats-col to-center">
                  <div class="cs_highlight cs_w-80">{{comparisonModel.olderGaitReport.primaryFactor}}</div>
                </div>
                <div class="cs_stats-col to-center">
                  <div class="cs_highlight cs_w-80">{{comparisonModel.newerGaitReport.primaryFactor}}</div>
                </div>
              </div>
            </div>
            <!--  -->

            <div class="cs_score-tab cs_mt1">
              <div class="cs_score-tab-item">
                <div class="cs_score-tab-item-label">
                  Good
                </div>
                <div class="cs_score-tab-item-review">
                  <div class="cs_score  s-g">g</div>
                  <div class="cs_score  s-gf">gf</div>
                </div>
              </div>
              <div class="cs_score-tab-item">
                <div class="cs_score-tab-item-label">
                  Fair
                </div>
                <div class="cs_score-tab-item-review">
                  <div class="cs_score  s-fg">fg</div>
                  <div class="cs_score  s-f">f</div>
                  <div class="cs_score  s-fp">fp</div>
                </div>
              </div>
              <div class="cs_score-tab-item">
                <div class="cs_score-tab-item-label">
                  Poor
                </div>
                <div class="cs_score-tab-item-review">
                  <div class="cs_score  s-pf">pf</div>
                  <div class="cs_score  s-p">p</div>
                </div>
              </div>
            </div>
            </div>

            <hr class="cs_page-break" />


            <!-- page 2 -->

            <div class="card card-body">
              <div class="cs_short-box cs_mt2">
                <h1 class="text-center">Scoring Factors</h1>
                <p class="text-center">Below you will find the individual factors that contribute to your RunDNA<br>Gait Score. A lower score means that you are closer to normal walking. </p>
              </div>

              <div class="cs_pbar-wrapper">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">Cadence</h3>
                    <span class="cs_middle"><i class="{{getArrowStyle(comparisonModel.cadence.differenceDirection, comparisonModel.cadence.comparisonResult)}}"></i>{{comparisonModel.cadence.differencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml pos-1">
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.cadence.score, comparisonModel.newerGaitReport.cadence.deviationPoints)}}%">
                      <span> {{comparisonModel.newerGaitReport.cadence.rawValue}} </span>
                      <span>&#9650;</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Cadence is the number of steps per minute that you take. Too high or too low may impact your walking speed and you balance
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Stride Length
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.strideLength.leftDifferenceDirection, comparisonModel.strideLength.leftComparisonResult)}}"></i>{{comparisonModel.strideLength.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.strideLength.rightDifferenceDirection, comparisonModel.strideLength.rightComparisonResult)}}"></i>{{comparisonModel.strideLength.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.strideLength.leftScore, comparisonModel.newerGaitReport.strideLength.deviationPointsLeft)}}%">
                      <span>{{getNumberFromNumber(comparisonModel.newerGaitReport.strideLength.rawValueLeft)/1000 | number:'1.2-2'}} m</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.strideLength.rightScore, comparisonModel.newerGaitReport.strideLength.deviationPointsRight)}}%">
                      <span>{{getNumberFromNumber(comparisonModel.newerGaitReport.strideLength.rawValueRight)/1000 | number:'1.2-2'}} m</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The distance you cover from one foot contact to the next on the same side is your stride length. Too large, too small, or asymmetries may impact your walking speed and balance
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Double Support
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.doubleSupport.leftDifferenceDirection, comparisonModel.doubleSupport.leftComparisonResult)}}"></i>{{comparisonModel.doubleSupport.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.doubleSupport.rightDifferenceDirection, comparisonModel.doubleSupport.rightComparisonResult)}}"></i>{{comparisonModel.doubleSupport.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.doubleSupport.leftScore, comparisonModel.newerGaitReport.doubleSupport.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.doubleSupport.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.doubleSupport.rightScore, comparisonModel.newerGaitReport.doubleSupport.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.doubleSupport.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Double support is the time that you spend during walking with both feet on the ground. People with pain, balance, or strength issues may shorten their time in Double Support
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Duty Factor
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.dutyFactor.leftDifferenceDirection, comparisonModel.dutyFactor.leftComparisonResult)}}"></i>{{comparisonModel.dutyFactor.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.dutyFactor.rightDifferenceDirection, comparisonModel.dutyFactor.rightComparisonResult)}}"></i>{{comparisonModel.dutyFactor.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.dutyFactor.leftScore, comparisonModel.newerGaitReport.dutyFactor.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.dutyFactor.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.dutyFactor.rightScore, comparisonModel.newerGaitReport.dutyFactor.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.dutyFactor.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The time spent with the foot on the ground versus in the air. People with pain, balance, or strength issues may see changes in Duty Factor
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Step Width
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.stepWidth.leftDifferenceDirection, comparisonModel.stepWidth.leftComparisonResult)}}"></i>{{comparisonModel.stepWidth.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.stepWidth.rightDifferenceDirection, comparisonModel.stepWidth.rightComparisonResult)}}"></i>{{comparisonModel.stepWidth.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.stepWidth.leftScore, comparisonModel.newerGaitReport.stepWidth.deviationPointsLeft)}}%">
                      <span>{{getNumberFromNumber(comparisonModel.newerGaitReport.stepWidth.rawValueLeft)/10 | number:'2.0-0'}} cm</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.stepWidth.rightScore, comparisonModel.newerGaitReport.stepWidth.deviationPointsRight)}}%">
                      <span>{{getNumberFromNumber(comparisonModel.newerGaitReport.stepWidth.rawValueRight)/10 | number:'2.0-0'}} cm</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Step width is related to balance and stability. Too high or too low may indicate potential balance <br />issues
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Stance Time
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.stanceTime.leftDifferenceDirection, comparisonModel.stanceTime.leftComparisonResult)}}"></i>{{comparisonModel.stanceTime.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.stanceTime.rightDifferenceDirection, comparisonModel.stanceTime.rightComparisonResult)}}"></i>{{comparisonModel.stanceTime.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.stanceTime.leftScore, comparisonModel.newerGaitReport.stanceTime.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.stanceTime.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.stanceTime.rightScore, comparisonModel.newerGaitReport.stanceTime.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.stanceTime.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The time spent with the foot on the ground. Differences from side to side may indicate limitations or compensations in your walking gait
                </p>
              </div>
              <!--  -->
            </div>


            <hr class="cs_page-break" />
            <!-- page 3 -->
            <div class="card card-body">
              <p class="cs_bpar-desc">
                IC = Initial contact, when the foot hits the ground<br>
                MS = Mid Stance, half way through stance phase<br>
                TS = Terminal Stance, right before the foot leaves the ground
              </p>

              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Hip Flexion IC
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.hipFlexion.leftDifferenceDirection, comparisonModel.hipFlexion.leftComparisonResult)}}"></i>{{comparisonModel.hipFlexion.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.hipFlexion.rightDifferenceDirection, comparisonModel.hipFlexion.rightComparisonResult)}}"></i>{{comparisonModel.hipFlexion.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.hipFlexion.leftScore, comparisonModel.newerGaitReport.hipFlexion.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.hipFlexion.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.hipFlexion.rightScore, comparisonModel.newerGaitReport.hipFlexion.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.hipFlexion.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The position of the hip when the foot hits the ground relates to your walking posture and how your body is ready to accept the forces of walking
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Knee Flexion IC
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.kneeFlexion.leftDifferenceDirection, comparisonModel.kneeFlexion.leftComparisonResult)}}"></i>{{comparisonModel.kneeFlexion.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.kneeFlexion.rightDifferenceDirection, comparisonModel.kneeFlexion.rightComparisonResult)}}"></i>{{comparisonModel.kneeFlexion.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.kneeFlexion.leftScore, comparisonModel.newerGaitReport.kneeFlexion.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.kneeFlexion.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.kneeFlexion.rightScore, comparisonModel.newerGaitReport.kneeFlexion.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.kneeFlexion.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The knee is often nearly straight when the foot hits the ground to allow it to aid in absorbing forces and propelling the body forward
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Ankle Flexion IC
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.ankleFlexion.leftDifferenceDirection, comparisonModel.ankleFlexion.leftComparisonResult)}}"></i>{{comparisonModel.ankleFlexion.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.ankleFlexion.rightDifferenceDirection, comparisonModel.ankleFlexion.rightComparisonResult)}}"></i>{{comparisonModel.ankleFlexion.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.ankleFlexion.leftScore, comparisonModel.newerGaitReport.ankleFlexion.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.ankleFlexion.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.ankleFlexion.rightScore, comparisonModel.newerGaitReport.ankleFlexion.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.ankleFlexion.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The position of the foot at initial contact plays a vital role in absorbing the forces and propeling the body forward
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Pelvic Rotation IC
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.pelvicRotation.leftDifferenceDirection, comparisonModel.pelvicRotation.leftComparisonResult)}}"></i>{{comparisonModel.pelvicRotation.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.pelvicRotation.rightDifferenceDirection, comparisonModel.pelvicRotation.rightComparisonResult)}}"></i>{{comparisonModel.pelvicRotation.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.pelvicRotation.leftScore, comparisonModel.newerGaitReport.pelvicRotation.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.pelvicRotation.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.pelvicRotation.rightScore, comparisonModel.newerGaitReport.pelvicRotation.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.pelvicRotation.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The position of the foot at initial contact plays a vital role in absorbing the forces and propeling the body forward
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Trunk Rotation IC
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.trunkRotation.leftDifferenceDirection, comparisonModel.trunkRotation.leftComparisonResult)}}"></i>{{comparisonModel.trunkRotation.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.trunkRotation.rightDifferenceDirection, comparisonModel.trunkRotation.rightComparisonResult)}}"></i>{{comparisonModel.trunkRotation.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.trunkRotation.leftScore, comparisonModel.newerGaitReport.trunkRotation.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.trunkRotation.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.trunkRotation.rightScore, comparisonModel.newerGaitReport.trunkRotation.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.trunkRotation.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The rotation of the upper body provides opposition to the lower body in order to maintain balance and stability
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Knee Flexion MS
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.kneeFlexionMs.leftDifferenceDirection, comparisonModel.kneeFlexionMs.leftComparisonResult)}}"></i>{{comparisonModel.kneeFlexionMs.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.kneeFlexionMs.rightDifferenceDirection, comparisonModel.kneeFlexionMs.rightComparisonResult)}}"></i>{{comparisonModel.kneeFlexionMs.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.kneeFlexionMs.leftScore, comparisonModel.newerGaitReport.kneeFlexionMs.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.kneeFlexionMs.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.kneeFlexionMs.rightScore, comparisonModel.newerGaitReport.kneeFlexionMs.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.kneeFlexionMs.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The position of the knee halfway through the stance phase allows for force absoprtion and propulsion of the body forward
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Peak Hip ADD
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.peakHipAdd.leftDifferenceDirection, comparisonModel.peakHipAdd.leftComparisonResult)}}"></i>{{comparisonModel.peakHipAdd.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.peakHipAdd.rightDifferenceDirection, comparisonModel.peakHipAdd.rightComparisonResult)}}"></i>{{comparisonModel.peakHipAdd.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPositionReverse(comparisonModel.newerGaitReport.peakHipAdd.leftScore, comparisonModel.newerGaitReport.peakHipAdd.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakHipAdd.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPositionReverse(comparisonModel.newerGaitReport.peakHipAdd.rightScore, comparisonModel.newerGaitReport.peakHipAdd.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakHipAdd.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The body absorbs forces by going into small amounts of hip adduction (knees coming closer together). Too high or low will shift where the body absorbs the forces
                </p>
              </div>
              <!--  -->
            </div>


            <hr class="cs_page-break" />
            <!-- page 4 -->
            <div class="card card-body">
              <p class="cs_bpar-desc">
                IC = Initial contact, when the foot hits the ground<br>
                MS = Mid Stance, half way through stance phase<br>
                TS = Terminal Stance, right before the foot leaves the ground
              </p>

              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Peak Hip IR
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.peakHipIr.leftDifferenceDirection, comparisonModel.peakHipIr.leftComparisonResult)}}"></i>{{comparisonModel.peakHipIr.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.peakHipIr.rightDifferenceDirection, comparisonModel.peakHipIr.rightComparisonResult)}}"></i>{{comparisonModel.peakHipIr.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPositionReverse(comparisonModel.newerGaitReport.peakHipIr.leftScore, comparisonModel.newerGaitReport.peakHipIr.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakHipIr.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPositionReverse(comparisonModel.newerGaitReport.peakHipIr.rightScore, comparisonModel.newerGaitReport.peakHipIr.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakHipIr.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The body absorbs forces by going into small amounts of rotation. Too high or low will shift where the body absorbs the forces
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Peak Ankle
                      Pronation
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.peakAnklePronation.leftDifferenceDirection, comparisonModel.peakAnklePronation.leftComparisonResult)}}"></i>{{comparisonModel.peakAnklePronation.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.peakAnklePronation.rightDifferenceDirection, comparisonModel.peakAnklePronation.rightComparisonResult)}}"></i>{{comparisonModel.peakAnklePronation.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.peakAnklePronation.leftScore, comparisonModel.newerGaitReport.peakAnklePronation.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakAnklePronation.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.peakAnklePronation.rightScore, comparisonModel.newerGaitReport.peakAnklePronation.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakAnklePronation.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Pronation is important to help absorb forces and engage the joints above the foot for normal walking mechanics. Too high or low may shift how the body absorbs forces
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Peak Pelvic Drop
                      MS
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.peakPelvicDrop.leftDifferenceDirection, comparisonModel.peakPelvicDrop.leftComparisonResult)}}"></i>{{comparisonModel.peakPelvicDrop.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.peakPelvicDrop.rightDifferenceDirection, comparisonModel.peakPelvicDrop.rightComparisonResult)}}"></i>{{comparisonModel.peakPelvicDrop.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.peakPelvicDrop.leftScore, comparisonModel.newerGaitReport.peakPelvicDrop.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakPelvicDrop.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.peakPelvicDrop.rightScore, comparisonModel.newerGaitReport.peakPelvicDrop.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakPelvicDrop.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  The hip muscles resist the pelvis from dropping to the opposite side, which can contribute to injuries if too high or low
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Pelvic Tilt TO
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.pelvicTilt.leftDifferenceDirection, comparisonModel.pelvicTilt.leftComparisonResult)}}"></i>{{comparisonModel.pelvicTilt.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.pelvicTilt.rightDifferenceDirection, comparisonModel.pelvicTilt.rightComparisonResult)}}"></i>{{comparisonModel.pelvicTilt.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.pelvicTilt.leftScore, comparisonModel.newerGaitReport.pelvicTilt.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.pelvicTilt.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.pelvicTilt.rightScore, comparisonModel.newerGaitReport.pelvicTilt.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.pelvicTilt.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Limited or excessive pelvis motion while walking can put strain on the hips and/or lower back
                  <br />
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Peak Foot ER
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.peakFoot.leftDifferenceDirection, comparisonModel.peakFoot.leftComparisonResult)}}"></i>{{comparisonModel.peakFoot.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.peakFoot.rightDifferenceDirection, comparisonModel.peakFoot.rightComparisonResult)}}"></i>{{comparisonModel.peakFoot.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.peakFoot.leftScore, comparisonModel.newerGaitReport.peakFoot.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakFoot.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.peakFoot.rightScore, comparisonModel.newerGaitReport.peakFoot.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.peakFoot.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Foot rotation may be present from limited ankle mobility and/or to shift weight bearing in the <br /> knee

                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Hip Extension TO
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.hipExtension.leftDifferenceDirection, comparisonModel.hipExtension.leftComparisonResult)}}"></i>{{comparisonModel.hipExtension.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.hipExtension.rightDifferenceDirection, comparisonModel.hipExtension.rightComparisonResult)}}"></i>{{comparisonModel.hipExtension.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.hipExtension.leftScore, comparisonModel.newerGaitReport.hipExtension.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.hipExtension.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPosition(comparisonModel.newerGaitReport.hipExtension.rightScore, comparisonModel.newerGaitReport.hipExtension.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.hipExtension.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  A certain amount of hip extension is need as the body propels forward walking. Too little or too much can place strain on the hips or lower back
                </p>
              </div>
              <!--  -->
              <div class="cs_pbar-wrapper ">
                <div class="cs_pbar-item">
                  <div class="cs_pbar-val">
                    <h3 class="cs_pbar-lable">
                      Ankle Supination
                      TO
                    </h3>
                    <span class="cs_middle">L<i class="{{getArrowStyle(comparisonModel.ankleSupination.leftDifferenceDirection, comparisonModel.ankleSupination.leftComparisonResult)}}"></i>{{comparisonModel.ankleSupination.leftDifferencePercentage}}%</span>
                    <span class="cs_middle">R<i class="{{getArrowStyle(comparisonModel.ankleSupination.rightDifferenceDirection, comparisonModel.ankleSupination.rightComparisonResult)}}"></i>{{comparisonModel.ankleSupination.rightDifferencePercentage}}%</span>
                  </div>
                  <div class="cs_pbar-line">
                    <div class="cs_bpar-mark cs-ml" style="left:{{calculateScoringFactorPositionReverse(comparisonModel.newerGaitReport.ankleSupination.leftScore, comparisonModel.newerGaitReport.ankleSupination.deviationPointsLeft)}}%">
                      <span>{{comparisonModel.newerGaitReport.ankleSupination.rawValueLeft}}</span>
                      <span>L</span>
                    </div>
                    <div class="cs_bpar-mark cs-mr" style="left:{{calculateScoringFactorPositionReverse(comparisonModel.newerGaitReport.ankleSupination.rightScore, comparisonModel.newerGaitReport.ankleSupination.deviationPointsRight)}}%">
                      <span>{{comparisonModel.newerGaitReport.ankleSupination.rawValueRight}}</span>
                      <span>R</span>
                    </div>
                  </div>
                </div>
                <p class="cs_pbar_description">
                  Ankle supination is part of walking where the foot is on the pinky toe side and is needed to generate forces to propel the body forward. Too high or low may shifts forces
                </p>
              </div>
              <!--  -->
            </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
