import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentResult, AssessmentViewModelRead, CreateAssessmentDTO } from 'src/app/_models/generatedModels';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseToolTipModalComponent } from './exercise-tool-tip-modal.component';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { AssessmentProgramDialogComponent } from 'src/app/_components/assessment-program-assignment/assessment-program-dialog.component';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bxl-add-edit-assessment',
  templateUrl: 'add-edit-assessment.component.html',
  styleUrls: ['add-edit-assessment.component.scss']
})
export class AddEditAssessmentComponent extends FormCanDeactivate implements OnInit {
  initialized = false;
  assessmentId: number;
  assessment: AssessmentViewModelRead;
  assessmentResult = AssessmentResult;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToasterService, private modalService: NgbModal, private assessmentService: AssessmentService,
    private fb: FormBuilder, private breadcrumbs: BreadcrumbsService) {
      super();
    }

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb((this.assessmentId ? 'Edit ' : 'Add ') + 'Assessment', this.router.url, []);

    if (this.assessmentId) {
      this.assessmentService.getAssessmentById(this.clientUserId, this.assessmentId).subscribe(result => {
        this.assessment = result;
        this.setupForm();
      },
        (err: HttpErrorResponse) =>
        {
          if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
            this.toastr.error('You do not have access to the assessment', 'Error',);
          } else {
            this.toastr.error('An error occurred while loading the assessment', 'Error');
          }

          this.router.navigate(['../../../'], { relativeTo: this.route });

        });
    } else {
      this.setupForm();
    }
  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      toeTouch: [this.assessmentResult[this.assessment?.toeTouch] || 'Able', [Validators.required]],
      toeTouchStraighLegRaiseLeft: [this.assessmentResult[this.assessment?.toeTouchStraighLegRaiseLeft] || 'Able', [Validators.required]],
      toeTouchStraighLegRaiseRight: [this.assessmentResult[this.assessment?.toeTouchStraighLegRaiseRight] || 'Able', [Validators.required]],
      toeTouchChildPose: [this.assessmentResult[this.assessment?.toeTouchChildPose] || 'Able', [Validators.required]],

      // Backbend
      backBend: [this.assessmentResult[this.assessment?.backBend] || 'Able', [Validators.required]],
      backBendProneHipExtensionLeft: [this.assessmentResult[this.assessment?.backBendProneHipExtensionLeft] || 'Able', [Validators.required]],
      backBendProneHipExtensionRight: [this.assessmentResult[this.assessment?.backBendProneHipExtensionRight] || 'Able', [Validators.required]],
      backBendPronePressUp: [this.assessmentResult[this.assessment?.backBendPronePressUp] || 'Able', [Validators.required]],

      // rotation
      rotationLeft: [this.assessmentResult[this.assessment?.rotationLeft] || 'Able', [Validators.required]],
      rotationRight: [this.assessmentResult[this.assessment?.rotationRight] || 'Able', [Validators.required]],
      rotationQuadrupedRotationLeft: [this.assessmentResult[this.assessment?.rotationQuadrupedRotationLeft] || 'Able', [Validators.required]],
      rotationQuadrupedRotationRight: [this.assessmentResult[this.assessment?.rotationQuadrupedRotationRight] || 'Able', [Validators.required]],
      rotationProneHipExternalRotationLeft: [this.assessmentResult[this.assessment?.rotationProneHipExternalRotationLeft] || 'Able', [Validators.required]],
      rotationProneHipExternalRotationRight: [this.assessmentResult[this.assessment?.rotationProneHipExternalRotationRight] || 'Able', [Validators.required]],
      rotationProneHipInternalRotationLeft: [this.assessmentResult[this.assessment?.rotationProneHipInternalRotationLeft] || 'Able', [Validators.required]],
      rotationProneHipInternalRotationRight: [this.assessmentResult[this.assessment?.rotationProneHipInternalRotationRight] || 'Able', [Validators.required]],

      // single leg balance
      singleLegBalanceLeft: [this.assessmentResult[this.assessment?.singleLegBalanceLeft] || 'Able', [Validators.required]],
      singleLegBalanceRight: [this.assessmentResult[this.assessment?.singleLegBalanceRight] || 'Able', [Validators.required]],
      singleLegBalanceHalfKneelLeft: [this.assessmentResult[this.assessment?.singleLegBalanceHalfKneelLeft] || 'Able', [Validators.required]],
      singleLegBalanceHalfKneelRight: [this.assessmentResult[this.assessment?.singleLegBalanceHalfKneelRight] || 'Able', [Validators.required]],
      singleLegBalanceFootAssessment: [this.assessmentResult[this.assessment?.singleLegBalanceFootAssessment] || 'Able', [Validators.required]],

      // squat
      squat: [this.assessmentResult[this.assessment?.squat] || 'Able', [Validators.required]],
      squatDKTCLeft: [this.assessmentResult[this.assessment?.squatDKTCLeft] || 'Able', [Validators.required]],
      squatDKTCRight: [this.assessmentResult[this.assessment?.squatDKTCRight] || 'Able', [Validators.required]],
      squatLungeDorsiflexionLeft: [this.assessmentResult[this.assessment?.squatLungeDorsiflexionLeft] || 'Able', [Validators.required]],
      squatLungeDorsiflexionRight: [this.assessmentResult[this.assessment?.squatLungeDorsiflexionRight] || 'Able', [Validators.required]],

      singleLegSitToStandLeft: [this.assessmentResult[this.assessment?.singleLegSitToStandLeft] || 'Able', [Validators.required]],
      singleLegSitToStandRight: [this.assessmentResult[this.assessment?.singleLegSitToStandRight] || 'Able', [Validators.required]],
      singleLegCalfRaiseLeft: [this.assessmentResult[this.assessment?.singleLegCalfRaiseLeft] || 'Able', [Validators.required]],
      singleLegCalfRaiseRight: [this.assessmentResult[this.assessment?.singleLegCalfRaiseRight] || 'Able', [Validators.required]],
      sidePlankLeft: [this.assessmentResult[this.assessment?.sidePlankLeft] || 'Able', [Validators.required]],
      sidePlankRight: [this.assessmentResult[this.assessment?.sidePlankRight] || 'Able', [Validators.required]],
      unilateralHipBridgeEnduranceLeft: [this.assessmentResult[this.assessment?.unilateralHipBridgeEnduranceLeft] || 'Able', [Validators.required]],
      unilateralHipBridgeEnduranceRight: [this.assessmentResult[this.assessment?.unilateralHipBridgeEnduranceRight] || 'Able', [Validators.required]],

      toeSplayLeft: [this.assessmentResult[this.assessment?.toeSplayLeft] || 'Able', [Validators.required]],
      toeSplayRight: [this.assessmentResult[this.assessment?.toeSplayRight] || 'Able', [Validators.required]],
      halluxExtensionLeft: [this.assessmentResult[this.assessment?.halluxExtensionLeft] || 'Able', [Validators.required]],
      halluxExtensionRight: [this.assessmentResult[this.assessment?.halluxExtensionRight] || 'Able', [Validators.required]],
      halluxExtensionAbductionLeft: [this.assessmentResult[this.assessment?.halluxExtensionAbductionLeft] || 'Able', [Validators.required]],
      halluxExtensionAbductionRight: [this.assessmentResult[this.assessment?.halluxExtensionAbductionRight] || 'Able', [Validators.required]],
      ankleInversionLeft: [this.assessmentResult[this.assessment?.ankleInversionLeft] || 'Able', [Validators.required]],
      ankleInversionRight: [this.assessmentResult[this.assessment?.ankleInversionRight] || 'Able', [Validators.required]],
      ankleEversionLeft: [this.assessmentResult[this.assessment?.ankleEversionLeft] || 'Able', [Validators.required]],
      ankleEversionRight: [this.assessmentResult[this.assessment?.ankleEversionRight] || 'Able', [Validators.required]],

      impairmentCategory: [null],
      notes: [this.assessment?.notes || null]
    });

    this.formGroup.markFormDirtyOnValueChange().subscribe();
    this.initialized = true;
  }

  public onSave(isDraft: boolean) {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: CreateAssessmentDTO = Object.assign({}, this.formGroup.value, this.formGroup.value);
      if (this.assessmentId) {
        this.update(formData, isDraft, resetButton);
      } else {
        this.save(formData, isDraft, resetButton);
      }
    });
  }

  showHelp(key: string) {
    const modalRef = this.modalService.open(ExerciseToolTipModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.key = key;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  save(formData: CreateAssessmentDTO, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.createAssessment(this.clientUserId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Saved', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  update(formData: CreateAssessmentDTO, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.updateAssessment(this.assessmentId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Updated', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  openAssessmentDialog(assessmentId: number) {
    const modalRef = this.modalService.open(AssessmentProgramDialogComponent, { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;

    modalRef.result.then(
      (result) => {},
      (reason) => {
        this.navigateBack();
      }
    );
  }

  cancel() {
    this.navigateBack();
  }

  navigateBack() {
    if (this.assessmentId) {
      this.router.navigate(['../../../list'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../list'], { relativeTo: this.route });
    }
  }
}
