import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/_models/generatedEnums';
import { DailyStatusSettingsViewModel, DailyStatusViewModel, DailyStatusViewModelRead } from 'src/app/_models/generatedModels';
import { ClientsService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';

@Component({
  selector: 'bxl-daily-status-modal',
  templateUrl: 'daily-status-modal.component.html',
  styleUrls: ['daily-status-modal.component.scss']
})
export class DailyStatusModalComponent implements OnInit {
  initialized = false;
  lastDayEntry: DailyStatusViewModelRead;
  entry: DailyStatusViewModelRead;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  smallStep: number = 1;
  mediumStep: number = 5;
  painType = Enums.PainTypeEnum;
  hideDailyStatus: boolean;

  @Input()
  userId: number;

  @Input()
  userCredencialId: number;

  @Input()
  dailyStatusId: number;

  @Input()
  lastDayStatusId: number;


  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private toastr: ToasterService, private clientService: ClientsService) { }

  ngOnInit(): void
  {
    const timeZoneOffset = new Date().getTimezoneOffset();

    if (this.dailyStatusId) {
      this.clientService.getDailyStatus(this.dailyStatusId, timeZoneOffset).subscribe(result =>
      {
        this.entry = result;
        this.setupForm();

      });
    } else if (this.lastDayStatusId) {
      this.clientService.getDailyStatus(this.lastDayStatusId, timeZoneOffset).subscribe(result =>
      {
        this.lastDayEntry = result;
        this.setupForm();

      })
    }

    this.setupForm();

  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      ableToRun: [this.entry ? this.entry.ableToRun : null, [Validators.required]],
      isSick: [this.entry ? this.entry.isSick : null, [Validators.required]],
      runningInjury: [this.entry ? this.entry.runningInjury : null],
      percentEffort: [this.entry?.percentEffort || null, [Validators.required]],
      hasPain: [this.entry ? this.entry.hasPain : null, [Validators.required]],
      hasMuscleSoreness: [this.entry? this.entry.hasMuscleSoreness : null, [Validators.required]],
      painType: [this.entry?.painType || null],
      changedDailyRoutine: [this.entry ? this.entry.changedDailyRoutine : null, [Validators.required]],
      dailyStress: [this.entry?.dailyStress || 0],
      ateWell: [this.entry?.ateWell || 0],
      slept: [this.entry?.slept || 0],
      caffeineConsumption: [this.entry?.caffeineConsumption || 0],
      waterConsumption: [this.entry?.waterConsumption || 0],
      alcoholConsumption: [this.entry?.alcoholConsumption || 0],
      sexualActivity: [this.entry?.sexualActivity || 0],
      meditation: [this.entry?.meditation || 0],
      exerciseAmount: [this.entry?.exerciseAmount || 0],
      exerciseIntensity: [this.entry?.exerciseIntensity || 0],
      steps: [this.entry?.steps || 0],
      travel: [this.entry?.travel || 0],
      other: [this.entry?.other || null],
      notes: [this.entry?.notes || null],
      hideDailyStatus: [this.hideDailyStatus || false]
    });

    if (this.dailyStatusId) {
        this.formGroup.disable();
      }
      this.initialized = true;
  }

    onCopyLastDay()
    {
      this.entry = this.lastDayEntry;
      this.setupForm();
    }

    onChangePain() {
      if (this.formGroup.get('hasPain').value) {
        this.formGroup.get('painType').setValidators([Validators.required]);
      }
      else {
        this.formGroup.get('painType').setValidators([]);
        this.formGroup.get('painType').setValue(null);
      }
      this.formGroup.get('painType').updateValueAndValidity();
    }

    onChangeAbleToRun() {
      if (!this.formGroup.get('ableToRun').value) {
        this.formGroup.get('runningInjury').setValidators([Validators.required]);
      }
      else {
        this.formGroup.get('runningInjury').setValidators([]);
        this.formGroup.get('runningInjury').setValue(null);
      }
      this.formGroup.get('runningInjury').updateValueAndValidity();
    }

    onSave() {
      if (!this.formGroup.valid) {
        this.formGroup.markAllControlsDirty();
        this.toastr.error('Please fill out all required fields', 'Error');
        return;
      }

      this.submitComplete = new Promise((resetButton:any, reject) => {
        const formData: DailyStatusViewModel = this.formGroup.value;
        formData.dailyStatusDate = new Date();

        this.clientService.addDailyStatus(this.userId, formData).subscribe((result) => {
          this.toastr.success('Daily Status Added', 'Success');
          resetButton();
          this.activeModal.dismiss('saved');
        });
      });
  }

  onhideDailyStatusChange(value: boolean)
  {
    this.toastr.confirmDialog('Daily status journals allow your coach to see how you are feeling and responding to workouts. Would you like to stop the prompts to fill these out? You can turn them back in the notification center if needed.', 'Confirm', 'Yes', 'No', 300)
      .subscribe(result =>
    {
      if (result) {
        let model = new DailyStatusSettingsViewModel();
        model.requestDailyStatus = false;
        model.userCredentialId = this.userCredencialId;

        this.clientService.updateDailyStatusSettings(model).subscribe(
          (user) =>
          {
            this.toastr.success('Daily Statuses Settings Updated', 'Success');
            this.activeModal.dismiss('hide');
          },
          (error) => { }
        );
      } else {
        this.formGroup.get('hideDailyStatus').setValue(false);
      }

    });
  }
}
