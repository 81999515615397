import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramsService, TrainingLoadPlannerService } from 'src/app/_services/generatedServices';
import { ProgramViewModelRead, ProgramWorkoutDayViewModelRead, WorkoutType, ProgramViewModel, ProgramKeywordViewModel, VideoType, TrainingLoadPeriodViewModelRead, TrainingLoadPeriodType, ProgramWeekViewModelRead, ClientDayEventType, OrganizationType, ProgramCategory, WorkoutActivityTypeDurationViewModelRead, MarketplaceStatus } from 'src/app/_models/generatedModels';
import { forkJoin, of, timer } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { dynamicSort, arrayMax, MilesKilometersToMilesPipe, enumToArray, getFlagEnumValueAsArray, localMeasurementDistanceName } from 'src/app/_helpers/extensions.module';
import { BasicObject, ProgramWeek, ProgramWorkoutDayCopy } from 'src/app/_models/models';
import { Enums } from 'src/app/_models/generatedEnums';
import { delay, map, switchMap } from 'rxjs/operators';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkoutViewModalComponent } from 'src/app/_components/workout-view-modal/workout-view-modal.component';
import { DragEndEvent, DragStartEvent } from '@progress/kendo-angular-sortable';
import { ProgramHelper } from 'src/app/_helpers/programs';
import { CopyProgramEventDialogComponent } from '../copy-program-event-dialog/copy-program-event-dialog.component';
import { AddProgramEventDialogComponent } from './add-program-event-dialog/add-program-event-dialog.component';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { ProgramQuickWorkoutViewModalComponent } from 'src/app/_components/program-quick-workout-view-modal/program-quick-workout-view-modal.component';

@Component({
  selector: 'bxl-add-edit-program',
  templateUrl: 'add-edit-program.component.html',
  styleUrls: ['add-edit-program.component.scss']
})
export class AddEditProgramComponent extends FormCanDeactivate implements OnInit {
  minPaidProgramPrice: number = 30;
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  public weeks: ProgramWeek[] = [];
  listUrl: string = '/library/programs';
  canEditGlobalLibrary: boolean = false;
  programId: number;
  VideoType = VideoType;
  program: ProgramViewModelRead;
  keywordsList: string[];
  workoutType = WorkoutType;
  currentWeekIndex: number;
  currentWeekDayIndex: number;
  existingWorkoutRecord: boolean;
  dynamicSort = dynamicSort;
  //cardioExerciseTypeEnum = Enums.CardioExerciseTypeEnum;
  showGeneralSection: boolean = true;
  //showWeeklyDurations: boolean = true;
  trainingLoadPeriodEnum = Enums.TrainingLoadPeriodTypeEnum;
  trainingLoadPeriodType = TrainingLoadPeriodType;
  showPeriods: boolean = false;
  periods: TrainingLoadPeriodViewModelRead[];
  eventType = ClientDayEventType;
  eventTypeEnum = Enums.ClientDayEventTypeEnum;
  programCategory = ProgramCategory;
  programCategoryEnum = Enums.ProgramCategoryEnum;
  marketplaceStatus = MarketplaceStatus;
  hasMarketplace: boolean = false;
  allProgramCategories: BasicObject[];
  globalMarketplaceProgramTypeEnum = Enums.GlobalMarketplaceProgramTypeEnum;
  globalMarketplaceExperienceLevelEnum = Enums.GlobalMarketplaceExperienceLevelEnum;
  localMeasurementName: string;

  // context menu logic
  selectedWorkoutDayDayNumber: number = null;
  selectedWorkoutDaySortOrder: number = null;

  //drag drop logic
  draggedEvent: ProgramWorkoutDayViewModelRead;
  draggedEventIndex: number;
  draggedEventWeekIndex: number;
  draggedEventDayIndex: number;

  // for Preview logic
  isPreview: boolean = false;
  programKeywords: string[];
  openAssignProgramDialog: boolean = false;
  openAssignTeamProgramDialog: boolean = false;
  currentProgramId: number;
  currentProgramName: string;

  constructor(private route: ActivatedRoute, private programData: ProgramsService, private auth: AuthenticationService,
    private router: Router, private fb: FormBuilder, private toastr: ToasterService, private breadcrumbs: BreadcrumbsService, private modalService: NgbModal,
    private plannerService: TrainingLoadPlannerService, private milesKilometersToMilesPipe: MilesKilometersToMilesPipe) {
      super();
    }

  ngOnInit(): void {
    this.isPreview = this.router.url.includes('/view');
    this.programId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb('Programs', this.listUrl, []);
    this.breadcrumbs.AppendBreadcrumb((this.isPreview ? 'Preview ' : (this.programId ? 'Edit ' : 'Add ')) + 'Program', this.router.url, []);
    this.allProgramCategories = enumToArray(this.programCategoryEnum);
    this.localMeasurementName = localMeasurementDistanceName();

    this.auth.fetchUserProfile().subscribe(user => {
      this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
      if (user.organizationMarketplaceStatus & this.marketplaceStatus.Active) {
        this.hasMarketplace = true;
      }
    });

    if (this.programId) {
    forkJoin([this.programData.getProgramById(this.programId, true), this.programData.getProgramKeywordsForOrganization(), this.plannerService.getTrainingLoadPeriods()]).subscribe(results => {
        this.program = results[0];
        this.keywordsList = results[1].map(x => x.name);
        this.programKeywords = this.program.programKeywords ? this.program.programKeywords.map(x => x.name) : [];
        this.periods = results[2];

        // make sure the program id is valid for this user
        if (!this.isPreview && !this.program.organizationId && !this.canEditGlobalLibrary) {
          this.router.navigate([this.listUrl]);
        }

        this.setupForm();
      });
    } else {
      forkJoin([this.programData.getProgramKeywordsForOrganization(), this.plannerService.getTrainingLoadPeriods()]).subscribe(results => {
        this.program = new ProgramViewModelRead();
        this.program.videoType = VideoType.Public;
        this.keywordsList = results[0].map(x => x.name);
        this.periods = results[1];
        this.setupForm();
      });
    }
  }

  setupForm() {
    this.formGroup = this.fb.group({
      name: [this.program.name, { validators: [Validators.required], asyncValidators: [this.validateNameNotTaken.bind(this)], updateOn: 'change' }],
      description: [this.program.description],
      videoLink: [this.program.videoLink],
      videoType: [this.program.videoType],
      videoId: [this.program.videoId],
      weekCount: [this.program.weekCount || 1],
      isPaidProgram: [this.program.isPaidProgram || false],
      price: [this.program.price],
      isHidden: [this.program.isHidden || false],
      isArchived: [this.program.isArchived || false],
      showInGlobalMarketplace: [this.program.showInGlobalMarketplace || false],
      globalMarketplaceExperienceLevel: [this.program.globalMarketplaceExperienceLevel],
      globalMarketplaceProgramType: [this.program.globalMarketplaceProgramType],
      programCategory: [getFlagEnumValueAsArray(this.program.programCategory, this.programCategoryEnum)],
      userId: [(!this.programId ? this.auth.user.id : this.program.userId)],
      programKeywords: [this.program.programKeywords ? this.program.programKeywords.map(x => x.name) : []],
      workoutDays: [[]]
    });

    this.handlePaidProgramRequiredFields();
    this.handleGlobalMarketplaceRequiredFields();

    if (this.programId) {

      this.weeks = ProgramHelper.populateProgramWeeks(this.program);

      // finally update duration and training load values
      for (let index = 0; index < this.program.weekCount; index++) {
        this.updateWeekDurations(index + 1);
        this.updateWeekTrainingLoad(index + 1);
        this.updateSuggestedTrainingLoadValues(index + 1);
      }
    } else {
      this.addWeek(0);
    }

    this.formGroup.markFormDirtyOnValueChange().subscribe();

    this.initialized = true;
  }

  addWeek(weekIndex: number) {
    let futureWeeks = this.weeks.filter(x => x.weekNumber > weekIndex);
    futureWeeks.forEach(week => {
      week.weekNumber = week.weekNumber + 1;
      week.trainingLoadPeriodType = null;
    })
    this.weeks.splice(weekIndex, 0, new ProgramWeek(weekIndex + 1));

    if (this.initialized) {
      for (let index = weekIndex; index < this.weeks.length; index++) {
        this.updateWeekArrayForMenuAndSortable(index);
      }
    }
  }

  onPaidProgramChange() {
    this.handlePaidProgramRequiredFields();
  }

  handlePaidProgramRequiredFields() {
    if (this.formGroup.get('isPaidProgram').value == true) {
      this.formGroup.get('price').setValidators([Validators.required, this.validateMinimumPrice(this.minPaidProgramPrice)]);
      this.formGroup.get('userId').setValidators([Validators.required]);
      this.formGroup.get('programKeywords').setValidators([Validators.required]);
    } else {
      this.formGroup.get('price').setValidators([]);
      this.formGroup.get('userId').setValidators([]);
      this.formGroup.get('programKeywords').setValidators([]);
    }

    this.formGroup.get('price').updateValueAndValidity();
    this.formGroup.get('userId').updateValueAndValidity();
    this.formGroup.get('programKeywords').updateValueAndValidity();
  }

  onGlobalMarketplaceChange() {
    this.handleGlobalMarketplaceRequiredFields();
  }

  handleGlobalMarketplaceRequiredFields() {
    if (this.formGroup.get('showInGlobalMarketplace').value == true) {
      this.formGroup.get('globalMarketplaceExperienceLevel').setValidators([Validators.required]);
      this.formGroup.get('globalMarketplaceProgramType').setValidators([Validators.required]);
    } else {
      this.formGroup.get('globalMarketplaceExperienceLevel').setValidators([]);
      this.formGroup.get('globalMarketplaceProgramType').setValidators([]);
    }

    this.formGroup.get('globalMarketplaceExperienceLevel').updateValueAndValidity();
    this.formGroup.get('globalMarketplaceProgramType').updateValueAndValidity();
  }

  updateWeekDurations(weekNumber: number) {
    // summarize durations by activity type
    let week = this.weeks.find(x => x.weekNumber === weekNumber);
    let durations = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout).map(x => x.workout.activityTypeDurations.map(duration => Object.assign(duration, {rateOfPerceivedExertion: x.workout.expectedRPE ?? 5}))).reduce((a, b) => { return a.concat(b); }, []);
    let quickWorkoutDurations = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.eventType == this.eventType.QuickWorkout).map(x => { return {workoutId: 0, activityType: x.quickWorkoutActivityType, expectedTime: x.quickWorkoutDuration, expectedMiles: x.quickWorkoutDistance, expectedKilometers: 0, rateOfPerceivedExertion: 5 } as WorkoutActivityTypeDurationViewModelRead}).reduce((a, b) => { return a.concat(b); }, []);
    durations.push(...quickWorkoutDurations);
    
    let result = [];
    durations.reduce((res, value) => {
      if (!res[value.activityType]) {
        res[value.activityType] = { activityType: value.activityType, duration: 0, distance: 0, trainingLoad: 0 };
        result.push(res[value.activityType])
      }
      res[value.activityType].duration += value.expectedTime;
      res[value.activityType].distance += this.milesKilometersToMilesPipe.transform(value.expectedMiles, value.expectedKilometers) ?? 0;
      res[value.activityType].trainingLoad += Math.round(value.rateOfPerceivedExertion * (value.expectedTime / 60.0));
      return res;
    }, {});
    week.activityTypeDurations = result.sort(dynamicSort('activityType'));
  }

  updateWeekTrainingLoad(weekNumber: number) {
    let week = this.weeks.find(x => x.weekNumber === weekNumber);
    week.scheduledTrainingLoad = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout).map(x => x.workout.expectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);
    week.scheduledTrainingLoad += week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.eventType == this.eventType.QuickWorkout).map(x => x.quickWorkoutExpectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);

    week.scheduledRPE1To4Load = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout && (x.workout.expectedRPE ?? 5) <= 4).map(x => x.workout.expectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);
    week.scheduledRPE5To6Load = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout && (x.workout.expectedRPE ?? 5) >= 5 && (x.workout.expectedRPE ?? 5) <= 6).map(x => x.workout.expectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);
    // all quick workouts will have RPE of 5 since RPE is not specified
    week.scheduledRPE5To6Load += week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.eventType == this.eventType.QuickWorkout).map(x => x.quickWorkoutExpectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);
    week.scheduledRPE7To8Load = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout && (x.workout.expectedRPE ?? 5) >= 7 && (x.workout.expectedRPE ?? 5) <= 8).map(x => x.workout.expectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);
    week.scheduledRPE9To10Load = week.programDays.map(x => x.workoutDays).reduce((a, b) => { return a.concat(b); }, []).filter(x => x.workout && (x.workout.expectedRPE ?? 5) >= 9).map(x => x.workout.expectedTrainingLoad).reduce((a, b) => { return a + b; }, 0);
  }

  onDeleteWeek(weekNumber: number) {
    this.toastr.confirmDialog('Are you sure you want to remove this week from the program?', 'Delete Week', 'Delete Week', 'Cancel').subscribe(result => {
      if (result) {
        this.weeks.remove(x => x.weekNumber === weekNumber);
        this.weeks.filter(x => x.weekNumber > weekNumber).forEach(week => {
          week.weekNumber = week.weekNumber - 1;
          this.updateWeekArrayForMenuAndSortable(week.weekNumber - 1);
        });
        this.formGroup.markAsDirty();
      }
    });
  }

  onCloneWeek(weekNumber: number) {
    let newWeek = JSON.parse(JSON.stringify(this.weeks.find(x => x.weekNumber === weekNumber)));
    newWeek.weekNumber = weekNumber + 1;
    delete newWeek.id;
    newWeek.programDays.forEach(day => {
      day.workoutDays.forEach(workout => {
        delete workout.id;
      });
    });
    // add cloned week
    this.weeks.splice(weekNumber, 0, newWeek);
    this.updateWeekArrayForMenuAndSortable(newWeek.weekNumber - 1);
    this.updateSuggestedTrainingLoadValues(newWeek.weekNumber);

    // update all the weeks after the inserted week
    for (let index = (weekNumber + 1); index < this.weeks.length; index++) {
      this.weeks[index].weekNumber = this.weeks[index].weekNumber + 1;
      this.updateWeekArrayForMenuAndSortable(index);
      this.updateSuggestedTrainingLoadValues(this.weeks[index].weekNumber);
    }
  }

  onMoveWeek(weekNumber: number, moveDirection: number) {
    const originalWeek = this.weeks.find(x => x.weekNumber === weekNumber);
    const swapWeek = this.weeks.find(x => x.weekNumber === weekNumber + moveDirection);
    originalWeek.weekNumber = weekNumber + moveDirection;
    swapWeek.weekNumber = weekNumber;
    this.weeks.sort(dynamicSort("weekNumber"));

    const firstAffectedWeek = originalWeek.weekNumber < swapWeek.weekNumber ? originalWeek.weekNumber : swapWeek.weekNumber;
    for (let index = (firstAffectedWeek - 1); index < this.weeks.length; index++) {
      this.updateWeekArrayForMenuAndSortable(index);
      this.updateSuggestedTrainingLoadValues(index);
    }
  }

  onOpenWorkoutDialog(clickEvent: Event, weekIndex: number, dayIndex: number, sortOrder: number) {
    clickEvent.stopPropagation();
    this.closeAllMenus();

    this.openWorkoutDialog(weekIndex, dayIndex, sortOrder, null, null);
  }

  openWorkoutDialog(weekIndex: number, dayIndex: number, sortOrder: number, workoutIdToModify: number, eventItem: ProgramWorkoutDayViewModelRead | null) {
    //if sort order is null, it means we are adding a new event instead of modifying an existing one
    const maxSortOrder = arrayMax(this.weeks[weekIndex].programDays[dayIndex].workoutDays.map(x => x.sortOrder));
    const workoutDayItem = sortOrder ? this.weeks[weekIndex].programDays[dayIndex].workoutDays.find(x => x.sortOrder === sortOrder) : new ProgramWorkoutDayViewModelRead();
    
    // this is the scenario where a quick workout is being copy/modified
    if (eventItem && eventItem.eventType == this.eventType.QuickWorkout) {
      workoutDayItem.eventType = eventItem.eventType;
      workoutDayItem.quickWorkoutName = eventItem.quickWorkoutName;
      workoutDayItem.quickWorkoutDescription = eventItem.quickWorkoutDescription;
      workoutDayItem.quickWorkoutActivityType = eventItem.quickWorkoutActivityType;
      workoutDayItem.quickWorkoutDuration = eventItem.quickWorkoutDuration;
      workoutDayItem.quickWorkoutDistance = eventItem.quickWorkoutDistance;
      workoutDayItem.quickWorkoutExpectedTrainingLoad = eventItem.quickWorkoutExpectedTrainingLoad;
    }

    this.currentWeekIndex = weekIndex;
    this.currentWeekDayIndex = dayIndex;

    const modalRef = this.modalService.open(AddProgramEventDialogComponent, { size: 'lg' });
    modalRef.componentInstance.workoutDayItem = workoutDayItem;
    modalRef.componentInstance.currentWeekIndex = weekIndex;
    modalRef.componentInstance.currentWeekDayIndex = dayIndex;
    modalRef.componentInstance.maxSortOrder = maxSortOrder;
    modalRef.componentInstance.workoutIdToModify = workoutIdToModify;

    modalRef.componentInstance.savedObject.subscribe((workout: ProgramWorkoutDayViewModelRead) => {
      this.saveWorkout(workout, sortOrder);
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onCopyEvent(clickEvent: Event, eventItem: ProgramWorkoutDayViewModelRead) {
    clickEvent.stopPropagation();
    this.closeAllMenus();

    const modalRef = this.modalService.open(CopyProgramEventDialogComponent, { size: 'lg', windowClass: 'modal-md-custom'});
    modalRef.componentInstance.workoutName = eventItem.workoutName;

    modalRef.componentInstance.savedObject.subscribe((eventCopyData: ProgramWorkoutDayCopy) => {
      this.currentWeekDayIndex = eventCopyData.weekDayNumber - 1;
      this.currentWeekIndex = eventCopyData.weekNumber - 1;
      if (this.weeks.length < this.currentWeekIndex + 1) {
        for (let i = this.weeks.length; i <= this.currentWeekIndex; i++) {
          this.addWeek(i);
        }
      }

      if (eventCopyData.copyOnly) {
        const maxSortOrder = arrayMax(this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.map(x => x.sortOrder));
        var copiedEvent = Object.assign({}, eventItem);
        copiedEvent.sortOrder = maxSortOrder + 1;
        this.saveWorkout(copiedEvent, null);
      } else {
        this.openWorkoutDialog(this.currentWeekIndex , this.currentWeekDayIndex, null, eventItem.workoutId || null, eventItem);
      }
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {
      }
    );
  }

  saveWorkout(eventItem: ProgramWorkoutDayViewModelRead, originalSortOrder: number) {
    // using sort order to see if item already exists
    if (originalSortOrder) {
        // if sort order hasn't changed, just update the data; otherwise, remove the item, and re-add it in correct spot and re-sort
        if (originalSortOrder === eventItem.sortOrder) {
          let workoutDayItem = this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.find(x => x.sortOrder === originalSortOrder);
          workoutDayItem.workoutId = eventItem.workoutId;
          workoutDayItem.workout = eventItem.workout;
          workoutDayItem.sortOrder = eventItem.sortOrder;
          workoutDayItem.coachNotes = eventItem.coachNotes;
          workoutDayItem.eventType = eventItem.eventType;
          workoutDayItem.eventName = eventItem.eventName;
          workoutDayItem.taskDescription = eventItem.taskDescription;
          workoutDayItem.workoutName = eventItem.workoutName;
          workoutDayItem.quickWorkoutName = eventItem.quickWorkoutName;
          workoutDayItem.quickWorkoutDescription = eventItem.quickWorkoutDescription;
          workoutDayItem.quickWorkoutActivityType = eventItem.quickWorkoutActivityType;
          workoutDayItem.quickWorkoutDuration = eventItem.quickWorkoutDuration;
          workoutDayItem.quickWorkoutDistance = eventItem.quickWorkoutDistance;
          workoutDayItem.quickWorkoutExpectedTrainingLoad = eventItem.quickWorkoutExpectedTrainingLoad;

        } else {
          this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.remove(x => x.sortOrder === originalSortOrder);
          this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.splice((eventItem.sortOrder - 1), 0, this.createWorkoutItem(eventItem));
          this.resortProgramDay(this.currentWeekIndex, this.currentWeekDayIndex);
        }

        this.updateWorkoutDayArrayForMenuAndSortable(this.currentWeekIndex, this.currentWeekDayIndex);
        this.updateWeekDurations(this.currentWeekIndex + 1);
        this.updateWeekTrainingLoad(this.currentWeekIndex + 1);
        if (this.currentWeekIndex == 0) {
          this.updateSuggestedTrainingLoadValuesForAffectedWeeks(this.currentWeekIndex + 1);
        }
    } else {
      const maxSortOrder = arrayMax(this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.map(x => x.sortOrder));
      // if sort order is > maxSortOrder, just add the record; otherwise add it in correct spot and re-sort
      if (eventItem.sortOrder > maxSortOrder) {
        this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.push(this.createWorkoutItem(eventItem));
      } else {
        this.weeks[this.currentWeekIndex].programDays[this.currentWeekDayIndex].workoutDays.splice((eventItem.sortOrder - 1), 0, this.createWorkoutItem(eventItem));
        this.resortProgramDay(this.currentWeekIndex, this.currentWeekDayIndex);
      }

      this.updateWorkoutDayArrayForMenuAndSortable(this.currentWeekIndex, this.currentWeekDayIndex);
      this.updateWeekDurations(this.currentWeekIndex + 1);
      this.updateWeekTrainingLoad(this.currentWeekIndex + 1);
      if (this.currentWeekIndex == 0) {
        this.updateSuggestedTrainingLoadValuesForAffectedWeeks(this.currentWeekIndex + 1);
      }
    }
    this.formGroup.markAsDirty();
  }

  createWorkoutItem(eventItem: ProgramWorkoutDayViewModelRead): ProgramWorkoutDayViewModelRead {
    const workoutDayItem = new ProgramWorkoutDayViewModelRead();

    workoutDayItem.workoutId = eventItem.workoutId;
    workoutDayItem.workout = eventItem.workout;
    workoutDayItem.sortOrder = eventItem.sortOrder;
    workoutDayItem.coachNotes = eventItem.coachNotes;
    workoutDayItem.eventType = eventItem.eventType;
    workoutDayItem.eventName = eventItem.eventName;
    workoutDayItem.taskDescription = eventItem.taskDescription;
    workoutDayItem.workoutName = eventItem.workoutName;
    workoutDayItem.quickWorkoutName = eventItem.quickWorkoutName;
    workoutDayItem.quickWorkoutDescription = eventItem.quickWorkoutDescription;
    workoutDayItem.quickWorkoutActivityType = eventItem.quickWorkoutActivityType;
    workoutDayItem.quickWorkoutDuration = eventItem.quickWorkoutDuration;
    workoutDayItem.quickWorkoutDistance = eventItem.quickWorkoutDistance;
    workoutDayItem.quickWorkoutExpectedTrainingLoad = eventItem.quickWorkoutExpectedTrainingLoad;

    return workoutDayItem;
  }

  resortProgramDay(weekIndex: number, dayIndex: number) {
    let workoutItems = this.weeks[weekIndex].programDays[dayIndex].workoutDays.sort(dynamicSort('sortOrder'));
    for (let i = 0; i < workoutItems.length; i++) {
      workoutItems[i].sortOrder = i+1;
    }
    workoutItems.sort(dynamicSort('sortOrder'));
  }

  calculateProgramDay(weekIndex: number, dayIndex: number) : number {
    const dayNumber = ((this.weeks[weekIndex].weekNumber - 1) * 7) + this.weeks[weekIndex].programDays[dayIndex].weekDayNumber;
    return dayNumber;
  }

  updateDayNumberForDay(weekIndex: number, dayIndex: number) {
    let workoutItems = this.weeks[weekIndex].programDays[dayIndex].workoutDays;
    const dayNumber = this.calculateProgramDay(weekIndex, dayIndex);
    workoutItems.forEach(workoutDay => {
      workoutDay.dayNumber = dayNumber;
    });
  }

  updateSortOrderForDay(weekIndex: number, dayIndex: number) {
    let counter = 1;
    let workoutItems = this.weeks[weekIndex].programDays[dayIndex].workoutDays;
    workoutItems.forEach(workoutDay => {
      workoutDay.sortOrder = counter;
      counter ++;
    });
    this.formGroup.markAsDirty();
  }

  updateWeekArrayForMenuAndSortable(weekIndex: number) {
    for (var i = 0; i < 7; i++) {
      this.updateDayNumberForDay(weekIndex, i);
    }
    this.formGroup.markAsDirty();
  }

  updateWorkoutDayArrayForMenuAndSortable(weekIndex: number, dayIndex: number) {
    // update workout day now so that event click will have unique values to use
    this.updateDayNumberForDay(weekIndex, dayIndex);

    // array reference has to be replaced for Kendo sortable to pick up the changes
    this.weeks[weekIndex].programDays[dayIndex].workoutDays = this.weeks[weekIndex].programDays[dayIndex].workoutDays.concat([]);
  }

  onDeleteEvent(clickEvent: Event, weekIndex: number, dayIndex: number, workoutDayIndex: number) {
    clickEvent.stopPropagation();
    this.closeAllMenus();

    this.toastr.confirmDialog('Are you sure you want to remove this event from the program?', 'Delete Event', 'Delete Event', 'Cancel').subscribe(result => {
      if (result) {
        this.weeks[weekIndex].programDays[dayIndex].workoutDays.splice(workoutDayIndex, 1);
        this.updateWeekDurations(weekIndex + 1);
        this.updateWeekTrainingLoad(weekIndex + 1);
        this.resortProgramDay(weekIndex, dayIndex);
        this.updateWorkoutDayArrayForMenuAndSortable(weekIndex, dayIndex);
        if (weekIndex == 0) {
          this.updateSuggestedTrainingLoadValuesForAffectedWeeks(weekIndex + 1);
        }
        this.formGroup.markAsDirty();
      }
    });
  }

  onPreviewWorkout(workoutId: number) {
    this.closeAllMenus();

    if (workoutId) {
      const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
      modalRef.componentInstance.workoutId = workoutId;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    }
  }

  onPreviewQuickWorkout(programWorkoutDay: ProgramWorkoutDayViewModelRead) {
    this.closeAllMenus();

    if (programWorkoutDay) {
      const modalRef = this.modalService.open(ProgramQuickWorkoutViewModalComponent, { size: 'lg' });
      modalRef.componentInstance.programWorkoutDay = programWorkoutDay;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    }
  }

  updateSuggestedTrainingLoadValuesForAffectedWeeks(startWeekNumber: number) {
    // calculate current week and any future weeks
    for (var i = startWeekNumber; i < this.weeks.length + 1; i++) {
      this.updateSuggestedTrainingLoadValues(i);
    }
  }

  onPeriodTypeChange(event: any, periodEl: HTMLSelectElement, weekNumber: number) {
    this.updateSuggestedTrainingLoadValuesForAffectedWeeks(weekNumber);
  }

  updateSuggestedTrainingLoadValues(weekNumber: number) {
    // week 1 is being used as the starting training load values for the subsequent weeks
    const week1 = this.weeks.find(x => x.weekNumber == 1);
    const startingTrainingLoad = week1 ? week1.scheduledTrainingLoad ?? 0 : 0;
    let currentWeek = this.weeks.find(x => x.weekNumber === weekNumber);
    const period = this.periods.find(x => x.trainingLoadPeriodType == (weekNumber == 1 ? TrainingLoadPeriodType.Maintenance : currentWeek.trainingLoadPeriodType));

    // can't update the week's values yet if a period hasn't been selected / but need to make sure we clear out existing values
    if (!startingTrainingLoad || !period) {
      currentWeek.suggestedTrainingLoad = 0;
      currentWeek.trainingLoadLow = 0;
      currentWeek.trainingLoadHigh = 0;
      currentWeek.rpE1To4Load = 0;
      currentWeek.rpE5To6Load = 0;
      currentWeek.rpE7To8Load = 0;
      currentWeek.rpE9To10Load = 0;
      return;
    }

    // if the previous week is a Skip week, keep going back until you find a non-skip week
    let previousWeekScheduledLoad;
    let previousWeek = this.weeks.find(x => x.weekNumber === (weekNumber - 1));
    if (previousWeek && (previousWeek.trainingLoadPeriodType == this.trainingLoadPeriodType.Skip || !previousWeek.trainingLoadPeriodType)) {
      let previousWeekNumber = weekNumber - 2;
      while (this.weeks.find(x => x.weekNumber === previousWeekNumber) && !previousWeekScheduledLoad) {
        const anotherPreviousWeek = this.weeks.find(x => x.weekNumber === previousWeekNumber);
        if (anotherPreviousWeek.trainingLoadPeriodType && anotherPreviousWeek.trainingLoadPeriodType != this.trainingLoadPeriodType.Skip) {
          previousWeekScheduledLoad = anotherPreviousWeek.scheduledTrainingLoad;
        }
        previousWeekNumber--;
      }
      previousWeekScheduledLoad = previousWeekScheduledLoad || startingTrainingLoad;
    } else {
      previousWeekScheduledLoad = (previousWeek ? previousWeek.scheduledTrainingLoad: startingTrainingLoad);
    }

    // calculate all the values
    const suggestedTrainingLoad = Math.round(period.trainingLoadMultiplier * previousWeekScheduledLoad);
    currentWeek.suggestedTrainingLoad = suggestedTrainingLoad;
    currentWeek.trainingLoadLow = Math.round(0.8 * previousWeekScheduledLoad);
    currentWeek.trainingLoadHigh = Math.round(1.3 * previousWeekScheduledLoad);
    currentWeek.rpE1To4Load = Math.round(period.rpE1To4Multiplier * suggestedTrainingLoad);
    currentWeek.rpE5To6Load = Math.round(period.rpE5To6Multiplier * suggestedTrainingLoad);
    currentWeek.rpE7To8Load = Math.round(period.rpE7To8Multiplier * suggestedTrainingLoad);
    currentWeek.rpE9To10Load = Math.round(period.rpE9To10Multiplier * suggestedTrainingLoad);

    if (period.trainingLoadPeriodType == this.trainingLoadPeriodType.Skip) {
      currentWeek.trainingLoadLow = 0;
      currentWeek.trainingLoadHigh = 0;
    }
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe(result => {
        if (result) {
          this.router.navigate([this.listUrl]);
        }
      });
    } else {
      this.router.navigate([this.listUrl]);
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.showGeneralSection = true;
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: ProgramViewModel = Object.assign({}, this.program, this.formGroup.getRawValue());
      formData.weekCount = this.weeks.length;
      formData.programKeywords = (this.formGroup.get('programKeywords').value as string[]).map(x => {return { name: x } as ProgramKeywordViewModel});
      formData.programCategory = (this.formGroup.get('programCategory').value as number[]).reduce((a, b) => a + b, 0) || null;

      let workoutDays: ProgramWorkoutDayViewModelRead[] = [];
      let programWeeks: ProgramWeekViewModelRead[] = [];
      this.weeks.forEach(week => {

        let programWeek = new ProgramWeekViewModelRead();
        programWeek.id = week.id;
        programWeek.programId = week.programId;
        programWeek.weekNumber = week.weekNumber;
        programWeek.trainingLoadPeriodType = week.trainingLoadPeriodType;
        programWeeks.push(programWeek);

        week.programDays.forEach(day => {
          const dayNumber = ((week.weekNumber - 1) * 7) + day.weekDayNumber;
          let counter = 1;
          day.workoutDays.sort(dynamicSort('sortOrder')).forEach(workoutDay => {
            workoutDay.dayNumber = dayNumber;
            workoutDay.sortOrder = counter;
            workoutDays.push(workoutDay);
            counter ++;
          });
        });
      });
      formData.workoutDays = workoutDays;
      formData.programWeeks = programWeeks;

      if (this.programId) {
        this.update(formData, resetButton);
      } else {
        // save the user who is creating the program
        formData.userId = formData.userId || this.auth.user.id;
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.programData.addProgram(formData).subscribe((result) => {
      this.toastr.success('Program Added', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    });
  }

  update(formData: any, resetButton: () => any) {
    this.programData.updateProgram(this.programId, formData).subscribe((result) => {
      this.toastr.success('Program Updated', 'Success');
      resetButton();
      this.router.navigate([this.listUrl]);
    });
  }

  validateNameNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.programData.isProgramNameDuplicate(control.value, this.programId || 0)),
      map((res)  => {
        return res ? { nameTaken: true } : null;
      })
    );
  }

  validateMinimumPrice(minPaidProgramPrice: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || Number(control.value) < minPaidProgramPrice) {
        return { minPrice: true }
      }
      return null;
    };
  }

  onAssign() {
    this.currentProgramId = this.program.id;
    this.currentProgramName = this.program.name;
    this.openAssignProgramDialog = true;
  }

  onAssignToTeam() {
    this.currentProgramId = this.program.id;
    this.currentProgramName = this.program.name;
    this.openAssignTeamProgramDialog = true;
  }

  onViewWorkout(workoutId: number) {
    if (workoutId) {
      const modalRef = this.modalService.open(WorkoutViewModalComponent, { size: 'lg' });
      modalRef.componentInstance.workoutId = workoutId;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    }
  }

  closeAllMenus() {
    this.selectedWorkoutDayDayNumber = null;
    this.selectedWorkoutDaySortOrder = null;
  }

  onClickedOutsideMenu(event: Event) {
    this.closeAllMenus();
  }

  onDayClick(clickEvent: Event, date: Date, weekIndex: number, dayIndex: number) {
    // don't show modal if someone is just clicking off of an event context menu
    if (this.selectedWorkoutDayDayNumber) {
      return;
    }

    clickEvent.stopPropagation();
    this.onOpenWorkoutDialog(clickEvent, weekIndex, dayIndex, null);
  }

  onEventClick(clickEvent: Event, event: ProgramWorkoutDayViewModelRead) {
    clickEvent.stopPropagation();

    if (this.selectedWorkoutDayDayNumber == event.dayNumber && this.selectedWorkoutDaySortOrder == event.sortOrder) {
      this.closeAllMenus();
    } else {
      this.selectedWorkoutDayDayNumber = event.dayNumber;
      this.selectedWorkoutDaySortOrder = event.sortOrder;
    }
  }

  onDragStart(e: DragStartEvent, weekIndex: number, dayIndex: number): void {
    this.draggedEvent = this.weeks[weekIndex].programDays[dayIndex].workoutDays[e.index];
    this.draggedEventIndex = e.index;
    this.draggedEventWeekIndex = weekIndex;
    this.draggedEventDayIndex = dayIndex;
  }

  onDragEnd(dragEvent: DragEndEvent, weekIndex: number, dayIndex: number) {
    this.closeAllMenus();

    // have to do it this way because of DragEnd bug
    let draggedEventNewIndex = this.weeks[weekIndex].programDays[dayIndex].workoutDays.findIndex((x) => x.sortOrder == this.draggedEvent.sortOrder && x.dayNumber == this.draggedEvent.dayNumber);

    // don't do anything if they dragged it to the original location
    if (!this.draggedEvent || (draggedEventNewIndex == this.draggedEventIndex && this.draggedEventWeekIndex == weekIndex && this.draggedEventDayIndex == dayIndex)) {
      return;
    }

    // adding a delay here bc onDragEnd event hasn't yet updated the original sortable to remove the item that was moved
    of(null)
      .pipe(delay(1000))
      .subscribe((result) => {
        this.updateSortOrderForDay(weekIndex, dayIndex);
        this.updateDayNumberForDay(weekIndex, dayIndex);
        this.updateWeekDurations(weekIndex + 1);
        this.updateWeekTrainingLoad(weekIndex + 1);
        if (weekIndex == 0) {
          this.updateSuggestedTrainingLoadValuesForAffectedWeeks(weekIndex + 1);
        }

        if (this.draggedEventDayIndex != dayIndex || this.draggedEventWeekIndex != weekIndex) {
          this.updateSortOrderForDay(this.draggedEventWeekIndex, this.draggedEventDayIndex);
          this.updateDayNumberForDay(this.draggedEventWeekIndex, this.draggedEventDayIndex);
          if (this.draggedEventWeekIndex != weekIndex) {
            this.updateWeekDurations(this.draggedEventWeekIndex + 1);
            this.updateWeekTrainingLoad(this.draggedEventWeekIndex + 1);
            if (this.draggedEventWeekIndex == 0) {
              this.updateSuggestedTrainingLoadValuesForAffectedWeeks(this.draggedEventWeekIndex + 1);
            }
          }
        }
    });
  }
}
