import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssessmentStatus, LoadingLevelsAssessmentViewModel, LoadingLevelsAssessmentViewModelRead } from 'src/app/_models/generatedModels';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { ExerciseToolTipModalComponent } from '../exercise-tool-tip-modal.component';
import { LoadingLevelsProgramDialogComponent } from 'src/app/_components/loading-levels-program-assignment/loading-levels-program-dialog.component';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bxl-add-loading-levels',
  templateUrl: 'add-loading-levels.component.html',
  styleUrls: ['add-loading-levels.component.scss']
})
export class AddLoadingLevelsComponent extends FormCanDeactivate implements OnInit {
  initialized = false;
  assessmentId: number;
  assessment: LoadingLevelsAssessmentViewModelRead;
  assessmentStatus = AssessmentStatus;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToasterService, private modalService: NgbModal, private assessmentService: AssessmentService,
    private fb: FormBuilder, private breadcrumbs: BreadcrumbsService) {
    super();
  }

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb((this.assessmentId ? 'Edit ' : 'Add ') + 'Loading Levels', this.router.url, []);

    if (this.assessmentId) {
      this.assessmentService.getLoadingLevelsAssessment(this.assessmentId).subscribe(result => {
        this.assessment = result;
        this.setupForm();
      },
        (err: HttpErrorResponse) =>
        {
          if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
            this.toastr.error('You do not have access to the assessment', 'Error',);
          } else {
            this.toastr.error('An error occurred while loading the assessment', 'Error');
          }

          this.router.navigate(['../../../'], { relativeTo: this.route });

        });
    } else {
      this.setupForm();
    }
  }

  setupForm(): any {
    this.formGroup = this.fb.group({
      // 1 - Lateral Step Down
      lateralStepDownAblePainfreeLeft: [this.assessment?.lateralStepDownAblePainfreeLeft || false],
      lateralStepDownAblePainfreeRight: [this.assessment?.lateralStepDownAblePainfreeRight || false],
      lateralStepDownAlignmentLeft: [this.assessment?.lateralStepDownAlignmentLeft || false],
      lateralStepDownAlignmentRight: [this.assessment?.lateralStepDownAlignmentRight || false],
      lateralStepDownSymmetry: [this.assessment?.lateralStepDownSymmetry || false],

      qualifierBilateralJumpRopeAblePainfree: [this.assessment?.qualifierBilateralJumpRopeAblePainfree || false],

      // 2 - Stationary Jump Bilateral
      stationaryJumpBilateralAblePainfree: [this.assessment?.stationaryJumpBilateralAblePainfree || false],
      stationaryJumpBilateralAlignment: [this.assessment?.stationaryJumpBilateralAlignment || false],
      stationaryJumpBilateralSymmetry: [this.assessment?.stationaryJumpBilateralSymmetry || false],

      // 3 - Depth Jump Over Barrier
      depthJumpOverBarrierAblePainfree: [this.assessment?.depthJumpOverBarrierAblePainfree || false],
      depthJumpOverBarrierAlignment: [this.assessment?.depthJumpOverBarrierAlignment || false],
      depthJumpOverBarrierSymmetry: [this.assessment?.depthJumpOverBarrierSymmetry || false],

      qualifierUnilateralJumpRopeAblePainfreeLeft: [this.assessment?.qualifierUnilateralJumpRopeAblePainfreeLeft || false],
      qualifierUnilateralJumpRopeAblePainfreeRight: [this.assessment?.qualifierUnilateralJumpRopeAblePainfreeRight || false],

      // 4 - Stationary Jump Unilateral
      stationaryJumpUnilateralAblePainfreeLeft: [this.assessment?.stationaryJumpUnilateralAblePainfreeLeft || false],
      stationaryJumpUnilateralAblePainfreeRight: [this.assessment?.stationaryJumpUnilateralAblePainfreeRight || false],
      stationaryJumpUnilateralAlignmentLeft: [this.assessment?.stationaryJumpUnilateralAlignmentLeft || false],
      stationaryJumpUnilateralAlignmentRight: [this.assessment?.stationaryJumpUnilateralAlignmentRight || false],
      stationaryJumpUnilateralSymmetry: [this.assessment?.stationaryJumpUnilateralSymmetry || false],

      // 5 - Triple Hop Unilateral
      tripleHopUnilateralAblePainfreeLeft: [this.assessment?.tripleHopUnilateralAblePainfreeLeft || false],
      tripleHopUnilateralAblePainfreeRight: [this.assessment?.tripleHopUnilateralAblePainfreeRight || false],
      tripleHopUnilateralAlignmentLeft: [this.assessment?.tripleHopUnilateralAlignmentLeft || false],
      tripleHopUnilateralAlignmentRight: [this.assessment?.tripleHopUnilateralAlignmentRight || false],
      tripleHopUnilateralSymmetry: [this.assessment?.tripleHopUnilateralSymmetry || false],

      notes: [this.assessment?.notes || null],
      lateralStepDownLeftVideoId: [this.assessment?.lateralStepDownLeftVideoId || null],
      lateralStepDownRightVideoId: [this.assessment?.lateralStepDownRightVideoId || null],
      stationaryJumpBilateralVideoId: [this.assessment?.stationaryJumpBilateralVideoId || null],
      depthJumpOverBarrierVideoId: [this.assessment?.depthJumpOverBarrierVideoId || null],
      stationaryJumpUnilateralLeftVideoId: [this.assessment?.stationaryJumpUnilateralLeftVideoId || null],
      stationaryJumpUnilateralRightVideoId: [this.assessment?.stationaryJumpUnilateralRightVideoId || null],
      tripleHopUnilateralLeftVideoId: [this.assessment?.tripleHopUnilateralLeftVideoId || null],
      tripleHopUnilateralRightVideoId: [this.assessment?.tripleHopUnilateralRightVideoId || null]
    });

    this.formGroup.markFormDirtyOnValueChange().subscribe();
    this.initialized = true;
  }

  public onSave(isDraft: boolean) {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: LoadingLevelsAssessmentViewModel = Object.assign({}, this.formGroup.value, this.formGroup.value);
      
      if (this.assessmentId) {
        this.update(formData, isDraft, resetButton);
      } else {
        this.save(formData, isDraft, resetButton);
      }
    });
  }

  showHelp(key: string) {
    const modalRef = this.modalService.open(ExerciseToolTipModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.key = key;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  update(formData: LoadingLevelsAssessmentViewModel, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.updateLoadingLevelsAssessment(this.assessmentId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Updated', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  save(formData: LoadingLevelsAssessmentViewModel, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.createLoadingLevelsAssessment(this.clientUserId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Saved', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  openAssessmentDialog(assessmentId: number) {
    const modalRef = this.modalService.open(LoadingLevelsProgramDialogComponent, { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;

    modalRef.result.then(
      (result) => {},
      (reason) => {
        this.navigateBack();
      }
    );
  }

  cancel() {
    this.navigateBack();
  }

  navigateBack() {
    if (this.assessmentId) {
      this.router.navigate(['../../../list'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../list'], { relativeTo: this.route });
    }
  }
}
