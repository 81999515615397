import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentStatus, ImpairmentCategory, TwoDimensionalAssessmentResult, TwoDimensionalAssessmentViewModel, TwoDimensionalAssessmentViewModelRead } from 'src/app/_models/generatedModels';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { TwoDimensionalProgramDialogComponent } from 'src/app/_components/two-dimensional-program-assignment/two-dimensional-program-dialog.component';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bxl-add-2d-assessment',
  templateUrl: 'add-2d-assessment.component.html',
  styleUrls: ['add-2d-assessment.component.scss'],
})
export class Add2DAssessmentComponent extends FormCanDeactivate implements OnInit {
  initialized = false;
  assessmentId: number;
  assessment: TwoDimensionalAssessmentViewModelRead;
  twoDAssessmentResult = TwoDimensionalAssessmentResult;
  assessmentStatus = AssessmentStatus;
  ImpairmentCategory = ImpairmentCategory;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  clientUserId: number;
  leftOption: any = { label: 'Needs Attention', value: 'NeedsAttention' };
  rightOption: any = { label: 'Satisfactory', value: 'Satisfactory' };

  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToasterService, private modalService: NgbModal, private assessmentService: AssessmentService, private fb: FormBuilder, private breadcrumbs: BreadcrumbsService) {
    super();
  }

  ngOnInit(): void {
    this.clientUserId = getParamFromRoute(this.route, 'id');
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb((this.assessmentId ? 'Edit ' : 'Add ') + '2D assessment', this.router.url, []);

    if (this.assessmentId) {
      this.assessmentService.getTwoDimensionalAssessment(this.assessmentId).subscribe(result => {
        this.assessment = result;
        this.setupForm();
      },
        (err: HttpErrorResponse) =>
        {
          if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
            this.toastr.error('You do not have access to the assessment', 'Error',);
          } else {
            this.toastr.error('An error occurred while loading the assessment', 'Error');
          }

          this.router.navigate(['../../../'], { relativeTo: this.route });

        });
    } else {
      this.setupForm();
    }
  }

  setupForm(): any {
    let videos = this.fb.array([], [Validators.maxLength(4)]);
    if (this.assessment?.videos?.length > 0) {
      this.assessment.videos.forEach(video => {
        videos.push(this.fb.group({
          id: [video.id],
          videoId: [video.videoId],
          description: [video.description],
        }));
      });
    } else {
      videos.push(this.initializeVideo());
    }

    let images = this.fb.array([], [Validators.maxLength(6)]);
    if (this.assessment?.images?.length > 0) {
      this.assessment.images.forEach(image => {
        images.push(this.fb.group({
          id: [image.id],
          imageLocatorName: [image.imageLocatorName],
          description: [image.description],
          notes: [image.notes],
        }));
      });
    } else {
      images.push(this.initializeImage());
    }


    this.formGroup = this.fb.group({
      title: [this.assessment?.title, [Validators.required]],
      assessmentNotes: [this.assessment?.assessmentNotes],
      impairmentCategory: [this.ImpairmentCategory[this.assessment?.impairmentCategory] || null],
      videos: videos,
      images: images,

      generalCadence: [this.twoDAssessmentResult[this.assessment?.generalCadence] || null],
      generalBouncing: [this.twoDAssessmentResult[this.assessment?.generalBouncing] || null],
      generalGroundContactTime: [this.twoDAssessmentResult[this.assessment?.generalGroundContactTime] || null],
      generalOther1: [this.twoDAssessmentResult[this.assessment?.generalOther1] || null],
      generalOther1Text: [this.assessment?.generalOther1Text],
      generalOther2: [this.twoDAssessmentResult[this.assessment?.generalOther2] || null],
      generalOther2Text: [this.assessment?.generalOther2Text],
      generalNotes: [this.assessment?.generalNotes],

      initialFootPosition: [this.twoDAssessmentResult[this.assessment?.initialFootPosition] || null],
      initialKneePosition: [this.twoDAssessmentResult[this.assessment?.initialKneePosition] || null],
      initialHipPosition: [this.twoDAssessmentResult[this.assessment?.initialHipPosition] || null],
      initialMalleolarLine: [this.twoDAssessmentResult[this.assessment?.initialMalleolarLine] || null],
      initialHipSeparationAngle: [this.twoDAssessmentResult[this.assessment?.initialHipSeparationAngle] || null],
      initialOther: [this.twoDAssessmentResult[this.assessment?.initialOther] || null],
      initialOtherText: [this.assessment?.initialOtherText],
      initialNotes: [this.assessment?.initialNotes],

      midFootPosition: [this.twoDAssessmentResult[this.assessment?.midFootPosition] || null],
      midKneePosition: [this.twoDAssessmentResult[this.assessment?.midKneePosition] || null],
      midHipPosition: [this.twoDAssessmentResult[this.assessment?.midHipPosition] || null],
      midKneeWindow: [this.twoDAssessmentResult[this.assessment?.midKneeWindow] || null],
      midPosture: [this.twoDAssessmentResult[this.assessment?.midPosture] || null],
      midOther: [this.twoDAssessmentResult[this.assessment?.midOther] || null],
      midOtherText: [this.assessment?.midOtherText],
      midNotes: [this.assessment?.midNotes],

      swingFootPosition: [this.twoDAssessmentResult[this.assessment?.swingFootPosition] || null],
      swingKneePosition: [this.twoDAssessmentResult[this.assessment?.swingKneePosition] || null],
      swingHipPosition: [this.twoDAssessmentResult[this.assessment?.swingHipPosition] || null],
      swingPosture: [this.twoDAssessmentResult[this.assessment?.swingPosture] || null],
      swingOther: [this.twoDAssessmentResult[this.assessment?.swingOther] || null],
      swingOtherText: [this.assessment?.swingOtherText],
      swingNotes: [this.assessment?.swingNotes],
    });

    this.formGroup.markFormDirtyOnValueChange().subscribe();

    this.initialized = true;
  }

  initializeVideo(): FormGroup {
    return this.fb.group({
      id: [0],
      videoId: [null],
      description: [null],
    });
  }

  onAddVideo() {
    let videos = this.formGroup!.get('videos') as FormArray;
    videos.push(this.initializeVideo());
  }

  onDeleteVideo(index: number) {
    let videos = this.formGroup!.get('videos') as FormArray;
    videos.removeAt(index);
  }

  initializeImage(): FormGroup {
    return this.fb.group({
      id: [0],
      imageLocatorName: [null],
      description: [null],
      notes: [null],
    });
  }

  onAddImage() {
    let images = this.formGroup!.get('images') as FormArray;
    images.push(this.initializeImage());
  }

  onDeleteImage(index: number) {
    let images = this.formGroup!.get('images') as FormArray;
    images.removeAt(index);
  }

  onChangeOtherValue(controlName: string) {
    const textName = controlName + 'Text';

    if (this.formGroup.get(controlName).value) {
      this.formGroup.get(textName).setValidators([Validators.required]);
    }
    else {
      this.formGroup.get(textName).setValidators([]);
      this.formGroup.get(textName).setValue(null);
    }
    this.formGroup.get(textName).updateValueAndValidity();
  }

  onChangeOtherText(textName: string) {
    const controlName = textName.replace('Text', '');

    if (this.formGroup.get(textName).value) {
      this.formGroup.get(controlName).setValidators([Validators.required]);
    }
    else {
      this.formGroup.get(controlName).setValidators([]);
      this.formGroup.get(controlName).setValue(null);
    }
    this.formGroup.get(controlName).updateValueAndValidity();
  }

  onSave(isDraft: boolean) {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: TwoDimensionalAssessmentViewModel = Object.assign({}, this.formGroup.value, this.formGroup.value);
      formData.videos = formData.videos.filter(x => x.videoId != null);
      formData.images = formData.images.filter(x => x.imageLocatorName != null);

      if (this.assessmentId) {
        this.update(formData, isDraft, resetButton);
      } else {
        this.save(formData, isDraft, resetButton);
      }
    });
  }

  update(formData: TwoDimensionalAssessmentViewModel, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.updateTwoDimensionalAssessment(this.assessmentId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Updated', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  save(formData: TwoDimensionalAssessmentViewModel, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.createTwoDimensionalAssessment(this.clientUserId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Saved', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  openAssessmentDialog(assessmentId: number) {
    const modalRef = this.modalService.open(TwoDimensionalProgramDialogComponent, { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;

    modalRef.result.then(
      (result) => {},
      (reason) => {
        this.navigateBack();
      }
    );
  }

  cancel() {
    this.navigateBack();
  }

  navigateBack() {
    if (this.assessmentId) {
      this.router.navigate(['../../../list'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../list'], { relativeTo: this.route });
    }
  }
}
