import { Component, Input, OnDestroy, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { VideoService } from 'src/app/_services/generatedServices';
import { SettingsProvider } from '../../_services/settingsProvider.service';

declare var flowplayer: any;
import { Flowplayer } from '@flowplayer/player';
import Player = Flowplayer.Player;

@Component({
  selector: 'embed-video-player',
  templateUrl: 'video-player.component.html',
  styleUrls: ['video-player.component.scss'],
})

// TODO: Rewrite it completely
export class VideoPlayerComponent implements OnChanges, OnDestroy {
  uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
  initialized = false;
  youtubeUrl: SafeUrl;
  vimeoUrl: SafeUrl;
  livePLayer: amp.Player;
  isWowzaVideo = false;
  wowzaPlayer: Player;
  streamingLocatorName: string;

  @Input()
  url: string;

  @Input()
  privateVideo: boolean;

  @Input()
  videoId: number = null;

  @Input()
  azureUrl: string;

  @Input()
  encoded: boolean;

  @Input()
  size: string = 'small';

  constructor(private sanitizer: DomSanitizer, private videoService: VideoService, private settingsProvider: SettingsProvider) {}

  ngOnChanges(): void
  {
    if (this.privateVideo) {
      this.isWowzaVideo = true;

      if (!this.azureUrl && this.url) {
        this.azureUrl = this.url;
        this.encoded = true;
      }

      this.doSetup();
    } else if (this.videoId) {
      this.videoService.getVideoById(this.videoId).subscribe(result => {
        this.azureUrl = result.assetPath;
        this.encoded = result.encoded;
        this.streamingLocatorName = result.streamingLocatorName; 

        if (this.azureUrl && this.azureUrl.includes('wowza'))
          this.isWowzaVideo = true;

        this.doSetup();

      });
    } else {
      this.doSetup();
    }
  }

  doSetup() {
    if (this.azureUrl) {
      this.initializeAzurePlayer();
    } else if (this.url) {
      this.loadPublic();
    } else {
      this.initialized = true;
    }
  }

  loadPublic() {
    const vimeoURLFormat = 'https://vimeo.com/';
    const youtubeUrlArray = ['https://youtu.be/', 'https://www.youtube.com/watch?v=', 'https://www.youtube.com/shorts/', 'https://youtube.com/shorts/', 'https://youtube.com/watch?v='];

    let index = this.url.indexOf(vimeoURLFormat);
    if (index > -1) {
      const vimeoId = this.url.slice(index + vimeoURLFormat.length);
      this.vimeoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + vimeoId + '?autoplay=0&rel=0');
    }

    for (let i = 0; i < youtubeUrlArray.length; i++) {
      let index = this.url.indexOf(youtubeUrlArray[i]);
      if (index > -1) {
        let youtubeVideoId = this.url.slice(index + youtubeUrlArray[i].length);

        let timestamp = '';
        const youTubeURL = new URL(this.url);
        if (youTubeURL.searchParams.get('t')) {
          timestamp = '&start=' + youTubeURL.searchParams.get('t').replace('s', '');
          youtubeVideoId = youtubeVideoId.replace('&t=' + youTubeURL.searchParams.get('t'), '');
        }

        this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + youtubeVideoId + '?autoplay=0&rel=0' + timestamp);
        break;
      }
    }

    this.initialized = true;
  }



  initializeAzurePlayer() {
    of(true)
      .pipe(
        delay(500),
        tap(() => {
          this.loadAzure();
        })
      )
      .subscribe();
  }

  loadAzure() {
    if (!this.encoded){
      return;
    }

    if (this.isWowzaVideo) {

      this.wowzaPlayer = flowplayer('#wowzaPlayer_' + this.uniqueId,
        {
          src: this.azureUrl,
          autoplay: flowplayer.autoplay.OFF,
          autopause: true,
          controls: true,
          loop: true,
          start_quality: 4,
          ui: flowplayer.ui.USE_DRAG_HANDLE,
          qsel: {},
          token: this.settingsProvider.wowzaPlayerToken
        });

      this.initialized = true;

      return;
    }

    let source = { src: this.azureUrl, type: 'video/mp4' };

    let options = {
      autoplay: false,
      controls: true,
      loop: true,
      hotKeys: {
        enableVolumeScroll: false,
      },
      fluid: true,
      logo: { enabled: false },
      ampAds: {
        mainProgram: {
          source: source,
          // tracks: tracks,
        },
      },
    };

    const id = 'livePlayer' + (this.videoId || '');
    this.livePLayer = amp(id, options);
    this.initialized = true;
  }

  ngOnDestroy(): void {

    if (this.livePLayer) {
      this.livePLayer.dispose();
    }
    if (this.wowzaPlayer) {
      this.wowzaPlayer.destroy();
    }
  }
}
