<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized">
  <div class="header pb-6">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-md-6 col-12">
            <h6 class="h2 d-inline-block mb-0">Assessments</h6>
          </div>

          <div class="col-md-6 col-12 text-right">
            <div class="btn-group">
              <div class="btn-group" ngbDropdown role="group" aria-label="Workout dropdown">
                <button class="btn btn-primary" ngbDropdownToggle>New Assessment</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button [routerLink]="['../rra-assessment/add']" ngbDropdownItem>Runner Readiness</button>
                  <button [routerLink]="['../gc-assessment/add']" ngbDropdownItem>Gait Capacity</button>
                  <button [routerLink]="['../loading-levels/add']" ngbDropdownItem>Loading Levels</button>
                  <button [routerLink]="['../2d-assessment/add']" ngbDropdownItem>2D Assessment</button>
                  <button *ngIf="hasUSAFFitnessAssessment" [routerLink]="['../af-fitness-assessment/add']" ngbDropdownItem>USAF Fitness Assessment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt--6">
    <div class="row">
      <div class="col">
        <assessment-list-view [userId]="clientUserId" [isCoachView]="true"></assessment-list-view>
      </div>
    </div>
  </div>
</div>
