<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Walking reports comparison</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <h5>Reports to compare</h5>

          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 heading-small">
                  <a href="javascript:void(0)" *ngIf="!firstReport && !secondReport" (click)="activeModal.dismiss('cancel')"> Use the <b>"Assessments"</b> grid and the <b>"Add to compare"</b> action to compare walking reports. </a>
                  <a href="javascript:void(0)" *ngIf="firstReport && secondReport" (click)="activeModal.dismiss('cancel')"> Select a walking report from the <b>"Assessments"</b> grid to override already selected if needed. </a>
                  <a href="javascript:void(0)" *ngIf="(firstReport || secondReport) && (!firstReport || !secondReport)" (click)="activeModal.dismiss('cancel')"> Use the <b>"Assessments"</b> grid and the <b>"Add to compare"</b> action to add one more walking report to compare. </a>
                </div>
              </div>
              <div class="row">
                <div *ngIf="!firstReport && !secondReport" class="heading-small col-md-12">No walking reports selected to be compared.</div>
                <div class="col-md-12 mt-3" *ngIf="firstReport || secondReport">
                  <div class="heading-small">Or remove repots from comparison here if needed:</div>
                  <div *ngIf="firstReport" class="container mt-3">
                    <div [ngClass]="{ 'card': useCards }" class="card mb-1">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <ng-container>
                              <a style="vertical-align: text-bottom;" href="javascript:void(0)" (click)="removeReportFromComparison(firstReport.id)">
                                <i class="mr-2 fas fa-trash"> </i>
                              </a>
                              <a [relativeTo]="curentLocation" [routerLink]="[assessmentsUrl, firstReport.id]" href="javascript:void(0)" target="_blank"><h5 class="d-inline">{{firstReport.name}}</h5></a>
                            </ng-container>
                          </div>
                          <div class="col-md-12">
                            {{firstReport.reportDate | date:'short' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="secondReport" class="container mt-3">
                    <div [ngClass]="{ 'card': useCards }" class="card mb-1">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <ng-container>
                              <a style="vertical-align: text-bottom;" href="javascript:void(0)" (click)="removeReportFromComparison(secondReport.id)">
                                <i class="mr-2 fas fa-trash"> </i>
                              </a>
                              <a [relativeTo]="curentLocation" [routerLink]="[assessmentsUrl, secondReport.id]" href="javascript:void(0)" target="_blank"><h5 class="d-inline">{{secondReport.name}}</h5></a>
                            </ng-container>
                          </div>
                          <div class="col-md-12">
                            {{secondReport.reportDate | date:'short' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              </div>
            </div>

      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col text-right">
            <a href="javascript:void(0)" *ngIf="!firstReport || !secondReport" class="btn btn-primary disabled">Compare</a>
            <a href="javascript:void(0)" *ngIf="firstReport && secondReport"  target="_blank"  (click)="activeModal.dismiss('cancel')"  [routerLink]="[comparerUrl, firstReport.id, secondReport.id]" [relativeTo]="curentLocation" class="btn btn-primary">Compare</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

