import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { ExerciseService } from 'src/app/_services/generatedServices';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { WorkoutViewModelRead, WorkoutViewModel, ExerciseListViewModelRead, WorkoutItemType, WorkoutType, CardioIntensityType, CardioType, CardioDistanceUOM, VideoType, WorkoutKeywordViewModel, ActivityType, GarminWorkoutStepDurationType, GarminWorkoutStepDurationValueType, GarminWorkoutStepTargetType, GarminWorkoutStepTargetValueType, WorkoutItemGarminRepeatType, GarminPoolLengthUnit, ExerciseViewModelRead, WorkoutCategory, MeasurementSystem, ExerciseResistanceType, WeightUOM } from 'src/app/_models/generatedModels';
import { forkJoin, timer } from 'rxjs';
import { dynamicSort } from 'src/app/_helpers/extensions.module';
import { Enums } from 'src/app/_models/generatedEnums';
import { map, switchMap } from 'rxjs/operators';
import { IntensityChartDialogComponent } from '../intensity-chart-dialog/intensity-chart-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExerciseAddModalComponent } from '../exercise-add-modal/exercise-add-modal.component';


@Component({
  selector: 'add-edit-workout-component',
  templateUrl: 'add-edit-workout.component.html',
  styleUrls: ['add-edit-workout.component.scss']
})
export class AddEditWorkoutComponentComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  workoutId: number;
  selectedWorkoutType: WorkoutType;
  selectedWorkoutTypeActiveName: string;
  isGarminWorkoutType: boolean = false;
  workout: WorkoutViewModelRead;
  exercises: ExerciseListViewModelRead[];
  workoutItemTypeEnum = WorkoutItemType;
  workoutType = WorkoutType;
  workoutTypeEnum = Enums.WorkoutTypeEnum;
  intensityType = CardioIntensityType;
  intensityTypeEnum = Enums.CardioIntensityTypeEnum;
  cardioType = CardioType;
  smallStep: number = 1;
  distanceUOM = CardioDistanceUOM;
  distanceUOMEnum = Enums.CardioDistanceUOMEnum;
  equipmentList: string[] = [];
  canEditGlobalLibrary: boolean = false;
  keywordsList: string[];
  workoutCategoryEnum =Enums.WorkoutCategoryEnum;
  vdotPaceTypeEnum = Enums.VDOTPaceTypeEnum;
  activityTypeEnum = Enums.ActivityTypeEnum;
  garminPoolLengthUnitEnum = Enums.GarminPoolLengthUnitEnum;
  garminWorkoutStepIntensityEnum = Enums.GarminWorkoutStepIntensityEnum;
  garminWorkoutStepDurationTypeEnum = Enums.GarminWorkoutStepDurationTypeEnum;
  garminWorkoutStepDurationValueTypeEnum = Enums.GarminWorkoutStepDurationValueTypeEnum;
  garminWorkoutStepTargetTypeEnum = Enums.GarminWorkoutStepTargetTypeEnum;
  garminWorkoutStepTargetValueTypeEnum = Enums.GarminWorkoutStepTargetValueTypeEnum;
  garminWorkoutStepStrokeTypeEnum = Enums.GarminWorkoutStepStrokeTypeEnum;
  garminWorkoutStepEquipmentTypeEnum = Enums.GarminWorkoutStepEquipmentTypeEnum;
  activityType = ActivityType;
  garminWorkoutStepDurationType = GarminWorkoutStepDurationType;
  garminWorkoutStepTargetType = GarminWorkoutStepTargetType;
  localMeasurementSystem: MeasurementSystem;
  exerciseResistanceTypeEnum = Enums.ExerciseResistanceTypeEnum;
  exerciseResistanceType = ExerciseResistanceType;
  weightUOMEnum = Enums.WeightUOMEnum;
  weightUOM = WeightUOM;

  totalExpectedTime: number = 0;
  totalExpectedDistanceMiles: number = 0;
  totalExpectedDistanceKM: number = 0;

  @Input()
  workoutIdInput: number;

  @Input()
  workoutTypeInput: any;

  @Input()
  workoutCopyDate: Date = null;

  @Input()
  isWorkoutCopy: boolean = false;

  @Output()
  savedObject = new EventEmitter<WorkoutViewModelRead>();

  @Output()
  isDirtyChange = new EventEmitter<boolean>();

  constructor(private exerciseData: ExerciseService, private fb: FormBuilder, private toastr: ToasterService, private auth: AuthenticationService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.workoutId = this.workoutIdInput;
    const type = this.workoutTypeInput;
    this.selectedWorkoutType = Object.values(this.workoutType).find(x => x == type) as WorkoutType;
    this.canEditGlobalLibrary = this.auth.canEditGlobalLibrary();
    this.localMeasurementSystem = this.auth.getMeasurementSystem();

    if (this.workoutId) {
      forkJoin([this.exerciseData.getWorkoutById(this.workoutId), this.exerciseData.getAllExercisesForOrganization(), this.exerciseData.getWorkoutKeywordsForOrganization()]).subscribe(results => {
        this.workout = results[0];
        this.selectedWorkoutType = this.workout.workoutType;
        this.exercises = results[1];
        this.keywordsList = results[2].map(x => x.name);

        // make sure the workout id is valid for this user
        if (!this.workout.organizationId && !this.canEditGlobalLibrary && !this.isWorkoutCopy) {
          this.savedObject.emit(null);
        }

        this.validateIsGarminWorkoutType();
        this.setupForm();

        if (this.selectedWorkoutType === WorkoutType.Cardio) {
          this.totalExpectedTime = this.workout.expectedTime;
          this.totalExpectedDistanceKM = this.workout.expectedKilometers;
          this.totalExpectedDistanceMiles = this.workout.expectedMiles;
        } else if (this.isGarminWorkoutType) {
          this.totalExpectedDistanceKM = this.workout.expectedKilometers;
          this.totalExpectedDistanceMiles = this.workout.expectedMiles;
        }

        if (this.isWorkoutCopy) {
          this.workoutId = null;
        }

      });
    } else {
      forkJoin([this.exerciseData.getAllExercisesForOrganization(), this.exerciseData.getWorkoutKeywordsForOrganization()]).subscribe(results => {
        this.workout = new WorkoutViewModelRead();
        this.exercises = results[0];
        this.keywordsList = results[1].map(x => x.name);

        this.validateIsGarminWorkoutType();
        this.setupForm();
      });
    }
  }


  setupForm() {
    var name = this.workout.name;
    if (this.isWorkoutCopy && this.workoutCopyDate) {
      const dateRegEx = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
      const lastWord = this.workout.name.substring((this.workout.name.lastIndexOf(' ') + 1));
      const copyDate = this.workoutCopyDate.toISOString().slice(0, 10);
      if (dateRegEx.test(lastWord)) {
        name = this.workout.name.replace(lastWord, copyDate);
      }
      else {
        name = name + ' ' + copyDate;
      }
    }

    this.formGroup = this.fb.group({
      name: [name, { validators: [Validators.required], asyncValidators: [this.validateNameNotTaken.bind(this)], updateOn: 'change' }],
      isHidden: [this.workout.isHidden || false],
      description: [this.workout.description],
      goal: [this.workout.goal],
      workoutType: [this.selectedWorkoutType],
      expectedTime: [this.workout.expectedTime, ((this.selectedWorkoutType == WorkoutType.Strength || this.isGarminWorkoutType) ? [Validators.required, this.workoutExpectedTimeValidator()] : null)],
      expectedMiles: [this.workout.expectedMiles],
      expectedKilometers: [this.workout.expectedKilometers],
      workoutKeywords: [this.workout.workoutKeywords ? this.workout.workoutKeywords.map(x => x.name) : []],
      videoType: [this.workout.videoType != null ? this.workout.videoType : 2],
      videoLink: [this.workout.videoLink],
      videoId: [this.workout.videoId],
      workoutCategory: [this.workout.workoutCategory || (this.selectedWorkoutType == WorkoutType.Strength ? WorkoutCategory.Strength : WorkoutCategory.Cardio), Validators.required],
      expectedRPE: [this.workout.expectedRPE],
      workoutItems: this.fb.array([], this.isGarminWorkoutType ? [Validators.required, Validators.minLength(1)] : [])
    });

    if (this.selectedWorkoutType == WorkoutType.GarminLap_Swimming) {
      this.formGroup.addControl('garminPoolLength', new FormControl(this.workoutId ? this.workout.garminPoolLength : 25, [Validators.required]));
      this.formGroup.addControl('garminPoolLengthUnit', new FormControl(this.workoutId ? this.workout.garminPoolLengthUnit : (this.localMeasurementSystem == MeasurementSystem.Metric ? GarminPoolLengthUnit.METER : GarminPoolLengthUnit.YARD), [Validators.required]));
    }

    if (this.workoutId && this.workout.workoutItems.length > 0) {
      let workoutItems = this.formGroup.get('workoutItems') as FormArray;

      this.workout.workoutItems.sort(dynamicSort('sortOrder')).forEach(item => {
        const exercises = item.workoutItemExercises.sort(dynamicSort('sortOrder'));
        let exerciseArray = this.fb.array([]);
        exercises.forEach(exercise => {
          exerciseArray.push(this.fb.group({
            id: [this.isWorkoutCopy ? 0 : exercise.id],
            exerciseId: [exercise.exerciseId, Validators.required],
            newlyCreatedAndSelectedExerciseId: [null],
            sortOrder: [exercise.sortOrder],
            rep: [exercise.rep],
            set: [exercise.set],
            resistance: [exercise.resistance],
            resistanceType: [exercise.resistanceType],
            weight: [exercise.weight],
            hold: [exercise.hold],
            weightUOM: [exercise.weightUOM],
            notes: [exercise.notes],
            durationForCardio: [exercise.durationForCardio, (this.selectedWorkoutType == WorkoutType.Cardio ? Validators.required : null)]
            }, { validators: [this.durationForCardioValidator] }));
        });

        const cardios = item.workoutItemCardios.sort(dynamicSort('sortOrder'));
        let cardioArray = this.fb.array([]);
        cardios.forEach(cardio => {
          cardioArray.push(this.fb.group({
            id: [this.isWorkoutCopy ? 0 : cardio.id],
            cardioType: [cardio.cardioType, Validators.required],
            distanceUOM: [cardio.distanceUOM, Validators.required],
            durationDistance: [cardio.durationDistance],
            durationTime: [cardio.durationTime, Validators.required],
            sortOrder: [cardio.sortOrder],
            activityType: [cardio.activityType, Validators.required],
            intensityType: [cardio.intensityType],
            intensityValue: [cardio.intensityValue],
            intensityValue2: [cardio.intensityValue2],
            vdotPaceType: [cardio.vdotPaceType],
            notes: [cardio.notes]
            }, { validators: [this.intensityRangeValidator, this.durationTimeValidator] }));
        });

        const garminSteps = item.workoutItemGarminSteps.sort(dynamicSort('sortOrder'));
        let garminStepArray = this.fb.array([]);
        garminSteps.forEach(garminStep => {

          let newStep = this.fb.group({
            id: [this.isWorkoutCopy ? 0 : garminStep.id],
            sortOrder: [garminStep.sortOrder],
            intensity: [garminStep.intensity, Validators.required],
            description: [garminStep.description],
            durationType: [garminStep.durationType, Validators.required],
            durationValue: [garminStep.durationValue],
            durationValueType: [garminStep.durationValueType],
            targetType: [garminStep.targetType],
            targetValue: [garminStep.targetValue],
            targetValueLow: [garminStep.targetValueLow],
            targetValueHigh: [garminStep.targetValueHigh],
            targetValueType: [garminStep.targetValueType],
            strokeType: [garminStep.strokeType],
            equipmentType: [garminStep.equipmentType]
          }, { validators: [this.targetRangeValidator] });

          this.garminDurationTypeValidationsForStep(newStep);
          this.garminTargetTypeValidationsForStep(newStep);
          garminStepArray.push(newStep);
        });

        workoutItems.push(this.fb.group({
          id: [this.isWorkoutCopy ? 0 : item.id],
          workoutItemType: [item.workoutItemType, Validators.required],
          repeat: [item.repeat, (item.workoutItemType === WorkoutItemType.Circuit || item.workoutItemType === WorkoutItemType.Interval) ? Validators.required : null],
          workoutItemGarminRepeatType: [item.workoutItemGarminRepeatType],
          sortOrder: [item.sortOrder],
          workoutItemExercises: exerciseArray,
          workoutItemCardios: cardioArray,
          workoutItemGarminSteps: garminStepArray
        }));
      });
    } else {
      if (this.selectedWorkoutType === WorkoutType.Strength) {
        this.initializeWorkoutItem(WorkoutItemType.Exercise, -1);
      } else if (this.selectedWorkoutType === WorkoutType.Cardio) {
        this.initializeWorkoutItem(WorkoutItemType.Cardio, -1);
      } else if (this.isGarminWorkoutType) {
        this.initializeWorkoutItem(WorkoutItemType.GarminWorkoutStep, -1);
      }

    }

    this.formGroup.markFormDirtyOnValueChange().subscribe(result => {
      this.isDirtyChange.emit(result);
    });

    this.updateEquipmentList();
    this.initialized = true;
  }

  validateIsGarminWorkoutType() {
    if (this.selectedWorkoutType == WorkoutType.GarminCycling || this.selectedWorkoutType == WorkoutType.GarminRunning || this.selectedWorkoutType == WorkoutType.GarminLap_Swimming) {
      this.isGarminWorkoutType = true;
      this.selectedWorkoutTypeActiveName = this.selectedWorkoutType == WorkoutType.GarminCycling ? 'Bike' : this.selectedWorkoutType == WorkoutType.GarminRunning ? 'Run' : 'Swim';
    }
  }

  updateEquipmentList() {
/*     let exerciseIds: number[] = [];
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    workoutItems.controls.forEach(item => {
      const exercises = item.get('workoutItemExercises') as FormArray;
      exerciseIds.push(...exercises.controls.map(x => x.get('exerciseId').value));
    });
    console.log(exerciseIds);
    this.exerciseData.getEquipmentListForExerciseIds(exerciseIds).subscribe(result => {
      this.equipmentList = result;
      console.log(this.equipmentList);
    }); */
  }

  calculateExpectedDuration() {
    if (this.selectedWorkoutType === WorkoutType.Cardio || this.isGarminWorkoutType) {
      let formData: WorkoutViewModel = Object.assign(this.formGroup.getRawValue());
      // remove keywords (and default a workout category) in case required fields aren't populated yet; don't need these items for duration calculations
      formData.workoutKeywords = [];
      formData.workoutCategory = 0;

      // can't send null exerciseId in view model
      formData.workoutItems.forEach(item => {
        item.workoutItemExercises.forEach(exercise => {
          exercise.exerciseId = (exercise.exerciseId || 0);
        });
      });

      this.exerciseData.updateWorkoutExpectedDurations(formData).subscribe(results => {
        this.totalExpectedDistanceKM = results.expectedKilometers;
        this.totalExpectedDistanceMiles = results.expectedMiles;
        if (this.selectedWorkoutType == WorkoutType.Cardio) {
          this.totalExpectedTime = results.expectedTime;
        }
      });

      // need to change id's back to null if we temp set them as 0
      formData.workoutItems.forEach(item => {
        item.workoutItemExercises.forEach(exercise => {
          exercise.exerciseId = (exercise.exerciseId == 0 ? null : exercise.exerciseId);
        });
      });
    }
  }

  initializeExercise() {
    return this.fb.group({
      id: [0],
      exerciseId: [null, Validators.required],
      newlyCreatedAndSelectedExerciseId: [null],
      sortOrder: [0],
      rep: [null],
      set: [null],
      resistance: [null],
      resistanceType: [null],
      weight: [null],
      hold: [null],
      weightUOM: [null],
      notes: [null],
      durationForCardio: [null, (this.selectedWorkoutType == WorkoutType.Cardio ? Validators.required : null)]
      }, { validators: [this.durationForCardioValidator] });
  }

  initializeCardio(cardioType) {
    return this.fb.group({
      id: [0],
      cardioType: [cardioType, Validators.required],
      distanceUOM: [(this.localMeasurementSystem == MeasurementSystem.Metric ? this.distanceUOM.Kilometers : this.distanceUOM.Miles), Validators.required],
      durationDistance: [null],
      durationTime: [null, Validators.required],
      sortOrder: [0],
      activityType: [1, Validators.required],
      intensityType: [null],
      intensityValue: [null],
      intensityValue2: [null],
      vdotPaceType: [null],
      notes: [null]
      }, { validators: [this.intensityRangeValidator, this.durationTimeValidator] });
  }

  initializeGarminStep() {
    let durationValueType = GarminWorkoutStepDurationValueType.MILE;
    if ((this.selectedWorkoutType == WorkoutType.GarminCycling || this.selectedWorkoutType == WorkoutType.GarminRunning) && this.localMeasurementSystem == MeasurementSystem.Metric) {
      durationValueType = GarminWorkoutStepDurationValueType.KILOMETER;  
    } else if (this.selectedWorkoutType == WorkoutType.GarminLap_Swimming) {
      durationValueType = this.localMeasurementSystem == MeasurementSystem.Metric ? GarminWorkoutStepDurationValueType.METER : GarminWorkoutStepDurationValueType.YARD;
    }

    return this.fb.group({
      id: [0],
      sortOrder: [0],
      intensity: [2, Validators.required],
      description: [null],
      durationType: [this.garminWorkoutStepDurationType.DISTANCE, Validators.required],
      durationValue: [null, Validators.required],
      durationValueType: [durationValueType],
      targetType: [null],
      targetValue: [null],
      targetValueLow: [null],
      targetValueHigh: [null],
      targetValueType: [null],
      strokeType: [null],
      equipmentType: [null]
    }, { validators: [this.targetRangeValidator] });
  }

  initializeWorkoutItem(workoutItemType: WorkoutItemType, index: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let workoutItemExercises = this.fb.array([]);
    let workoutItemCardios = this.fb.array([]);
    let workoutItemGarminSteps = this.fb.array([]);

    if (workoutItemType == WorkoutItemType.Cardio || workoutItemType == WorkoutItemType.Interval) {
      workoutItemCardios = this.fb.array(workoutItemType === WorkoutItemType.Interval ? [this.initializeCardio(this.cardioType.Work), this.initializeCardio(this.cardioType.Recovery)] : [this.initializeCardio(this.cardioType.Work)]);
    } else if (workoutItemType == WorkoutItemType.Exercise || workoutItemType == WorkoutItemType.Circuit) {
      workoutItemExercises = this.fb.array([this.initializeExercise()]);
    } else if (workoutItemType == WorkoutItemType.GarminWorkoutStep || workoutItemType == WorkoutItemType.GarminWorkoutRepeatStep) {
      workoutItemGarminSteps = this.fb.array([this.initializeGarminStep()]);
    }

    workoutItems.insert(index + 1, this.fb.group({
      id: [0],
      workoutItemType: [workoutItemType, Validators.required],
      repeat: [null, (workoutItemType === WorkoutItemType.Circuit || workoutItemType === WorkoutItemType.Interval || workoutItemType === WorkoutItemType.GarminWorkoutRepeatStep) ? Validators.required : null],
      workoutItemGarminRepeatType: [workoutItemType == WorkoutItemType.GarminWorkoutRepeatStep ? WorkoutItemGarminRepeatType.REPEAT_UNTIL_STEPS_CMPLT : null],
      sortOrder: [0],
      workoutItemExercises: workoutItemExercises,
      workoutItemCardios: workoutItemCardios,
      workoutItemGarminSteps: workoutItemGarminSteps
    }));

  }

  intensityRangeValidator: ValidatorFn = (fg: FormGroup) => {
    const value1 = fg.get('intensityValue').value;
    const value2 = fg.get('intensityValue2').value;
    if (value1 && value2) {
      if (value1 > value2) {
        return { invalidIntensityRange: true };
      }
    } else if (value2) {
      return { intensityValueRequired: true };
    }
    return null;
  };

  durationTimeValidator: ValidatorFn = (fg: FormGroup) => {
    const durationTime = fg.get('durationTime').value;
    if (durationTime == 0) {
      return { durationTimeIsZero: true };
    }
    return null;
  };

  durationForCardioValidator: ValidatorFn = (fg: FormGroup) => {
    if (this.selectedWorkoutType == WorkoutType.Cardio) {
      const durationForCardio = fg.get('durationForCardio').value;
      if (durationForCardio == 0) {
        return { durationForCardioIsZero: true };
      }
    }

    return null;
  };

  targetRangeValidator: ValidatorFn = (fg: FormGroup) => {
    const value1 = fg.get('targetValueLow').value;
    const value2 = fg.get('targetValueHigh').value;
    if (value1 && value2) {
      if (value1 > value2) {
        return { invalidTargetRange: true };
      }
    }
    return null;
  };

  workoutExpectedTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value == null) {
            return null;
        }

        if (control.value == 0) {
          return { expectedTimeIsZero: true }
        }

        return null;
    };
  }

  onAddExerciseToCircuit(index: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let exercises = workoutItems.at(index).get('workoutItemExercises') as FormArray;
    exercises.push(this.initializeExercise());
  }

  onAddStepToInterval(index: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let cardios = workoutItems.at(index).get('workoutItemCardios') as FormArray;
    cardios.push(this.initializeCardio(CardioType.Work));
  }

  onAddStepToRepeatStep(index: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let garminSteps = workoutItems.at(index).get('workoutItemGarminSteps') as FormArray;
    garminSteps.push(this.initializeGarminStep());
  }

  onAddExerciseToWorkout(index: number) {
    this.initializeWorkoutItem(WorkoutItemType.Exercise, index);
  }

  onAddStepToWorkout(index: number) {
    this.initializeWorkoutItem(WorkoutItemType.Cardio, index);
  }

  onAddGarminStepToWorkout(index: number) {
    this.initializeWorkoutItem(WorkoutItemType.GarminWorkoutStep, index);
  }

  onAddCircuitToWorkout(index: number) {
    this.initializeWorkoutItem(WorkoutItemType.Circuit, index);
  }

  onAddIntervalToWorkout(index: number) {
    this.initializeWorkoutItem(WorkoutItemType.Interval, index);
  }

  onAddGarminRepeatStepToWorkout(index: number) {
    this.initializeWorkoutItem(WorkoutItemType.GarminWorkoutRepeatStep, index);
  }

  onDeleteWorkoutItem(index: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    workoutItems.removeAt(index);
    this.calculateExpectedDuration();
  }

  onDeleteExercise(itemIndex: number, exerciseIndex: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let exercises = workoutItems.at(itemIndex).get('workoutItemExercises') as FormArray;
    exercises.removeAt(exerciseIndex);
    this.calculateExpectedDuration();
  }

  onDeleteStep(itemIndex: number, stepIndex: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let cardios = workoutItems.at(itemIndex).get('workoutItemCardios') as FormArray;
    cardios.removeAt(stepIndex);
    this.calculateExpectedDuration();
  }

  onDeleteGarminStep(itemIndex: number, stepIndex: number) {
    let workoutItems = this.formGroup.get('workoutItems') as FormArray;
    let garminSteps = workoutItems.at(itemIndex).get('workoutItemGarminSteps') as FormArray;
    garminSteps.removeAt(stepIndex);
    this.calculateExpectedDuration();
  }

  onRepeatChange() {
    this.calculateExpectedDuration();
  }

  onDurationTimeChange(value: any, itemIndex: number, stepIndex: number, isCardioItem: boolean) {
    if (isCardioItem) {
      const workoutItems = this.formGroup.get('workoutItems') as FormArray;
      const cardios = workoutItems.at(itemIndex).get('workoutItemCardios') as FormArray;
      const cardio = cardios.at(stepIndex);

      this.calculateDurationBasedOnPace(cardio, false);
    }

    this.calculateExpectedDuration();
  }

  onDistanceChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const cardios = workoutItems.at(itemIndex).get('workoutItemCardios') as FormArray;
    let cardio = cardios.at(stepIndex);
    this.calculateDurationBasedOnPace(cardio, true);
    this.calculateExpectedDuration();
  }

  onDistanceUOMChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const cardios = workoutItems.at(itemIndex).get('workoutItemCardios') as FormArray;
    let cardio = cardios.at(stepIndex);
    this.calculateExpectedDuration();
  }

  onIntensityTypeChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const cardios = workoutItems.at(itemIndex).get('workoutItemCardios') as FormArray;
    let cardio = cardios.at(stepIndex);
    cardio.get('intensityValue').setValue(null);
    cardio.get('intensityValue2').setValue(null);
  }

  onIntensityValueChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const cardios = workoutItems.at(itemIndex).get('workoutItemCardios') as FormArray;
    let cardio = cardios.at(stepIndex);

    this.calculateDurationBasedOnPace(cardio, true);
    this.calculateExpectedDuration();
  }

  onGarminDurationTypeChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const steps = workoutItems.at(itemIndex).get('workoutItemGarminSteps') as FormArray;
    let step = steps.at(stepIndex);

    step.get('durationValue').setValue(null);
    if (step.get('durationType').value == GarminWorkoutStepDurationType.DISTANCE) {
      if (this.selectedWorkoutType == WorkoutType.GarminCycling || this.selectedWorkoutType == WorkoutType.GarminRunning) {
        step.get('durationValueType').setValue(GarminWorkoutStepDurationValueType.MILE);
      } else {
        step.get('durationValueType').setValue(GarminWorkoutStepDurationValueType.YARD);
      }
    } else {
      step.get('durationValueType').setValue(null);
    }

    this.garminDurationTypeValidationsForStep(step);
    this.calculateExpectedDuration();
  }

  onResistanceTypeChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const exercises = workoutItems.at(itemIndex).get('workoutItemExercises') as FormArray;
    let exercise = exercises.at(stepIndex);
    exercise.get('weight').setValue(null);
    exercise.get('hold').setValue(null);
    exercise.get('resistance').setValue(null);
    exercise.get('weightUOM').setValue(null);
    if (exercise.get('resistanceType').value == this.exerciseResistanceType.Weight) {
      exercise.get('weightUOM').setValue(this.localMeasurementSystem == MeasurementSystem.Metric ? this.weightUOM.Kilograms : this.weightUOM.Pounds);
    } else {
      exercise.get('weightUOM').setValue(null);
    }
  }

  garminDurationTypeValidationsForStep(step: AbstractControl) {
    if (step.get('durationType').value == GarminWorkoutStepDurationType.OPEN) {
      step.get('durationValue').setValidators(null);
    } else {
      step.get('durationValue').setValidators(Validators.required);
    }
    step.get('durationValue').updateValueAndValidity();
    
    if (step.get('durationType').value == GarminWorkoutStepDurationType.DISTANCE) {
      step.get('durationValueType').setValidators(Validators.required);
    } else {
      step.get('durationValueType').setValidators(null);
    }
    step.get('durationValueType').updateValueAndValidity();
  }

  onGarminDurationValueDistanceChange(value: any, itemIndex: number, stepIndex: number) {
    this.calculateExpectedDuration();
  }

  onGarminDurationValueTypeChange(value: any, itemIndex: number, stepIndex: number) {
    this.calculateExpectedDuration();
  }

  onGarminTargetTypeChange(value: any, itemIndex: number, stepIndex: number) {
    const workoutItems = this.formGroup.get('workoutItems') as FormArray;
    const steps = workoutItems.at(itemIndex).get('workoutItemGarminSteps') as FormArray;
    let step = steps.at(stepIndex);

    step.get('targetValue').setValue(null);
    step.get('targetValueLow').setValue(null);
    step.get('targetValueHigh').setValue(null);
    step.get('targetValueType').setValue(null);
    
    if (value == GarminWorkoutStepTargetType.PACE || value == GarminWorkoutStepTargetType.SPEED) {
      let targetValueType: GarminWorkoutStepTargetValueType;
      if (this.selectedWorkoutType == WorkoutType.GarminCycling) {
        targetValueType = this.localMeasurementSystem == MeasurementSystem.Metric ? GarminWorkoutStepTargetValueType.KILOMETERS_PER_HOUR : GarminWorkoutStepTargetValueType.MILES_PER_HOUR;
      } else if (this.selectedWorkoutType == WorkoutType.GarminRunning) {
        targetValueType = this.localMeasurementSystem == MeasurementSystem.Metric ? GarminWorkoutStepTargetValueType.MINS_PER_KILOMETER : GarminWorkoutStepTargetValueType.MINS_PER_MILE;
      } else if (this.selectedWorkoutType == WorkoutType.GarminLap_Swimming) {
        targetValueType = this.localMeasurementSystem == MeasurementSystem.Metric ? GarminWorkoutStepTargetValueType.MIN_PER_100_METERS : GarminWorkoutStepTargetValueType.MIN_PER_100_YARDS;
      }
      step.get('targetValueType').setValue(targetValueType);
    }

    this.garminTargetTypeValidationsForStep(step);
  }

  garminTargetTypeValidationsForStep(step: AbstractControl) {
    let targetTypeValue = step.get('targetType').value;

    if (targetTypeValue == GarminWorkoutStepTargetType.PACE || targetTypeValue == GarminWorkoutStepTargetType.SPEED) {
      step.get('targetValueType').setValidators(Validators.required);
    } else {
      step.get('targetValueType').setValidators(null);
    }
    step.get('targetValueType').updateValueAndValidity();
    

    step.get('targetValue').setValidators(null);
    step.get('targetValueLow').setValidators(null);
    step.get('targetValueHigh').setValidators(null);

    if (targetTypeValue == GarminWorkoutStepTargetType.HEART_RATE_ZONE || targetTypeValue == GarminWorkoutStepTargetType.POWER_ZONE) {
      step.get('targetValue').setValidators(Validators.required);
    } else if (targetTypeValue != null) {
      step.get('targetValueLow').setValidators(Validators.required);
      step.get('targetValueHigh').setValidators(Validators.required);
    }

    step.get('targetValue').updateValueAndValidity();
    step.get('targetValueLow').updateValueAndValidity();
    step.get('targetValueHigh').updateValueAndValidity();
  }

  calculateDurationBasedOnPace(cardio: AbstractControl, useDistance: boolean) {
    // automatically set distance or time based on Pace value
    if (cardio.get('intensityType').value == this.intensityType.Pace) {
      const intensityValue1 = cardio.get('intensityValue').value;
      const intensityValue2 = cardio.get('intensityValue2').value;
      const intensityValue = (intensityValue1 && intensityValue2) ? (intensityValue1 + intensityValue2) / 2  : (intensityValue1 || intensityValue2);

      if (intensityValue) {
        const durationDistance = cardio.get('durationDistance').value;
        const durationTime = cardio.get('durationTime').value;

        if (durationDistance && useDistance) {
          const totalSeconds = Math.round(intensityValue * durationDistance);
          cardio.get('durationTime').setValue(totalSeconds);

        } else if (durationTime) {
          cardio.get('durationDistance').setValue(parseFloat(((durationTime) / intensityValue).toFixed(2)));
        }
      }
    }
  }

  showZoneChart(garminWorkoutStepTargetType: GarminWorkoutStepTargetType, cardioIntensityType: CardioIntensityType) {
    const modalRef = this.modalService.open(IntensityChartDialogComponent, { size: 'lg', windowClass: 'modal-md-custom' });
    modalRef.componentInstance.selectedCardioIntensityType = cardioIntensityType;
    modalRef.componentInstance.selectedGarminWorkoutStepTargetType = garminWorkoutStepTargetType;
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.toastr.confirmDialog('Are you sure you want to discard changes?', 'Discard Changes').subscribe(result => {
        if (result) {
          this.savedObject.emit(null);
        }
      });
    } else {
      this.savedObject.emit(null);
    }
  }

  onSave() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.isDirtyChange.emit(this.formGroup.dirty);
    this.submitComplete = new Promise((resetButton:any, reject) => {
      let formData: WorkoutViewModel = this.formGroup.value;
      formData.workoutKeywords = (this.formGroup.get('workoutKeywords').value as string[]).map(x => {return { name: x } as WorkoutKeywordViewModel});
      formData = this.updateSortOrder(formData);
      formData = this.updateVideoValues(formData);

      if (this.workoutId) {
        this.update(formData, resetButton);
      } else {
        // save the user who is creating the workout
        formData.userId = this.auth.user.id;
        this.add(formData, resetButton);
      }
    });
  }

  add(formData: any, resetButton: () => any) {
    this.exerciseData.addWorkout(formData).subscribe((result) => {
      this.toastr.success('Workout Added', 'Success');
      resetButton();
      this.savedObject.emit(result);
    });
  }

  update(formData: any, resetButton: () => any) {
    this.exerciseData.updateWorkout(this.workoutId, formData).subscribe((result) => {
      this.toastr.success('Workout Updated', 'Success');
      resetButton();
      this.savedObject.emit(result);
    });
  }

  updateSortOrder(workout: WorkoutViewModel): WorkoutViewModel {
    let itemSort = 1;
    workout.workoutItems.forEach(item => {
      item.sortOrder = itemSort;
      itemSort += 1;

      let cardioSort = 1;
      item.workoutItemCardios.forEach(cardio => {
        cardio.sortOrder = cardioSort;
        cardioSort += 1;
      });

      let exerciseSort = 1;
      item.workoutItemExercises.forEach(exercise => {
        exercise.sortOrder = exerciseSort;
        exerciseSort += 1;
      });

      let garminStepSort = 1;
      item.workoutItemGarminSteps.forEach(step => {
        step.sortOrder = garminStepSort;
        garminStepSort += 1;
      });
    });

    return workout;
  }

  updateVideoValues(workout: WorkoutViewModel): WorkoutViewModel {
    switch(workout.videoType) {
      case VideoType.None: {
        workout.videoLink = null;
        workout.videoId = null;
         break;
      }
      case VideoType.Public: {
        workout.videoId = null;
         break;
      }
      case VideoType.Upload: {
        workout.videoLink = null;
         break;
      }
   }
   if ((workout.videoType == VideoType.Upload && !workout.videoId) || workout.videoType == VideoType.Public && !workout.videoLink) {
     workout.videoType = VideoType.None;
   }

    return workout;
  }

  validateNameNotTaken(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => this.exerciseData.isWorkoutNameDuplicate(control.value, this.workoutId || 0)),
      map((res)  => {
        return res ? { nameTaken: true } : null;
      })
    );
  }

  onCreateExercise(itemIndex: number, exerciseIndex: number) {
    const modalRef = this.modalService.open(ExerciseAddModalComponent, { size: 'lg', windowClass: 'modal-xl-custom', backdrop: 'static' });
    modalRef.componentInstance.exerciseIdToModify = null;
    modalRef.componentInstance.savedObject.subscribe((exercise: ExerciseViewModelRead) => {
      let workoutItems = this.formGroup.get('workoutItems') as FormArray;
      let exercises = workoutItems.at(itemIndex).get('workoutItemExercises') as FormArray;
      exercises.at(exerciseIndex).get('exerciseId').setValue(exercise.id);
      exercises.at(exerciseIndex).get('newlyCreatedAndSelectedExerciseId').setValue(exercise.id);
    });
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }
}
