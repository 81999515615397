<div *ngIf="initialized">
  <div class="card mb-0">
    <div>
      <header class="card-header bg-light py-3 px-5">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Assign Programs</h3>

          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancel')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </header>
      <div class="card-body">
        <div class="mb-3">
          <h5>Assessment Score: {{model.handicapScore}}</h5>
        </div>
        <h5>Start Dates for {{model.clientFullName}}</h5>
        <form id="form" (ngSubmit)="onSave()" [formGroup]="formGroup" novalidate>

          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div  class="col-md-12">
                  <div class="form-group">
                    <div>Current programs assigned to client. Remove any programs here if needed:</div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-7 mt--3">
                  <runner-program-details [clientUserId]="model.clientUserId" [summaryOnly]="true"></runner-program-details>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div  class="col-md-12">
                  <div class="form-group">
                    <div>Gait Capacity Program: {{formGroup.get('gaitCapabilityCategory').value | enumString: gaitCapabilityCategoryEnum}}</div>
                  </div>
                </div>
                <div  class="col-md-12 col-lg-7">
                  <div class="form-group">
                    <label class="form-control-label" for="gaitCapabilityProgramStartDate">
                      Assigned Program
                    </label>
                    <div>{{formGroup.get('gaitCapabilityCategoryProgramName').value}}</div>
                  </div>
                </div>
                <div  class="col-md-12 col-lg-5">
                  <div class="form-group">
                    <label class="form-control-label" for="gaitCapabilityProgramStartDate">
                      Gait Capacity Program Start Date
                    </label>
                    <custom-recent-datepicker placeholder="" class="form-control" formControlName="gaitCapabilityCategoryProgramStartDate"></custom-recent-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div  class="col-md-12">
                  <div class="form-group">
                    <div>Additional Program (optional)</div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-7">
                  <div class="form-group">
                    <label class="form-control-label" for="optionalProgramId">
                      Program
                    </label>
                    <programs-dropdown class="form-control" (changed)="onOptionalProgramChange()" formControlName="optionalProgramId"></programs-dropdown>
                    <div class="invalid-message">
                      <div>Program is required if start date is selected</div>
                    </div>
                  </div>
                </div>
                <div  class="col-md-12 col-lg-5">
                  <div class="form-group">
                    <label class="form-control-label" for="optionalProgramStartDate">
                      Program Start Date
                    </label>
                    <custom-recent-datepicker placeholder="" class="form-control" formControlName="optionalProgramStartDate" (valueChange)="onOptionalProgramDateChange()"></custom-recent-datepicker>
                    <div class="invalid-message">
                      <div>Program start date is required if program is selected</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="formGroup.dirty && formGroup.errors && formGroup.errors.oneStartDateRequired" class="custom-invalid-message">
            You must select a start date for at least one program
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">Assign Later</button>
          </div>
          <div class="col text-right">
            <button form="form" type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

