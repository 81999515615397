import { Observable, forkJoin } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService, ProgramsService } from 'src/app/_services/generatedServices';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { ActivatedRoute} from '@angular/router';
import { GaitReportViewModel } from '../../_models/generatedModels';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'gait-reports-comparison-dialog',
  templateUrl: 'gait-reports-comparison-dialog.component.html',
})
export class GaitReportComparisonDialogComponent implements OnInit {
  initialized = false;
  clientUserId: number;

  firstReport: GaitReportViewModel;
  secondReport: GaitReportViewModel;

  comparerUrl: string = './../gait-comparison/view';
  assessmentsUrl: string = './../analysis-walking/view';

  @Input()
  gaitReportIdsToCompare: number[];

  @Input()
  curentLocation: ActivatedRoute;

  @Input()
  userId: number;

  @Output()
  reportRemovedFromComparison = new EventEmitter<number>();

  constructor(private toastr: ToasterService, private assessmentService: AssessmentService, public activeModal: NgbActiveModal, private auth: AuthenticationService) { }

  ngOnInit(): void
  {
    let reportTasks = new Array<Observable<GaitReportViewModel>>();

    if (this.gaitReportIdsToCompare[0]) {
      let assessment1 = this.assessmentService.getSpecificGaitReport(this.gaitReportIdsToCompare[0]);

      if (assessment1) {
        reportTasks.push(assessment1);
      }
    }

    if (this.gaitReportIdsToCompare[1]) {
      let assessment2 = this.assessmentService.getSpecificGaitReport(this.gaitReportIdsToCompare[1]);

      if (assessment2) {
        reportTasks.push(assessment2);
      }
    }

    forkJoin(reportTasks)
      .subscribe((results) =>
      {
        if (results[0]) {
          this.firstReport = results[0];
        }

        if (results[1]) {
          this.secondReport = results[1];
        }

        this.initialized = true;
      },
      (err: HttpErrorResponse) => {
          if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
            this.toastr.error('You do not have access to all or some compared assessments', 'Error');
          } else {
            this.toastr.error('An error occurred while loading the assessments', 'Error');
        }

        this.activeModal.dismiss('cancel');
      });

    if (reportTasks.length == 0) {
      this.initialized = true;
    }
  }

  removeReportFromComparison(id: number)
  {
    if (this.firstReport && this.firstReport.id == id) {
      this.firstReport = null;
    }

    if (this.secondReport && this.secondReport.id == id) {
      this.secondReport = null;
    }


    this.reportRemovedFromComparison.emit(id);
  }
}
