import { Component, OnInit, Input} from '@angular/core';
import { TwoDimensionalAssessmentResult, TwoDimensionalAssessmentViewModelRead } from 'src/app/_models/generatedModels';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { Enums } from 'src/app/_models/generatedEnums';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../../_services/toaster.service';

@Component({
  selector: 'two-dimensional-result-details',
  templateUrl: 'two-dimensional-result-details.component.html',
  styleUrls: ['two-dimensional-result-details.component.scss']
})
export class TwoDimensionalAssessmentResultDetailsComponent implements OnInit {
  initialized = false;
  assessmentId: any;
  assessment: TwoDimensionalAssessmentViewModelRead;
  TwoDimensionalAssessmentResult = TwoDimensionalAssessmentResult;
  ImpairmentCategoryEnum = Enums.ImpairmentCategoryEnum;

  @Input()
  isCoachView: boolean = false;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService, private router: Router, private toastr: ToasterService) {}

  ngOnInit(): void {
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    this.assessmentData.getTwoDimensionalAssessment(this.assessmentId).subscribe((result) => {
      this.assessment = result;
      this.initialized = true;
    },
      (err: HttpErrorResponse) =>
      {
        if (err && err.error && err.error.indexOf('UnauthorizedAccessException') >= 0) {
          this.toastr.error('You do not have access to the assessment', 'Error',);
        } else {
          this.toastr.error('An error occurred while loading the assessment', 'Error');
        }

        this.router.navigate(['../../../'], { relativeTo: this.route });

      });
  }
}
