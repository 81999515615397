import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gait-report-diagram-popup',
  templateUrl: 'gait-report-diagram-popup.component.html',
})
export class GaitReportDiagramPopupComponent implements OnInit {
  initialized = false;

  @Input()
  url: string;

  @Input()
  title: string;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initialized = true;
  }
}
