import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/_services/toaster.service';
import { ProgramsService } from 'src/app/_services/generatedServices';
import { ImpairmentCategory, ImpairmentCategoryProgramViewModelRead, ImpairmentCategoryUpdateViewModel, ProgramAssignmentViewModel, WalkingCategory, WalkingCategoryProgramViewModelRead, WalkingCategoryUpdateViewModel } from 'src/app/_models/generatedModels';
import { Enums } from 'src/app/_models/generatedEnums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { dateAsUTCNoTime } from 'src/app/_helpers/extensions.module';

@Component({
  selector: 'running-category-change-dialog',
  templateUrl: 'running-category-change-dialog.component.html',
})
export class RunningCategoryChangeDialogComponent implements OnInit {
  initialized = false;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  programs: ImpairmentCategoryProgramViewModelRead[];
  walkingPrograms: WalkingCategoryProgramViewModelRead[];
  impairmentCategoryEnum = Enums.ImpairmentCategoryEnum;
  impairmentCategory = ImpairmentCategory;
  walkingCategoryEnum = Enums.WalkingCategoryEnum;
  walkingCategory = WalkingCategory;
  selectedProgramName: string;
  selectedWalkingProgramName: string;

  @Input()
  clientUserId: number;

  @Input()
  clientFullName: string;

  @Output()
  saved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToasterService, private programsService: ProgramsService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void
  {
    forkJoin([this.programsService.getImpairmentCategoryPrograms(), this.programsService.getWalkingCategoryPrograms()])
      .subscribe((results) =>
      {
        this.programs = results[0];
        this.walkingPrograms = results[1];
        this.setupForm();
      });
  }

  setupForm() {
    this.formGroup = this.fb.group({
      clientUserId: [this.clientUserId],
      impairmentCategory: ['N/A'],
      impairmentCategoryProgramId: [null],
      impairmentCategoryProgramStartDate: [null],
      rtrNotes: [null],
      walkingCategory: ['N/A'],
      walkingCategoryProgramId: [null],
      walkingCategoryProgramStartDate: [null],
      walkingRtrNotes: [null],
      isImpairmentCategoryProgramSet: [null],
      isWalkingCategoryProgramSet: [null],

      programId: [null],
      startDate: [null]
    });

    this.initialized = true;
  }

  onCategoryChange(selectedValue: string)
  {
    if (selectedValue == 'N/A'){
      this.formGroup.get('isImpairmentCategoryProgramSet').setValue(false);
      this.formGroup.get('impairmentCategory').clearValidators();
      this.formGroup.get('impairmentCategoryProgramId').clearValidators();
      this.formGroup.get('impairmentCategoryProgramStartDate').clearValidators();
      this.formGroup.get('impairmentCategoryProgramId').setValue(null);
      this.formGroup.get('impairmentCategoryProgramStartDate').setValue(null);
      this.selectedProgramName = null;

      this.formGroup.get('impairmentCategoryProgramId').updateValueAndValidity();
      this.formGroup.get('impairmentCategoryProgramStartDate').updateValueAndValidity();

      return;
    }

    const selectedProgram = this.programs.find(x => x.impairmentCategory == this.impairmentCategory[selectedValue]).program;
    this.selectedProgramName = selectedProgram.name;
    this.formGroup.get('impairmentCategoryProgramId').setValue(selectedProgram.id);

    if (selectedValue) {
      this.formGroup.get('isImpairmentCategoryProgramSet').setValue(true);
      this.formGroup.get('impairmentCategory').setValidators([Validators.required]);
      this.formGroup.get('impairmentCategoryProgramId').setValidators([Validators.required]);
      this.formGroup.get('impairmentCategoryProgramStartDate').setValidators([Validators.required]);
    } else {
      this.formGroup.get('isImpairmentCategoryProgramSet').setValue(false);
    }

    this.formGroup.get('impairmentCategoryProgramId').updateValueAndValidity();
    this.formGroup.get('impairmentCategoryProgramStartDate').updateValueAndValidity();
  }

  onWalkingCategoryChange(selectedValue: string)
  {
    if (selectedValue == 'N/A') {
      this.formGroup.get('isWalkingCategoryProgramSet').setValue(false);
      this.formGroup.get('walkingCategory').clearValidators();
      this.formGroup.get('walkingCategoryProgramId').clearValidators();
      this.formGroup.get('walkingCategoryProgramStartDate').clearValidators();
      this.formGroup.get('walkingCategoryProgramId').setValue(null);
      this.formGroup.get('walkingCategoryProgramStartDate').setValue(null);
      this.selectedWalkingProgramName = null;

      this.formGroup.get('walkingCategoryProgramId').updateValueAndValidity();
      this.formGroup.get('walkingCategoryProgramStartDate').updateValueAndValidity();

      return;
    }

    const selectedProgram = this.walkingPrograms.find(x => x.walkingCategory == this.walkingCategory[selectedValue]).program;
    this.selectedWalkingProgramName = selectedProgram.name;
    this.formGroup.get('walkingCategoryProgramId').setValue(selectedProgram.id);

    if (selectedValue) {
      this.formGroup.get('isWalkingCategoryProgramSet').setValue(true);
      this.formGroup.get('walkingCategory').setValidators([Validators.required]);
      this.formGroup.get('walkingCategoryProgramId').setValidators([Validators.required]);
      this.formGroup.get('walkingCategoryProgramStartDate').setValidators([Validators.required]);
    } else {
      this.formGroup.get('isWalkingCategoryProgramSet').setValue(false);
    }

    this.formGroup.get('walkingCategoryProgramId').updateValueAndValidity();
    this.formGroup.get('walkingCategoryProgramStartDate').updateValueAndValidity();
  }

  onOptionalProgramChange()
  {
    if (this.formGroup.get('programId').value) {
      this.formGroup.get('startDate').setValidators([Validators.required]);
    } else {
      this.formGroup.get('startDate').setValidators([]);
    }

    this.formGroup.get('startDate').updateValueAndValidity();
  }

  onOptionalProgramDateChange()
  {
    if (this.formGroup.get('startDate').value) {
      this.formGroup.get('programId').setValidators([Validators.required]);
    } else {
      this.formGroup.get('programId').setValidators([]);
    }

    this.formGroup.get('programId').updateValueAndValidity();
  }
  
  onSave()
  {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      return;
    }

    this.submitComplete = new Promise((resetButton: any, reject) =>
    {
      const formData: ImpairmentCategoryUpdateViewModel = Object.assign({}, this.formGroup.getRawValue());
      const gcFormData: WalkingCategoryUpdateViewModel = Object.assign({}, this.formGroup.getRawValue());
      const optionalProgramFormData: ProgramAssignmentViewModel = Object.assign({}, this.formGroup.getRawValue());

      let assignentResult: Observable<any>[] = [];

      if (formData.impairmentCategoryProgramId) {
        formData.impairmentCategoryProgramStartDate = dateAsUTCNoTime(formData.impairmentCategoryProgramStartDate);
        assignentResult.push(this.programsService.assignCurrentImpairmentCategoryProgramToClient(formData));
      }

      if (gcFormData.walkingCategoryProgramStartDate) {
        gcFormData.walkingCategoryProgramStartDate = dateAsUTCNoTime(gcFormData.walkingCategoryProgramStartDate);
        assignentResult.push(this.programsService.assignCurrentWalkingCategoryProgramToClient(gcFormData));
      }

      if (optionalProgramFormData.startDate) {
        optionalProgramFormData.startDate = dateAsUTCNoTime(optionalProgramFormData.startDate);
        assignentResult.push(this.programsService.assignProgramToClient(optionalProgramFormData));
      }

      forkJoin(assignentResult)
        .subscribe((results) =>
        {
          this.toastr.success('Program Assigned', 'Success');
          this.saved.emit(true);
          this.activeModal.dismiss('saved');
        });

      if (assignentResult.length == 0) {
        this.activeModal.dismiss('saved');
      }
    });
  }
}
