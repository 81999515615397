import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gait-report-video-popup',
  templateUrl: 'gait-report-video-popup.component.html',
})
export class GaitReportVideoPopupComponent implements OnInit {
  initialized = false;

  @Input()
  videoLink: string;

  @Input()
  analysisName: string;

  @Input()
  encoded: boolean;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initialized = true;
  }
}
