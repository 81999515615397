<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="cs_wrapper">
  <div *ngIf="!isShared" class="row">
    <div class="col-lg-12 mt-1 mb-2">
      <div class="float-right d-none d-lg-block">
        <a href="javascript:void(0)" (click)="onCopyLink()" class="btn btn-sm btn-primary ml-2"> Shareable Link </a>
      </div>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <td>
          <div class="cs_header">
            <div class="cs_list">
              <div class="cs_list-item">
                <span class="cs_hl">Name:</span> {{assessment.user.firstName}} {{assessment.user.lastName}}
              </div>
              <div class="cs_list-item">
                <span class="cs_hl">Date:</span> {{assessment.dateTime | date:'short' }}
              </div>
              <div class="cs_list-item">
                <span class="cs_hl">Notes:</span> {{assessment.notes}}

              </div>
            </div>
            <a href="https://rundna.com/" target="_blank"><img class="cs_logo" src="../../../../assets/img/gaitreport/logo.png" alt="logo" /></a>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <!-- page 2 -->
          <div class="card card-body">
            <!--<div class="cs_space"></div>-->
            <div class="cs_sec-header dark cs_mt1">
              <div class="cs_sec-left">
                <div>
                  <h2>Gait</h2>
                  <h2>Capacity</h2>
                  <h2>Screen</h2>
                </div>
              </div>
              <div class="cs_sec-right">
                The Gait Capacity Screen (GCS) is a test of your flexibility,
                strength, and stability. These tests are designed to identify
                areas closely related to walking/balance that should be
                addressed, but do not indicate your risk of injury from walking.
              </div>
            </div>

            <div class="cs_card-grid cs_mt2">
              <div class="cs_card overal cs_black">

                <div class="cs_overal-score">
                  <div class="cs_overal-score-item color-1"></div>
                  <div class="cs_overal-score-item color-2"></div>
                  <div class="cs_overal-score-item color-3"></div>
                  <div class="cs_overal-score-item color-4"></div>
                  <div class="cs_overal-score-item color-5"></div>
                </div>
                <h3 class="cs_card-title overal-title">
                  <div>Overall<br>Score</div>
                  <div class="cs_score handicap_score sm color-{{assessment.gaitCapabilityAssessmentBase.handicapScoreLevel}}">{{assessment.gaitCapabilityAssessmentBase.handicapScore}}</div>
                </h3>
              </div>
              <div class="cs_card">
                <img src="../../../../assets/img/gaitreport/img_0004.svg" alt="" class="cs_card-image img-4">
                <h3 class="cs_card-title">
                  Mobility
                  <span class="cs_card-title-value">({{assessment.gaitCapabilityAssessmentBase.mobility.score}}/{{assessment.gaitCapabilityAssessmentBase.mobility.scoreMax}})</span>
                </h3>
                <h4 class="cs_card-subtitle">The ability of the body to move</h4>
                <div class="cs_card-list">
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col">
                      <!-- ## -->
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Left
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Right
                    </div>
                  </div>

                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('thomas')">
                      Thomas
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.mobility.hipLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{ assessment.gaitCapabilityAssessmentBase.mobility.hipLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.mobility.hipRight | enumString: AssessmentResultShortEnum | lowercase }}">{{ assessment.gaitCapabilityAssessmentBase.mobility.hipRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('kneelingDorsiflexion')">
                      Kneeling Dorsiflexion
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.mobility.ankleLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.mobility.ankleLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.mobility.ankleRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.mobility.ankleRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('aslr')">
                      ASLR
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.mobility.hamstringsLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.mobility.hamstringsLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.mobility.hamstringsRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.mobility.hamstringsRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="cs_card">
                <img src="../../../../assets/img/gaitreport/img_0003.svg" alt="" class="cs_card-image img-3">
                <h3 class="cs_card-title">
                  Stability
                  <span class="cs_card-title-value">({{assessment.gaitCapabilityAssessmentBase.stability.score}}/{{assessment.gaitCapabilityAssessmentBase.stability.scoreMax}})</span>
                </h3>
                <h4 class="cs_card-subtitle">Strength and Control</h4>
                <div class="cs_card-list">
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col">
                      <!-- ## -->
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Left
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Right
                    </div>
                  </div>

                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('uhbe')">
                      UHBE
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.coreLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.coreLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.coreRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.coreRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>

                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('sitToStand30sec')">
                      Sit to Stand
                    </div>
                    <div class="cs_card-list-item-col cs_span-2">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.hip | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.hip | enumString: AssessmentResultShortEnum}}</div>
                    </div>

                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('calfRaise')">
                      Calf Raise
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.calfLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.calfLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.calfRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.calfRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('paperGrip')">
                      Paper Grip
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.footLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.footLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.stability.footRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.stability.footRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cs_card">
                <img src="../../../../assets/img/gaitreport/img_0001.svg" alt="" class="cs_card-image img-1">
                <h3 class="cs_card-title">
                  Foot
                  <span class="cs_card-title-value">({{assessment.gaitCapabilityAssessmentBase.foot.score}}/{{assessment.gaitCapabilityAssessmentBase.foot.scoreMax}})</span>
                </h3>
                <h4 class="cs_card-subtitle">Foot Coordinated Movements</h4>
                <div class="cs_card-list">
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col">
                      <!-- ## -->
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Left
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Right
                    </div>
                  </div>

                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('halluxExtensionGc')">
                      Extension
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.foot.bigToeLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.foot.bigToeLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.foot.bigToeRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.foot.bigToeRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>

                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('halluxABD')">
                      Abduction
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.foot.pronationLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.foot.pronationLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.foot.pronationRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.foot.pronationRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('toeSplayGc')">
                      Toe&#8239;Splay
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.foot.splayLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.foot.splayLeft  | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.foot.splayRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.foot.splayRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cs_card">
                <img src="../../../../assets/img/gaitreport/img_0002.svg" alt="" class="cs_card-image img-2">
                <h3 class="cs_card-title">
                  Fundamental
                  <span class="cs_card-title-value">({{assessment.gaitCapabilityAssessmentBase.functional.score}}/{{assessment.gaitCapabilityAssessmentBase.functional.scoreMax}})</span>
                </h3>
                <h4 class="cs_card-subtitle">Combined Movements & Balance</h4>
                <div class="cs_card-list">
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col">
                      <!-- ## -->
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Left
                    </div>
                    <div class="cs_card-list-item-col cs_bold">
                      Right
                    </div>
                  </div>

                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('toeTouchGc')">
                      Toe Touch
                    </div>
                    <div class="cs_card-list-item-col cs_span-2">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.toeTouch | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.toeTouch | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('rotationGc')">
                      Rotation
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.rotationLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.rotationLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.rotationRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.rotationRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>

                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('4stageBalance')">
                      Balance
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.balanceLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.balanceLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.balanceRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.balanceRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                  <div class="cs_card-list-item">
                    <div class="cs_card-list-item-col btn-gc" (click)="showHelp('Hurdle')">
                      Hurdle
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.hurdleLeft | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.hurdleLeft | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                    <div class="cs_card-list-item-col">
                      <div class="cs_score sm s-{{ assessment.gaitCapabilityAssessmentBase.functional.hurdleRight | enumString: AssessmentResultShortEnum | lowercase }}">{{assessment.gaitCapabilityAssessmentBase.functional.hurdleRight | enumString: AssessmentResultShortEnum}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cs_card cs_black cs_content-center">
                <h3 class="cs_card-title">Your GCS Program</h3>
                <div *ngIf="assessment.program" class="cs_highlight" title="{{assessment.program.name}}">
                  <a href="javascript:void(0)" [routerLink]="[programUrl, assessment.program.id]">{{ (assessment.program.name.length>20) ? (assessment.program.name | slice:0:18) + '...' : (assessment.program.name) }}</a>
                </div>
                <div *ngIf="!assessment.program" class="cs_highlight">
                  Unable to assign
                </div>
                <p *ngIf="assessment.program">
                  {{ (gscProgramDescription.length> 140) ? (gscProgramDescription | slice:0:140) + '...' : (gscProgramDescription)}}
                </p>
              </div>
              <div class="cs_sec-header dark">
                <div class="cs_sec-left sec-score">
                  <div class="cs_sec-score-item">
                    <h2>Able</h2>
                    <div class="cs_score lg s-g">A</div>
                  </div>

                  <div class="cs_sec-score-item">
                    <h2>Unable</h2>
                    <div class="cs_score lg s-p">U</div>
                  </div>


                </div>
                <div class="cs_sec-right">
                  Tests are scored as Able or Unable. It is common that if there is one
                  area for improvement, it will impact multiple tests. Improving strength
                  or flexibility in one area may improve other tests, which is why we
                  focus on the top priority to improve how you move.
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
