<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="card">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="mb-0">Manage Category / Program relationships</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <form class="form" (ngSubmit)="onSave()" [formGroup]="formGroup" #ngForm="ngForm" novalidate>
          <h3>Walking Categories</h3>
          <ng-container formArrayName="walkingCategories">
            <div *ngFor="let item of formGroup.get('walkingCategories').controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-control-label" for="name">
                        {{item.get('name').value}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <programs-dropdown class="form-control" formControlName="programId"></programs-dropdown>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>


          <h3>Gait Capacity Categories</h3>
          <ng-container formArrayName="gaitCapabilityCategories">
            <div *ngFor="let item of formGroup.get('gaitCapabilityCategories').controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-control-label" for="name">
                        {{item.get('name').value}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <programs-dropdown class="form-control" formControlName="programId"></programs-dropdown>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <h3>Movement Categories</h3>
          <ng-container formArrayName="movementCategories">
            <div *ngFor="let item of formGroup.get('movementCategories').controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-control-label" for="name">
                        {{item.get('name').value}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <programs-dropdown class="form-control" formControlName="programId"></programs-dropdown>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <h3>Impairment Categories</h3>
          <ng-container formArrayName="impairmentCategories">
            <div *ngFor="let item of formGroup.get('impairmentCategories').controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-control-label" for="name">
                        {{item.get('name').value}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <programs-dropdown class="form-control" formControlName="programId"></programs-dropdown>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="row form-btns">
            <div class="col-6 text-right">
              <button type="submit" [promiseBtn]="submitComplete" class="btn btn-primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
