<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid ">
  <div class="header pb-6">
    <div>
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block mb-0">Gait Capacity Assessment Results</h6>
          </div>
          <div class="col-12">
            <a href="javascript:void(0)" [routerLink]="[listURL]"> &#60; Back to all Assessments</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt--6">
    <gaitcapability-assessment-result-details [isCoachView]="true"></gaitcapability-assessment-result-details>
  </div>
</div>

