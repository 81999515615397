import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class DailyStatusesService
{
  statusPopupShownTimeKey: string = "statusPopupLastTimeShown"

  public entryAdded: Subject<boolean> = new Subject();

  constructor() { }

  SendEntryAddedEvent()
  {
    this.entryAdded.next(true);
  }


  getLastTimeStatusPopupShown()
  {
    let timeString = localStorage.getItem("statusPopupShownTimeKey");

    if (!timeString)
      return new Date(0);

    return new Date(timeString);
  }

  setCurrentTimeAsStatusPopupLastShown()
  {
    return localStorage.setItem("statusPopupShownTimeKey", new Date().toString());
  }
}
