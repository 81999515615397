<div *ngIf="initialized && url">
  <iframe *ngIf="youtubeUrl" [ngClass]="{ 'youtube-small': size == 'small', 'youtube-large': size == 'large' }" [src]="youtubeUrl" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <iframe *ngIf="vimeoUrl" [ngClass]="{ 'vimeo-small': size == 'small', 'vimeo-large': size == 'large' }" [src]="vimeoUrl" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
</div>
<div *ngIf="!encoded && !url" class="alert alert-danger">Video is processing. Please check back later.</div>
<div *ngIf="(azureUrl || videoId) && !initialized" class="alert">Video loading...</div>
<div *ngIf="azureUrl && !isWowzaVideo && encoded" [ngClass]="{ 'azure-video-container-small': size == 'small', 'azure-video-container': size == 'large' }">
  <video [id]="'livePlayer' + (videoId || '')" class="azuremediaplayer amp-default-skin embed-responsive" style="z-index: 0"></video>
</div>

<div *ngIf="azureUrl && isWowzaVideo && encoded">
  <div *ngIf="isWowzaVideo" #wowzaPlayer_{{uniqueId}} id="wowzaPlayer_{{uniqueId}}" class="azuremediaplayer amp-default-skin embed-responsive live-player live-player-fluid hide-fullscreen" style="z-index: 0; background-color: black; display:flex; align-items: center"></div>
</div>
